import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";

const apiClient = new APIClient();

const ModalRecibo = ({ modal, setModal, setRefresh, retirada, userData }) => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [receiptUrl, setReceiptUrl] = useState(null);

  const toggle = () => {
    setModal(!modal);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    // Validate file type
    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
    if (selectedFile && allowedTypes.includes(selectedFile.type)) {
      setFile(selectedFile);
    } else {
      toast.error(
        "Por favor, seleccione un archivo JPG, JPEG, PNG o PDF válido."
      );
      e.target.value = null;
    }
  };

  const enviarRecibo = async () => {
    setLoading(true);

    try {
      // URL input for converter = false
      if (!receiptUrl.trim()) {
        toast.error("Por favor, ingrese una URL de recibo válida.");
        setLoading(false);
        return;
      }

      const response = await apiClient.put("/retiradas/hash", {
        retirada_id: retirada.id,
        recibo_url: receiptUrl,
      });

      if (response.sucesso) {
        toast.success(response.mensagem);
        setRefresh(true);
        toggle();
      } else {
        toast.error(response.mensagem || "Error al enviar la URL del recibo");
      }
    } catch (error) {
      console.error("Error al enviar recibo:", error);
      toast.error("Ocurrió un error al enviar el recibo");
    } finally {
      setLoading(false);
    }
  };

  // Only show modal for admin users
  if (userData?.role !== "admin") {
    return null;
  }

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader toggle={toggle}>
        {!retirada?.converter ? "Ingresar URL de Hash" : "Ingresar Coelsa ID"}
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <Form>
          <FormGroup>
            <Label>{!retirada?.converter ? "URL del Hash" : "Coelsa ID"}</Label>
            <Input
              type="text"
              placeholder={
                !retirada?.converter
                  ? "Ingrese la URL del hash"
                  : "Ingrese el Coelsa ID"
              }
              value={receiptUrl}
              onChange={(e) => setReceiptUrl(e.target.value)}
              disabled={loading}
            />
          </FormGroup>

          <div className="hstack gap-2 justify-content-end mt-4">
            <Link
              to="#"
              className="btn btn-link link-danger fw-medium"
              onClick={() => toggle()}
              disabled={loading}
            >
              <i className="ri-close-line me-1 align-middle"></i> Cerrar
            </Link>
            <Button
              color="primary"
              onClick={() => enviarRecibo()}
              disabled={loading}
            >
              {loading ? "Enviando..." : "Enviar"}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ModalRecibo;
