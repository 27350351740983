import React from "react";
import { Modal, ModalBody, ModalHeader, Row, Badge, Button } from "reactstrap";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

const ModalNotas = ({ modal, setModal, ordem }) => {
  const toggle = () => {
    setModal(!modal);
  };

  const getBadgeColor = (status) => {
    switch (status) {
      case "cancelada":
        return "danger";
      case "solicitada":
        return "warning";
      case "entregue":
        return "success";
      default:
        return "secondary";
    }
  };

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Notas Fiscales - Orden #{ordem?.id}
      </ModalHeader>
      <ModalBody className="text-left p-4">
        <div className="live-preview">
          <div className="table-responsive">
            <table className="table align-middle table-nowrap table-striped-columns mb-0">
              <thead className="table-light">
                <tr>
                  <th scope="col">Referencia</th>
                  <th scope="col">Estado</th>
                  <th scope="col">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {ordem?.notas_fiscais.map((nota, index) => (
                  <tr key={index}>
                    <td>{nota.nota_reference}</td>
                    <td>
                      <Badge color={getBadgeColor(nota.status)}>
                        {nota.status.charAt(0).toUpperCase() + nota.status.slice(1)}
                      </Badge>
                    </td>
                    <td>
                      <Button
                        color="primary"
                        size="sm"
                        tag="a"
                        href={nota.nota_url}
                        target="_blank"
                        className="me-2"
                      >
                        <FeatherIcon icon="external-link" width="14" />{" "}Acessar
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="hstack gap-2 justify-content-end mt-4">
          <Link
            to="#"
            className="btn btn-light"
            onClick={() => toggle()}
          >
            Cerrar
          </Link>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalNotas;
