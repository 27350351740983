import React, { useState, useCallback, useEffect } from "react";
import Calculadora from "../GerarOrdemPix/Calculadora";
import GerarLinkPagamento from "./GerarLinkPagamento";
import RelatorioOrdens from "./RelatorioOrdens"; // New import
import { Link } from "react-router-dom";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();
import { toast } from "react-toastify";

import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Row,
  CardHeader,
  Spinner,
  Button,
} from "reactstrap";

const Pdv = ({ pdv_id }) => {
  const [pdvDados, setPdvDados] = useState(null);
  const [loading, setLoading] = useState(false);
  const [atendenteId, setAtendenteId] = useState(null);
  const [atendenteNome, setAtendenteNome] = useState("");
  const [pinAceito, setPinAceito] = useState(false);
  const [pin, setPin] = useState("");
  const [opcaoSelecionada, setOpcaoSelecionada] = useState(null);

  const fetchPdvDados = useCallback(async (pdvId) => {
    setLoading(true);
    const params = {
      pdv_id: pdvId,
    };
    const response = await apiClient.get("/pdvs/ver", params);
    if (response.sucesso) {
      const dados = response.dados;
      setPdvDados(dados);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
    setLoading(false);
  }, []);

  const checkPin = async (pdvId, pinToCheck) => {
    setLoading(true);

    const data = {
      pin: pinToCheck,
      pdv_id: pdvId,
    };

    const response = await apiClient.post(`/pdvs/check-pin`, data);
    if (response.sucesso) {
      setPinAceito(true);
      // Save PIN to localStorage when accepted
      localStorage.setItem("pdv_pin", pinToCheck);
      // If no attendants, set atendenteId to 0
      if (!pdvDados?.atendentes?.length) {
        setAtendenteId(0);
      }
    } else {
      if (response.mensagem !== "") toast.error(response.mensagem);
      setPin("");
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  // Handle PIN input and validation
  const handlePinChange = (e) => {
    const value = e.target.value.replace(/\D/g, "").slice(0, 4);
    setPin(value);

    if (value.length === 4) {
      checkPin(pdv_id, value);
    }
  };

  const handleAtendenteSelect = (id, nome) => {
    const numId = Number(id); // Ensure it's a number
    setAtendenteId(numId);
    localStorage.setItem("atendente_id", numId.toString());
    localStorage.setItem("atendente_nome", nome);
    setAtendenteNome(nome);
  };

  const handleLogout = () => {
    // Reset all state variables
    setPinAceito(false);
    setPin("");
    setAtendenteId(null);
    setOpcaoSelecionada(null);
    // Remove data from localStorage
    localStorage.removeItem("pdv_pin");
    localStorage.removeItem("atendente_id");
    localStorage.removeItem("atendente_nome");
  };

  useEffect(() => {
    const fetchDados = async (pdv_id) => {
      await fetchPdvDados(pdv_id);
      // Check if PIN exists in localStorage
      const savedPin = localStorage.getItem("pdv_pin");
      if (savedPin) {
        setPin(savedPin);
        checkPin(pdv_id, savedPin);

        // After PIN is validated, check for saved attendant
        const savedAtendenteId = localStorage.getItem("atendente_id");
        const savedAtendenteNome = localStorage.getItem("atendente_nome");
        if (savedAtendenteId) {
          setAtendenteId(Number(savedAtendenteId));
          setAtendenteNome(savedAtendenteNome);
        }
      }
    };
    if (pdv_id) fetchDados(pdv_id);
  }, [fetchPdvDados]);

  const renderAtendentesSelection = () => {
    return (
      <Col xs={12}>
        <div className="text-center mb-4">
          <h4>Seleccione el Vendedor</h4>
        </div>
        <Row className="g-3 justify-content-center">
          <Col xs={8}>
            <Card
              className="cursor-pointer card-light"
              onClick={() => handleAtendenteSelect(0)}
              style={{
                cursor: "pointer",
                transition: "transform 0.2s",
              }}
              onMouseOver={(e) =>
                (e.currentTarget.style.transform = "scale(1.02)")
              }
              onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
            >
              <CardBody className="text-center">
                <h5 className="card-title mb-0">Sin Vendedor</h5>
              </CardBody>
            </Card>
          </Col>
          {pdvDados?.atendentes?.map((atendente) => (
            <Col xs={8} key={atendente.id}>
              <Card
                className="cursor-pointer card-light"
                onClick={() =>
                  handleAtendenteSelect(atendente.id, atendente.nome)
                }
                style={{
                  cursor: "pointer",
                  transition: "transform 0.2s",
                }}
                onMouseOver={(e) =>
                  (e.currentTarget.style.transform = "scale(1.02)")
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.transform = "scale(1)")
                }
              >
                <CardBody className="text-center">
                  <h5 className="card-title mb-0">{atendente.nome}</h5>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </Col>
    );
  };

  const renderOpcaoSelection = () => {
    return (
      <Col xs={12}>
        <div className="text-center mb-4">
          <h4>Seleccione una Opción</h4>
        </div>
        <Row className="g-3 justify-content-center">
          <Col xs={8}>
            <Card
              className="cursor-pointer card-light"
              onClick={() => setOpcaoSelecionada("qrcode")}
              style={{
                cursor: "pointer",
                transition: "transform 0.2s",
              }}
              onMouseOver={(e) =>
                (e.currentTarget.style.transform = "scale(1.02)")
              }
              onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
            >
              <CardBody className="text-center">
                <h5 className="card-title mb-0">Generar QR Code</h5>
              </CardBody>
            </Card>
          </Col>
          <Col xs={8}>
            <Card
              className="cursor-pointer card-light"
              onClick={() => setOpcaoSelecionada("link")}
              style={{
                cursor: "pointer",
                transition: "transform 0.2s",
              }}
              onMouseOver={(e) =>
                (e.currentTarget.style.transform = "scale(1.02)")
              }
              onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
            >
              <CardBody className="text-center">
                <h5 className="card-title mb-0">Generar Link de Pago</h5>
              </CardBody>
            </Card>
          </Col>
          <Col xs={8}>
            <Card
              className="cursor-pointer card-light"
              onClick={() => setOpcaoSelecionada("relatorio")}
              style={{
                cursor: "pointer",
                transition: "transform 0.2s",
              }}
              onMouseOver={(e) =>
                (e.currentTarget.style.transform = "scale(1.02)")
              }
              onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
            >
              <CardBody className="text-center">
                <h5 className="card-title mb-0">Informe</h5>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Col>
    );
  };

  document.title = "Punto de Venta | Nautt Finance";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="justify-content-center">
            <Col xl={8} lg={8} md={10} sm={12}>
              <Card className="product">
                <CardHeader className="text-center pt-4 pb-4">
                  <h2 className="text-success">
                    {pdvDados?.nome || "Punto de Venta Nautt Finance"}
                  </h2>
                  {atendenteNome && (
                    <small className="text-muted">
                      <b>Atendente:</b> {atendenteNome}
                    </small>
                  )}
                </CardHeader>
                <CardBody>
                  <Row className="gy-3">
                    {!pinAceito ? (
                      <Col xs={12}>
                        <div
                          className="text-center"
                          style={{ marginTop: "100px", marginBottom: "100px" }}
                        >
                          {loading ? (
                            <Spinner
                              style={{ width: "3rem", height: "3rem" }}
                            />
                          ) : (
                            <div>
                              <h4 className="mb-4">Ingrese el PIN del PDV</h4>
                              <Input
                                type="password"
                                value={pin}
                                onChange={handlePinChange}
                                className="form-control form-control-lg text-center"
                                style={{
                                  maxWidth: "200px",
                                  margin: "0 auto",
                                  fontSize: "24px",
                                  letterSpacing: "0.5em",
                                }}
                                placeholder="****"
                                autoFocus
                              />
                            </div>
                          )}
                        </div>
                      </Col>
                    ) : atendenteId === null &&
                      pdvDados?.atendentes?.length > 0 ? (
                      renderAtendentesSelection()
                    ) : opcaoSelecionada === null ? (
                      renderOpcaoSelection()
                    ) : opcaoSelecionada === "qrcode" ? (
                      <Calculadora pdv_id={pdv_id} atendente_id={atendenteId} />
                    ) : opcaoSelecionada === "link" ? (
                      <GerarLinkPagamento
                        pdv_id={pdv_id}
                        userId={pdvDados?.user_id}
                        atendente_id={atendenteId}
                        pin={pin}
                      />
                    ) : (
                      <RelatorioOrdens
                        pdv_id={pdv_id}
                        atendente_id={atendenteId}
                        pin={pin}
                      />
                    )}
                    {atendenteId !== null && (
                      <Col
                        md={12}
                        className="justify-content-center d-flex mt-3 gap-2"
                      >
                        <Button
                          color="secondary"
                          onClick={() => {
                            setOpcaoSelecionada(null);
                            setAtendenteId(null);
                          }}
                        >
                          Volver
                        </Button>
                        <Button color="danger" onClick={handleLogout}>
                          Cerrar Sesión
                        </Button>
                      </Col>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Pdv;
