import React, { useState, useEffect } from "react";
import { Card, CardHeader, Col, Spinner } from "reactstrap";
import getChartColorsArray from "../../Components/Common/ChartsDynamicColor";
import ReactApexChart from "react-apexcharts";
import { APIClient } from "../../helpers/api_helper";
import { toast } from "react-toastify";

const apiClient = new APIClient();

const RelatorioAtendentes = ({ setTipoRelatorio }) => {
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({
    labels: [],
    chart: {
      height: 333,
      type: "donut",
    },
    legend: {
      position: "bottom",
    },
    stroke: {
      show: false,
    },
    dataLabels: {
      dropShadow: {
        enabled: false,
      },
    },
    colors: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(false);
      try {
        const response = await apiClient.get("/relatorios/atendentes/vendas");
        if (response.sucesso && response.dados) {
          const { atendentes, vendido } = response.dados;

          // Reordenar para ter "Ninguno" primeiro
          const ningunoIndex = Object.keys(atendentes).find(
            (key) => atendentes[key] === "Ninguno"
          );
          const orderedLabels = [atendentes[ningunoIndex]];
          const orderedSeries = [parseFloat(vendido[ningunoIndex])];

          Object.keys(atendentes).forEach((key) => {
            if (key !== ningunoIndex) {
              orderedLabels.push(atendentes[key]);
              orderedSeries.push(parseFloat(vendido[key]));
            }
          });

          // Gerar cores dinâmicas baseado na quantidade de atendentes
          const baseColors = ["--vz-dark"]; // Cinza para "Ninguno"
          const otherColors = [
            "--vz-primary",
            "--vz-success",
            "--vz-warning",
            "--vz-danger",
            "--vz-info",
          ];

          // Adicionar cores extras se necessário
          while (baseColors.length < orderedLabels.length) {
            baseColors.push(
              otherColors[baseColors.length % otherColors.length]
            );
          }

          const dataColors = JSON.stringify(baseColors);
          const chartDonutBasicColors = getChartColorsArray(dataColors);

          let newOptions = {
            ...options,
            labels: orderedLabels,
            colors: chartDonutBasicColors,
          };

          setOptions(newOptions);
          setSeries(orderedSeries);
        } else {
          setError(true);
          if (response.mensagem) {
            toast.error(response.mensagem);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(true);
        toast.error("Error al cargar los datos de ventas por atendente");
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  const renderContent = () => {
    if (loading) {
      return (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "333px" }}
        >
          <Spinner color="primary" />
        </div>
      );
    }

    if (error) {
      return (
        <div
          className="d-flex justify-content-center align-items-center flex-column"
          style={{ height: "333px" }}
        >
          <i className="ri-error-warning-line fs-1 text-danger mb-2"></i>
          <p className="text-muted">Error al cargar los datos</p>
        </div>
      );
    }

    return (
      <ReactApexChart
        dir="ltr"
        options={options}
        series={series}
        type="donut"
        height="333"
        className="apex-charts"
      />
    );
  };

  return (
    <React.Fragment>
      <Col xl={4}>
        <Card className="card-height-100">
          <CardHeader className="align-items-center d-flex">
            <div className="flex-grow-1">
              <h4 className="card-title mb-0">Órdenes por atendentes</h4>
              <small className="text-muted">últimos 30 días</small>
            </div>
            <div className="flex-shrink-0">
              <button
                type="button"
                className="btn btn-soft-success btn-sm shadow-none"
                onClick={() => setTipoRelatorio("pdv")}
              >
                <i className="ri-store-2-line align-middle me-1"></i> Mudar para
                PDV
              </button>
            </div>
          </CardHeader>

          <div className="card-body">{renderContent()}</div>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default RelatorioAtendentes;
