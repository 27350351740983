import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Input,
  Spinner,
} from "reactstrap";
import { toast } from "react-toastify";
import { APIClient } from "../../helpers/api_helper";

const apiClient = new APIClient();

const ModalUpload = ({ modal, setModal, ordem, setRefresh }) => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);

  const toggle = () => {
    setFile(null);
    setModal(!modal);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileType = selectedFile.type;
      const validTypes = [
        "application/pdf",
        "image/png",
        "image/jpeg",
        "image/jpg",
      ];
      if (validTypes.includes(fileType)) {
        setFile(selectedFile);
      } else {
        toast.error("Por favor, seleccione un archivo PDF, PNG o JPG/JPEG");
        e.target.value = null;
      }
    }
  };

  const enviarComprovante = async () => {
    setLoading(true);

    try {
      if (!file) {
        toast.error("Por favor, seleccione un archivo de comprobante.");
        setLoading(false);
        return;
      }

      const formData = new FormData();
      formData.append("comprovante", file);
      formData.append("ordem_id", ordem.id);

      const extensaoComprovante = file.name.split(".").pop();
      formData.append("extensaoComprovante", extensaoComprovante);
      formData.append("uuid", ordem.uuid);

      const response = await apiClient.post(
        "/ordens-nautt/comprovante",
        formData
      );

      if (response.sucesso) {
        toast.success("¡Comprobante enviado con éxito! Procesaremos su depósito en breve.");
        setRefresh(true);
        toggle();
      } else {
        toast.error(response.mensagem || "Error al subir el comprobante");
      }
    } catch (error) {
      console.error("Error al enviar comprobante:", error);
      toast.error("Ocurrió un error al enviar el comprobante");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      backdrop={"static"}
      className="modal-md"
      centered
    >
      <ModalHeader className="modal-title" toggle={toggle}>
        Subir Comprobante
      </ModalHeader>
      <ModalBody className="text-center p-4">
        <div className="text-center">
          <p className="mb-2">
            <strong>¡Importante!</strong> Suba el comprobante de su depósito:
          </p>
          <p className="small text-muted mb-3">
            Archivos permitidos: PDF, PNG, JPG o JPEG
          </p>
          <div className="d-flex justify-content-center align-items-center gap-2">
            <Input
              type="file"
              onChange={handleFileChange}
              accept=".pdf,.png,.jpg,.jpeg"
              className="form-control"
              style={{ maxWidth: "300px" }}
            />
            <Button
              color="success"
              onClick={enviarComprovante}
              disabled={!file || loading}
            >
              {loading ? (
                <Spinner size="sm" />
              ) : (
                <i className="ri-upload-2-line" />
              )}
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalUpload;
