import React, { useState } from "react";
import {
  Row,
  Col,
  CardBody,
  CardText,
  Card,
  Container,
  Form,
  Input,
  Label,
  FormFeedback,
  Button,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import FeatherIcon from "feather-icons-react";

import logoLight from "../../assets/images/logo-light.png";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { APIClient } from "../../helpers/api_helper";

const RegisterKyb = () => {
  document.title = "Onboarding Persona Jurídica | Nautt Finance";
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(false);
  const [politicaChecked, setPoliticaChecked] = useState(false);

  const [formData, setFormData] = useState({
    nomeLegal: "",
    nomeFantasia: "",
    dataConstituicao: "",
    email: "",
    paisFiscal: "",
    documentoFiscal: "",
    paisTelefone: "",
    telefone: "",
    website: "",
  });

  const [enderecos, setEnderecos] = useState([
    { pais: "", estado: "", cidade: "", cep: "" },
  ]);
  const [acionistas, setAcionistas] = useState([
    { nomeCompleto: "", paisFiscal: "", documentoFiscal: "", email: "" },
  ]);
  const [documentos, setDocumentos] = useState({
    docConstituicao: null,
    docEndereco: null,
  });

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const adicionarEndereco = () => {
    setEnderecos([...enderecos, { pais: "", estado: "", cidade: "", cep: "" }]);
  };

  const removerEndereco = (index) => {
    const novosEnderecos = [...enderecos];
    novosEnderecos.splice(index, 1);
    setEnderecos(novosEnderecos);
  };

  const adicionarAcionista = () => {
    setAcionistas([
      ...acionistas,
      { nomeCompleto: "", paisFiscal: "", documentoFiscal: "", email: "" },
    ]);
  };

  const removerAcionista = (index) => {
    const novosAcionistas = [...acionistas];
    novosAcionistas.splice(index, 1);
    setAcionistas(novosAcionistas);
  };

  const handleFileChange = (e, docType) => {
    setDocumentos({ ...documentos, [docType]: e.target.files[0] });
  };

  const validateForm = () => {
    let tempErrors = {};
    if (!formData.nomeLegal)
      tempErrors.nomeLegal = "El nombre legal es obligatorio";
    if (!formData.nomeFantasia)
      tempErrors.nomeFantasia = "El nombre comercial es obligatorio";
    if (!formData.dataConstituicao)
      tempErrors.dataConstituicao = "La fecha de constitución es obligatoria";
    if (!formData.email)
      tempErrors.email = "El correo electrónico es obligatorio";
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)) {
      tempErrors.email = "Dirección de correo electrónico inválida";
    }
    if (!formData.paisFiscal)
      tempErrors.paisFiscal = "El país del documento es obligatorio";
    if (!formData.documentoFiscal)
      tempErrors.documentoFiscal = "El documento fiscal es obligatorio";
    if (!formData.paisTelefone)
      tempErrors.paisTelefone = "El país del teléfono es obligatorio";
    if (!formData.telefone)
      tempErrors.telefone = "El número de teléfono es obligatorio";

    // Validar direcciones
    enderecos.forEach((endereco, index) => {
      if (!endereco.pais)
        tempErrors[`endereco${index}Pais`] = "El país es obligatorio";
      if (!endereco.estado)
        tempErrors[`endereco${index}Estado`] = "El estado es obligatorio";
      if (!endereco.cidade)
        tempErrors[`endereco${index}Cidade`] = "La ciudad es obligatoria";
      if (!endereco.cep)
        tempErrors[`endereco${index}Cep`] = "El código postal es obligatorio";
    });

    // Validar accionistas
    acionistas.forEach((acionista, index) => {
      if (!acionista.nomeCompleto)
        tempErrors[`acionista${index}Nome`] =
          "El nombre completo es obligatorio";
      if (!acionista.paisFiscal)
        tempErrors[`acionista${index}PaisFiscal`] =
          "El país del documento es obligatorio";
      if (!acionista.documentoFiscal)
        tempErrors[`acionista${index}DocumentoFiscal`] =
          "El documento fiscal es obligatorio";
      if (!acionista.email)
        tempErrors[`acionista${index}Email`] =
          "El correo electrónico es obligatorio";
    });

    if (!documentos.docConstituicao)
      tempErrors.docConstituicao =
        "El Documento de Constitución de la Empresa es obligatorio";
    if (!documentos.docEndereco)
      tempErrors.docEndereco = "El Comprobante de Domicilio es obligatorio";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!politicaChecked) {
      toast.error("Por favor, acepte la política de privacidad.");
      return;
    }
    setLoading(true);
    if (validateForm()) {
      const submitData = new FormData();

      // Append form data
      Object.keys(formData).forEach((key) => {
        submitData.append(key, formData[key]);
      });

      // Append addresses
      enderecos.forEach((endereco, index) => {
        Object.keys(endereco).forEach((key) => {
          submitData.append(`enderecos[${index}][${key}]`, endereco[key]);
        });
      });

      // Append shareholders
      acionistas.forEach((acionista, index) => {
        Object.keys(acionista).forEach((key) => {
          submitData.append(`acionistas[${index}][${key}]`, acionista[key]);
        });
      });

      // Append documents
      Object.keys(documentos).forEach((key) => {
        if (documentos[key]) {
          submitData.append(key, documentos[key]);
        }
      });
      const extensaoDocConstituicao = documentos.docConstituicao.name
        .split(".")
        .pop();
      const extensaoDocEndereco = documentos.docEndereco.name.split(".").pop();

      //adicionar ao submitData as extensões dos arquivos
      submitData.append("extensaoDocConstituicao", extensaoDocConstituicao);
      submitData.append("extensaoDocEndereco", extensaoDocEndereco);

      try {
        const api = new APIClient();
        const response = await api.post("/kyb-onboarding", submitData);
        if (response.sucesso) {
          toast.success("¡Formulario enviado con éxito!");
          setFinished(true);
        } else {
          toast.error(
            response.mensagem || "Ocurrió un error al enviar el formulario."
          );
        }
      } catch (error) {
        toast.error("Ocurrió un error al enviar el formulario.");
      }
    } else {
      toast.error("Por favor, complete todos los campos obligatorios.");
    }
    setLoading(false);
  };

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <Link to="/" className="d-inline-block auth-logo">
                    <img src={logoLight} alt="" />
                  </Link>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={10} lg={8}>
                <Card className="mt-4">
                  {finished ? (
                    <CardBody className="p-4">
                      <div
                        className={`rounded-circle p-3 mb-2 bg-success`}
                        style={{
                          width: "100px",
                          height: "100px",
                          margin: "0 auto",
                        }}
                      >
                        <FeatherIcon
                          icon="check-circle"
                          className="text-white"
                          size={70}
                        />
                      </div>
                      <CardText tag="h4" className="font-weight-bold mt-4">
                        ¡Sus datos han sido enviados con éxito! Espere el
                        contacto de nuestro equipo.
                        <br />
                        <br />
                        Los accionistas registrados recibirán un correo
                        electrónico para realizar la verificación de identidad.
                      </CardText>
                    </CardBody>
                  ) : (
                    <CardBody className="p-4">
                      <div className="text-center mt-2">
                        <h5 className="text-success">Onboarding Empresarial</h5>
                        <p className="text-muted">
                          Complete la información de su empresa
                        </p>
                      </div>
                      <div className="p-2 mt-4">
                        <Form
                          onSubmit={onSubmit}
                          className="needs-validation"
                          encType="multipart/form-data"
                        >
                          <Row>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="nomeLegal"
                                  className="form-label"
                                >
                                  Nombre Legal
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="nomeLegal"
                                  name="nomeLegal"
                                  placeholder="Ingrese el nombre legal"
                                  value={formData.nomeLegal}
                                  onChange={handleInputChange}
                                  invalid={!!errors.nomeLegal}
                                />
                                {errors.nomeLegal && (
                                  <FormFeedback>
                                    {errors.nomeLegal}
                                  </FormFeedback>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="nomeFantasia"
                                  className="form-label"
                                >
                                  Nombre Comercial
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="nomeFantasia"
                                  name="nomeFantasia"
                                  placeholder="Ingrese el nombre comercial"
                                  value={formData.nomeFantasia}
                                  onChange={handleInputChange}
                                  invalid={!!errors.nomeFantasia}
                                />
                                {errors.nomeFantasia && (
                                  <FormFeedback>
                                    {errors.nomeFantasia}
                                  </FormFeedback>
                                )}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="dataConstituicao"
                                  className="form-label"
                                >
                                  Fecha de Constitución
                                </Label>
                                <Input
                                  type="date"
                                  className="form-control"
                                  id="dataConstituicao"
                                  name="dataConstituicao"
                                  value={formData.dataConstituicao}
                                  onChange={handleInputChange}
                                  invalid={!!errors.dataConstituicao}
                                />
                                {errors.dataConstituicao && (
                                  <FormFeedback>
                                    {errors.dataConstituicao}
                                  </FormFeedback>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label htmlFor="email" className="form-label">
                                  Correo Electrónico
                                </Label>
                                <Input
                                  type="email"
                                  className="form-control"
                                  id="email"
                                  name="email"
                                  placeholder="Ingrese la dirección de correo electrónico"
                                  value={formData.email}
                                  onChange={handleInputChange}
                                  invalid={!!errors.email}
                                />
                                {errors.email && (
                                  <FormFeedback>{errors.email}</FormFeedback>
                                )}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="paisFiscal"
                                  className="form-label"
                                >
                                  País del Documento
                                </Label>
                                <Input
                                  type="select"
                                  className="form-control"
                                  id="paisFiscal"
                                  name="paisFiscal"
                                  value={formData.paisFiscal}
                                  onChange={handleInputChange}
                                  invalid={!!errors.paisFiscal}
                                >
                                  <option value="">Seleccione el país</option>
                                  <option value="Brasil">Brasil</option>
                                  <option value="Argentina">Argentina</option>
                                  <option value="Chile">Chile</option>
                                </Input>
                                {errors.paisFiscal && (
                                  <FormFeedback>
                                    {errors.paisFiscal}
                                  </FormFeedback>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="documentoFiscal"
                                  className="form-label"
                                >
                                  {formData.paisFiscal === "Brasil"
                                    ? "CNPJ"
                                    : formData.paisFiscal === "Argentina"
                                    ? "CUIT"
                                    : "RUT"}
                                </Label>
                                <Input
                                  type="text"
                                  maxLength={20}
                                  className="form-control"
                                  id="documentoFiscal"
                                  name="documentoFiscal"
                                  placeholder={
                                    formData.paisFiscal === "Brasil"
                                      ? "00.000.000/0000-00"
                                      : formData.paisFiscal === "Argentina"
                                      ? "00-00000000-0"
                                      : "00.000.000-0"
                                  }
                                  value={formData.documentoFiscal}
                                  onChange={handleInputChange}
                                  invalid={!!errors.documentoFiscal}
                                />
                                {errors.documentoFiscal && (
                                  <FormFeedback>
                                    {errors.documentoFiscal}
                                  </FormFeedback>
                                )}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="paisTelefone"
                                  className="form-label"
                                >
                                  País del Teléfono
                                </Label>
                                <Input
                                  type="select"
                                  className="form-control"
                                  id="paisTelefone"
                                  name="paisTelefone"
                                  value={formData.paisTelefone}
                                  onChange={handleInputChange}
                                  invalid={!!errors.paisTelefone}
                                >
                                  <option value="">Seleccione el país</option>
                                  <option value="Brasil">Brasil</option>
                                  <option value="Argentina">Argentina</option>
                                  <option value="Chile">Chile</option>
                                </Input>
                                {errors.paisTelefone && (
                                  <FormFeedback>
                                    {errors.paisTelefone}
                                  </FormFeedback>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="telefone"
                                  className="form-label"
                                >
                                  Número de Teléfono
                                </Label>
                                <Input
                                  type="text"
                                  maxLength={20}
                                  className="form-control"
                                  id="telefone"
                                  name="telefone"
                                  placeholder={
                                    formData.paisTelefone === "Brasil"
                                      ? "+55 (11) 98765-4321"
                                      : formData.paisTelefone === "Argentina"
                                      ? "+54 2 22 3333-4444"
                                      : "+56 9 9999 9999"
                                  }
                                  value={formData.telefone}
                                  onChange={handleInputChange}
                                  invalid={!!errors.telefone}
                                />
                                {errors.telefone && (
                                  <FormFeedback>{errors.telefone}</FormFeedback>
                                )}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label htmlFor="website" className="form-label">
                                  Sitio Web
                                </Label>
                                <Input
                                  type="url"
                                  className="form-control"
                                  id="website"
                                  name="website"
                                  placeholder="Ingrese la URL del sitio web"
                                  value={formData.website}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </Col>
                          </Row>

                          <h5 className="mt-4">Direcciones</h5>
                          {enderecos.map((endereco, index) => (
                            <Row key={index}>
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label
                                    htmlFor={`pais_endereco_${index}`}
                                    className="form-label"
                                  >
                                    País
                                  </Label>
                                  <Input
                                    type="select"
                                    className="form-control"
                                    id={`pais_endereco_${index}`}
                                    value={endereco.pais}
                                    onChange={(e) => {
                                      const novosEnderecos = [...enderecos];
                                      novosEnderecos[index].pais =
                                        e.target.value;
                                      setEnderecos(novosEnderecos);
                                    }}
                                    invalid={!!errors[`endereco${index}Pais`]}
                                  >
                                    <option value="">Seleccione el país</option>
                                    <option value="Brasil">Brasil</option>
                                    <option value="Argentina">Argentina</option>
                                    <option value="Chile">Chile</option>
                                  </Input>
                                  {errors[`endereco${index}Pais`] && (
                                    <FormFeedback>
                                      {errors[`endereco${index}Pais`]}
                                    </FormFeedback>
                                  )}
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label
                                    htmlFor={`estado_${index}`}
                                    className="form-label"
                                  >
                                    {endereco.pais === "Brasil"
                                      ? "Estado"
                                      : "Provincia"}
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id={`estado_${index}`}
                                    value={endereco.estado}
                                    onChange={(e) => {
                                      const novosEnderecos = [...enderecos];
                                      novosEnderecos[index].estado =
                                        e.target.value;
                                      setEnderecos(novosEnderecos);
                                    }}
                                    invalid={!!errors[`endereco${index}Estado`]}
                                  />
                                  {errors[`endereco${index}Estado`] && (
                                    <FormFeedback>
                                      {errors[`endereco${index}Estado`]}
                                    </FormFeedback>
                                  )}
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label
                                    htmlFor={`cidade_${index}`}
                                    className="form-label"
                                  >
                                    Ciudad
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id={`cidade_${index}`}
                                    value={endereco.cidade}
                                    onChange={(e) => {
                                      const novosEnderecos = [...enderecos];
                                      novosEnderecos[index].cidade =
                                        e.target.value;
                                      setEnderecos(novosEnderecos);
                                    }}
                                    invalid={!!errors[`endereco${index}Cidade`]}
                                  />
                                  {errors[`endereco${index}Cidade`] && (
                                    <FormFeedback>
                                      {errors[`endereco${index}Cidade`]}
                                    </FormFeedback>
                                  )}
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label
                                    htmlFor={`cep_${index}`}
                                    className="form-label"
                                  >
                                    Código Postal
                                  </Label>
                                  <Input
                                    type="text"
                                    maxLength={20}
                                    className="form-control"
                                    id={`cep_${index}`}
                                    value={endereco.cep}
                                    onChange={(e) => {
                                      const novosEnderecos = [...enderecos];
                                      novosEnderecos[index].cep =
                                        e.target.value;
                                      setEnderecos(novosEnderecos);
                                    }}
                                    invalid={!!errors[`endereco${index}Cep`]}
                                  />
                                  {errors[`endereco${index}Cep`] && (
                                    <FormFeedback>
                                      {errors[`endereco${index}Cep`]}
                                    </FormFeedback>
                                  )}
                                </div>
                              </Col>
                              {index > 0 && (
                                <Col md={12} className="mb-2">
                                  <Button
                                    className="btn-sm"
                                    color="danger"
                                    onClick={() => removerEndereco(index)}
                                  >
                                    Eliminar Dirección
                                  </Button>
                                </Col>
                              )}
                            </Row>
                          ))}
                          <Button
                            color="primary"
                            onClick={adicionarEndereco}
                            className="btn-sm mt-2"
                          >
                            Agregar Dirección
                          </Button>

                          <h5 className="mt-4">Accionistas</h5>
                          {acionistas.map((acionista, index) => (
                            <Row key={index}>
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label
                                    htmlFor={`nome_completo_${index}`}
                                    className="form-label"
                                  >
                                    Nombre Completo
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id={`nome_completo_${index}`}
                                    value={acionista.nomeCompleto}
                                    onChange={(e) => {
                                      const novosAcionistas = [...acionistas];
                                      novosAcionistas[index].nomeCompleto =
                                        e.target.value;
                                      setAcionistas(novosAcionistas);
                                    }}
                                    invalid={!!errors[`acionista${index}Nome`]}
                                  />
                                  {errors[`acionista${index}Nome`] && (
                                    <FormFeedback>
                                      {errors[`acionista${index}Nome`]}
                                    </FormFeedback>
                                  )}
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label
                                    htmlFor={`pais_fiscal_acionista_${index}`}
                                    className="form-label"
                                  >
                                    País del Documento
                                  </Label>
                                  <Input
                                    type="select"
                                    className="form-control"
                                    id={`pais_fiscal_acionista_${index}`}
                                    value={acionista.paisFiscal}
                                    onChange={(e) => {
                                      const novosAcionistas = [...acionistas];
                                      novosAcionistas[index].paisFiscal =
                                        e.target.value;
                                      setAcionistas(novosAcionistas);
                                    }}
                                    invalid={
                                      !!errors[`acionista${index}PaisFiscal`]
                                    }
                                  >
                                    <option value="">Seleccione el país</option>
                                    <option value="Brasil">Brasil</option>
                                    <option value="Argentina">Argentina</option>
                                    <option value="Chile">Chile</option>
                                  </Input>
                                  {errors[`acionista${index}PaisFiscal`] && (
                                    <FormFeedback>
                                      {errors[`acionista${index}PaisFiscal`]}
                                    </FormFeedback>
                                  )}
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label
                                    htmlFor={`documento-fiscal-acionista-${index}`}
                                    className="form-label"
                                  >
                                    {acionista.paisFiscal === "Brasil"
                                      ? "CPF"
                                      : acionista.paisFiscal === "Argentina"
                                      ? "DNI"
                                      : "RUT"}
                                  </Label>
                                  <Input
                                    type="text"
                                    maxLength={20}
                                    className="form-control"
                                    id={`documento-fiscal-acionista-${index}`}
                                    value={acionista.documentoFiscal}
                                    onChange={(e) => {
                                      const novosAcionistas = [...acionistas];
                                      novosAcionistas[index].documentoFiscal =
                                        e.target.value;
                                      setAcionistas(novosAcionistas);
                                    }}
                                    invalid={
                                      !!errors[
                                        `acionista${index}DocumentoFiscal`
                                      ]
                                    }
                                  />
                                  {errors[
                                    `acionista${index}DocumentoFiscal`
                                  ] && (
                                    <FormFeedback>
                                      {
                                        errors[
                                          `acionista${index}DocumentoFiscal`
                                        ]
                                      }
                                    </FormFeedback>
                                  )}
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label
                                    htmlFor={`email_acionista_${index}`}
                                    className="form-label"
                                  >
                                    Correo Electrónico
                                  </Label>
                                  <Input
                                    type="email"
                                    className="form-control"
                                    id={`email_acionista_${index}`}
                                    value={acionista.email}
                                    onChange={(e) => {
                                      const novosAcionistas = [...acionistas];
                                      novosAcionistas[index].email =
                                        e.target.value;
                                      setAcionistas(novosAcionistas);
                                    }}
                                    invalid={!!errors[`acionista${index}Email`]}
                                  />
                                  {errors[`acionista${index}Email`] && (
                                    <FormFeedback>
                                      {errors[`acionista${index}Email`]}
                                    </FormFeedback>
                                  )}
                                </div>
                              </Col>
                              {index > 0 && (
                                <Col md={12} className="mb-2">
                                  <Button
                                    className="btn-sm"
                                    color="danger"
                                    onClick={() => removerAcionista(index)}
                                  >
                                    Remover Accionista
                                  </Button>
                                </Col>
                              )}
                            </Row>
                          ))}
                          <Button
                            color="primary"
                            onClick={adicionarAcionista}
                            className="mt-2 btn-sm"
                          >
                            Añadir Accionista
                          </Button>

                          <h5 className="mt-4">Documentos</h5>
                          <Row>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="doc_constituicao"
                                  className="form-label"
                                >
                                  Documento de Constitución de la Empresa
                                </Label>
                                <Input
                                  type="file"
                                  className="form-control"
                                  id="doc_constituicao"
                                  onChange={(e) =>
                                    handleFileChange(e, "docConstituicao")
                                  }
                                  invalid={!!errors.docConstituicao}
                                />
                                {errors.docConstituicao && (
                                  <FormFeedback>
                                    {errors.docConstituicao}
                                  </FormFeedback>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="doc_endereco"
                                  className="form-label"
                                >
                                  Comprobante de Domicilio
                                </Label>
                                <Input
                                  type="file"
                                  className="form-control"
                                  id="doc_endereco"
                                  onChange={(e) =>
                                    handleFileChange(e, "docEndereco")
                                  }
                                  invalid={!!errors.docEndereco}
                                />
                                {errors.docEndereco && (
                                  <FormFeedback>
                                    {errors.docEndereco}
                                  </FormFeedback>
                                )}
                              </div>
                            </Col>
                          </Row>

                          <Row className="mt-4 justify-content-center">
                            <Col md={12}>
                              <div className="form-check">
                                <Input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="aceptarPolitica"
                                  checked={politicaChecked}
                                  onChange={(e) =>
                                    setPoliticaChecked(e.target.checked)
                                  }
                                  required
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="aceptarPolitica"
                                >
                                  Acepto la{" "}
                                  <Link
                                    href="https://nautt.finance/wp-content/uploads/2024/12/3-DocOps-Politica-de-Privacidade.pdf"
                                    target="_blank"
                                  >
                                    política de privacidad
                                  </Link>
                                </Label>
                              </div>
                            </Col>
                          </Row>

                          <Row className="mt-4 justify-content-center">
                            <Col md={4}>
                              <Button
                                color="success"
                                className="w-100"
                                type="submit"
                                disabled={loading}
                              >
                                {loading ? (
                                  <Spinner size="sm" color="light" />
                                ) : (
                                  "Enviar Información"
                                )}
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    </CardBody>
                  )}
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default RegisterKyb;
