import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Alert,
  UncontrolledTooltip,
  Input,
  Modal
} from "reactstrap";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { toast } from 'react-toastify';

import BreadCrumb from "../../Components/Common/BreadCrumb";
import UiContent from "../../Components/Common/UiContent";
import { APIClient } from "../../helpers/api_helper";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import CreateCredentialModal from "./CreateCredentialModal";
import DeleteCredentialModal from "./DeleteCredentialModal";

const api = new APIClient();

const ApiData = () => {
  document.title = "Nautt API | Nautt Finance";
  const [apiCredentials, setApiCredentials] = useState([]);
  const [pdvs, setPdvs] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [newCredential, setNewCredential] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedCredential, setSelectedCredential] = useState(null);

  const toggle = () => {
    setIsCreateModalOpen(!isCreateModalOpen);
    if (!isCreateModalOpen) setNewCredential(null);
  };

  const fetchPdvs = async () => {
    try {
      const response = await api.get("/pdvs");
      if (response.sucesso) {
        setPdvs(response.dados);
      } else {
        if (response.mensagem !== "") {
          toast.error(response.mensagem);
        }
      }
      if (!response.sucesso && (!response.responseCode || response.responseCode === 401)) {
        await checkLastLogin();
      }
    } catch (error) {
      toast.error("Error al cargar los puntos de venta");
    }
  };

  const fetchApiCredentials = async () => {
    try {
      const response = await api.get("/api-data");
      if (response.sucesso) {
        setApiCredentials(response.dados);
      } else {
        if (response.mensagem !== "") {
          toast.error(response.mensagem);
        }
      }
      if (!response.sucesso && (!response.responseCode || response.responseCode === 401)) {
        await checkLastLogin();
      }
    } catch (error) {
      toast.error("Error al cargar las credenciales de API");
    }
  };

  const handleCreateCredential = async (pdvId) => {
    try {
      setLoading(true);
      const response = await api.post("/api-data", {
        pdv_id: pdvId
      });
      
      if (response.sucesso) {
        setNewCredential(response.dados);
        toast.success(response.mensagem);
        fetchApiCredentials();
      } else {
        console.error(response.mensagem);
        if (response.mensagem !== "") toast.error(response.mensagem);
      }
      if (!response.sucesso && (!response.responseCode || response.responseCode === 401)) {
        await checkLastLogin();
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteCredential = async () => {
    try {
      setLoading(true);
      const response = await api.delete("/api-data", {
        api_id: selectedCredential.id
      });
      
      if (response.sucesso) {
        toast.success(response.mensagem);
        fetchApiCredentials();
        toggleDeleteModal();
      } else {
        console.error(response.mensagem);
        if (response.mensagem !== "") toast.error(response.mensagem);
      }
      if (!response.sucesso && (!response.responseCode || response.responseCode === 401)) {
        await checkLastLogin();
      }
    } finally {
      setLoading(false);
    }
  };

  const toggleDeleteModal = (credential = null) => {
    setSelectedCredential(credential);
    setDeleteModal(!deleteModal);
  };

  const getBaseUrl = () => {
    const hostname = window.location.hostname;
    if (hostname.includes("sandbox")) {
      return "https://api-sandbox.nautt.finance/v1";
    } else {
      return "https://api.nautt.finance/v1";
    }
  };

  const copyToClipboard = (text) => {
    try {
      navigator.clipboard.writeText(text);
      toast.success("Copiado al portapapeles");
    } catch (error) {
      toast.error("Error al copiar al portapapeles");
    }
  };

  const baseUrl = getBaseUrl();

  useEffect(() => {
    fetchApiCredentials();
    fetchPdvs();
  }, []);

  return (
    <Fragment>
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Credenciales" pageTitle="API" />
          
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Nautt API</h4>
                  <div className="text-muted">
                    <p>La API de Nautt Finance permite integrar nuestros servicios financieros con su aplicación de manera segura y eficiente.</p>
                    
                    <h5 className="mt-4">Autenticación</h5>
                    <p>Para acceder a la API, necesitará:</p>
                    <ul>
                      <li><strong>Client ID:</strong> Identificador único de su aplicación</li>
                      <li><strong>Client Secret:</strong> Clave secreta para autenticar sus solicitudes</li>
                    </ul>

                    <div className="alert alert-info">
                      <FeatherIcon icon="info" className="me-2" />
                      El Client Secret se muestra solo una vez al crear las credenciales. Asegúrese de guardarlo en un lugar seguro.
                    </div>

                    <h5 className="mt-4">Seguridad</h5>
                    <p>Recomendaciones para mantener sus credenciales seguras:</p>
                    <ul>
                      <li>Nunca comparta su Client Secret</li>
                      <li>Rote sus credenciales periódicamente</li>
                      <li>Use variables de entorno para almacenar las credenciales</li>
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={6}>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <h4 className="card-title mb-0">Credenciales Disponibles</h4>
                    <Button color="primary" onClick={toggle}>
                      <FeatherIcon icon="plus" className="me-2" size={14} />
                      Nueva Credencial
                    </Button>
                  </div>

                  {baseUrl && (
                    <Alert color="info" className="mb-4">
                      <div className="d-flex align-items-center">
                        <FeatherIcon icon="info" className="me-2" size={18} />
                        <div className="flex-grow-1">
                          <strong>URL Base de la API:</strong>
                          <div className="mt-2 input-group">
                            <Input
                              type="text"
                              value={baseUrl}
                              readOnly
                            />
                            <Button
                              color="primary"
                              onClick={() => copyToClipboard(baseUrl)}
                            >
                              <FeatherIcon icon="copy" size={14} />
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Alert>
                  )}

                  {apiCredentials.length === 0 ? (
                    <Alert color="info">
                      No hay credenciales registradas. Cree una nueva para comenzar.
                    </Alert>
                  ) : (
                    apiCredentials.map((cred, index) => (
                      <Card key={index} className="border mb-3">
                        <CardBody>
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <h6 className="mb-2">Client ID</h6>
                              <code>{cred.client_id}</code>
                              {cred.pdv && (
                                <div className="mt-2 text-muted d-flex align-items-center">
                                  <FeatherIcon icon="link" size={14} className="me-1" />
                                  <small>PDV: {cred.pdv.nome}</small>
                                </div>
                              )}
                            </div>
                            <div className="d-flex">
                              <Button
                                color="light"
                                size="sm"
                                id={`copy-${index}`}
                                onClick={() => copyToClipboard(cred.client_id)}
                                className="me-2"
                              >
                                <FeatherIcon icon="copy" size={14} />
                              </Button>
                              <UncontrolledTooltip target={`copy-${index}`}>
                                Copiar Client ID
                              </UncontrolledTooltip>
                              <Button
                                color="danger"
                                size="sm"
                                id={`delete-${index}`}
                                onClick={() => toggleDeleteModal(cred)}
                              >
                                <FeatherIcon icon="trash-2" size={14} />
                              </Button>
                              <UncontrolledTooltip target={`delete-${index}`}>
                                Eliminar Credencial
                              </UncontrolledTooltip>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    ))
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <DeleteCredentialModal
            isOpen={deleteModal}
            toggle={() => toggleDeleteModal()}
            selectedCredential={selectedCredential}
            loading={loading}
            onDeleteCredential={handleDeleteCredential}
          />

          <CreateCredentialModal
            isOpen={isCreateModalOpen}
            toggle={() => setIsCreateModalOpen(!isCreateModalOpen)}
            newCredential={newCredential}
            loading={loading}
            onCreateCredential={handleCreateCredential}
            pdvs={pdvs}
          />
        </Container>
      </div>
    </Fragment>
  );
};

export default ApiData;
