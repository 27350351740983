import React from "react";
import { Col, Row } from "reactstrap";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const Section = ({ nauttPontos, userData }) => {
  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour >= 5 && hour < 12) return "¡Buenos días";
    if (hour >= 12 && hour < 19) return "¡Buenas tardes";
    return "¡Buenas noches";
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Copiado al portapapeles");
  };

  const baseUrl = window.location.origin;

  return (
    <React.Fragment>
      <Row className="mb-3 pb-1">
        <Col xs={12}>
          <div className="d-flex align-items-lg-center flex-lg-row flex-column">
            <div className="flex-grow-1">
              <h4 className="fs-16 mb-1">{getGreeting()}, {userData.name}!</h4>
              <p className="text-muted mb-0">
                Recomende Nautt a tus amigos y gana beneficios. <Link to="#" onClick={() => copyToClipboard(`${baseUrl}/kyc-wizard-onboarding?indicado=${userData.cod_afiliado}`)} className="text-info">Haz clic aquí para copiar tu enlace de afiliado</Link>
              </p>
            </div>
            <div className="mt-3 mt-lg-0">
              <form action="#">
                <Row className="g-3 mb-0 align-items-center">
                  <div className="col-auto">
                    <button type="button" className="btn btn-soft-warning">
                      <i className="ri-trophy-line align-middle me-1"></i>{" "}
                      {nauttPontos} Nautt Puntos
                    </button>
                  </div>
                  <div className="col-auto">
                    <button
                      type="button"
                      className="btn btn-soft-info"
                      onClick={() => copyToClipboard(userData.cod_afiliado)}
                    >
                      <FeatherIcon icon="copy" className="me-1" size={16} />{" "}
                      Código Afiliado
                    </button>
                  </div>
                  <div className="col-auto">
                    <Link
                      to="https://wa.me/message/ZZWGGICWNKSPK1"
                      target="_blank"
                      rel="noopener noreferrer"
                      type="button"
                      className="btn btn-soft-success"
                    >
                      <FeatherIcon icon="message-circle" className="me-1" size={16} />{" "}
                      Soporte
                    </Link>
                  </div>
                </Row>
              </form>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Section;
