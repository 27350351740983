import React, { useState, useCallback, useEffect } from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  FormFeedback,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import { toast } from "react-toastify";
import { APIClient } from "../../../helpers/api_helper";
import InputMask from "react-input-mask";
import FeatherIcon from "feather-icons-react";

const apiClient = new APIClient();

const StepTwo = ({ onComplete, data, countries, selectedCountry }) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    documento_fiscal: data.documento_fiscal || "",
    enderecos: (!data.enderecos || data.enderecos.length === 0) ? [
      {
        pais: "",
        estado: "",
        cidade: "",
        cep: "",
      },
    ] : data.enderecos,
  });
  const [errors, setErrors] = useState({});

  const getDocumentMask = () => {
    return selectedCountry?.tipo_documento?.mask_pj || "";
  };

  const getCepMask = (countryCode) => {
    const selectedCountry = countries.find(
      (country) => country.country_code === countryCode
    );
    return selectedCountry?.tipo_documento?.mask_cep || "";
  };

  const getDocumentLabel = () => {
    return selectedCountry?.tipo_documento?.doc_pj || "Documento Fiscal";
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    // Clear error when user types
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleAddressChange = (index, field, value) => {
    setFormData((prev) => {
      const newEnderecos = [...prev.enderecos];
      newEnderecos[index] = {
        ...newEnderecos[index],
        [field]: value,
      };
      return {
        ...prev,
        enderecos: newEnderecos,
      };
    });
    // Clear error when user types
    if (errors[`enderecos.${index}.${field}`]) {
      setErrors((prev) => ({ ...prev, [`enderecos.${index}.${field}`]: "" }));
    }
  };

  const addNewAddress = () => {
    setFormData((prev) => ({
      ...prev,
      enderecos: [
        ...prev.enderecos,
        {
          pais: "",
          estado: "",
          cidade: "",
          cep: "",
        },
      ],
    }));
  };

  const removeAddress = (index) => {
    if (formData.enderecos.length === 1) {
      toast.error("Es necesario tener al menos una dirección");
      return;
    }

    setFormData((prev) => ({
      ...prev,
      enderecos: prev.enderecos.filter((_, i) => i !== index),
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.documento_fiscal) {
      newErrors.documento_fiscal = "El documento fiscal es obligatorio";
    }

    formData.enderecos.forEach((endereco, index) => {
      if (!endereco.pais) {
        newErrors[`enderecos.${index}.pais`] = "El país es obligatorio";
      }
      if (!endereco.estado) {
        newErrors[`enderecos.${index}.estado`] = "El estado es obligatorio";
      }
      if (!endereco.cidade) {
        newErrors[`enderecos.${index}.cidade`] = "La ciudad es obligatoria";
      }
      if (!endereco.cep) {
        newErrors[`enderecos.${index}.cep`] = "El código postal es obligatorio";
      }
    });

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setLoading(true);

    try {
      const payload = {
        uuid: data.uuid,
        documento_fiscal: formData.documento_fiscal,
        enderecos: formData.enderecos,
      };

      const response = await apiClient.put("/kyb/enderecos-documento", payload);
      if (response.sucesso) {
        onComplete(formData);
      } else {
        toast.error(response.mensagem || "Error al guardar los datos");
      }
    } catch (error) {
      console.error(error);
      toast.error("Error al guardar los datos");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup className="mb-4">
        <Label for="documento_fiscal">
          {getDocumentLabel()} <span className="text-danger">*</span>
        </Label>
        <InputMask
          mask={getDocumentMask()}
          value={formData.documento_fiscal}
          onChange={handleInputChange}
        >
          {(inputProps) => (
            <Input
              {...inputProps}
              type="text"
              name="documento_fiscal"
              id="documento_fiscal"
              invalid={!!errors.documento_fiscal}
            />
          )}
        </InputMask>
        <FormFeedback>{errors.documento_fiscal}</FormFeedback>
      </FormGroup>

      <div className="mb-4">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h5 className="mb-0">Direcciones</h5>
          <Button
            color="success"
            size="sm"
            type="button"
            onClick={addNewAddress}
          >
            <FeatherIcon icon="plus" size={16} className="me-1" />
            Agregar Dirección
          </Button>
        </div>

        {formData.enderecos.map((endereco, index) => (
          <Card key={index} className="mb-3">
            <CardBody>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h6 className="mb-0">Dirección {index + 1}</h6>
                {formData.enderecos.length > 1 && (
                  <Button
                    color="danger"
                    size="sm"
                    type="button"
                    onClick={() => removeAddress(index)}
                  >
                    <FeatherIcon icon="trash-2" size={16} />
                  </Button>
                )}
              </div>

              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label>País <span className="text-danger">*</span></Label>
                    <Input
                      type="select"
                      value={endereco.pais}
                      onChange={(e) => handleAddressChange(index, "pais", e.target.value)}
                      invalid={!!errors[`enderecos.${index}.pais`]}
                    >
                      <option value="">Seleccione un país</option>
                      {countries.map((country) => (
                        <option key={country.country_code} value={country.country_code}>
                          {country.pais}
                        </option>
                      ))}
                    </Input>
                    <FormFeedback>{errors[`enderecos.${index}.pais`]}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>Estado/Provincia <span className="text-danger">*</span></Label>
                    <Input
                      type="text"
                      value={endereco.estado}
                      onChange={(e) => handleAddressChange(index, "estado", e.target.value)}
                      invalid={!!errors[`enderecos.${index}.estado`]}
                    />
                    <FormFeedback>{errors[`enderecos.${index}.estado`]}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label>Ciudad <span className="text-danger">*</span></Label>
                    <Input
                      type="text"
                      value={endereco.cidade}
                      onChange={(e) => handleAddressChange(index, "cidade", e.target.value)}
                      invalid={!!errors[`enderecos.${index}.cidade`]}
                    />
                    <FormFeedback>{errors[`enderecos.${index}.cidade`]}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>Código Postal <span className="text-danger">*</span></Label>
                    <InputMask
                      mask={getCepMask(endereco.pais)}
                      value={endereco.cep}
                      onChange={(e) => handleAddressChange(index, "cep", e.target.value)}
                    >
                      {(inputProps) => (
                        <Input
                          {...inputProps}
                          type="text"
                          invalid={!!errors[`enderecos.${index}.cep`]}
                        />
                      )}
                    </InputMask>
                    <FormFeedback>{errors[`enderecos.${index}.cep`]}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        ))}
      </div>

      <div className="mt-4">
        <Button
          color="success"
          className="w-100"
          type="submit"
          disabled={loading}
        >
          {loading ? <Spinner size="sm" /> : "Continuar"}
        </Button>
      </div>
    </Form>
  );
};

export default StepTwo;
