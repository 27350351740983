import React, { useEffect, useState, Fragment, useCallback } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Modal,
  Row,
  Spinner,
} from "reactstrap";
import Paginacao from "../../Components/Common/Paginacao";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import UiContent from "../../Components/Common/UiContent";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";

import SearchOption from "../../Components/Common/SearchOption";
import ModalApagar from "./ModalApagar";
import ModalNovoLink from "../LinksPagamento/ModalNovo";
import ModalNovo from "./ModalNovo";
import ModalEditar from "./ModalEditar";

import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";

const apiClient = new APIClient();

const PontosDeVenda = () => {
  document.title = "Puntos de Venda | Nautt Finance";

  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadTable, setLoadTable] = useState(true);

  const [pdvs, setPdvs] = useState([]);
  const [pdv, setPdv] = useState(null);
  const [itensPorPagina, setItensPorPagina] = useState(50);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [totalDePdvs, setTotalDePdvs] = useState(0);
  const [termoBusca, setTermoBusca] = useState("");

  const [modalEditar, setModalEditar] = useState(false);
  const [modalNovo, setModalNovo] = useState(false);
  const [modalApagar, setModalApagar] = useState(false);
  const [modalLinkPagamento, setModalLinkPagamento] = useState(false);
  const [pdvSelecionadoParaLink, setPdvSelecionadoParaLink] = useState(null);

  const toggleModalEditar = useCallback(
    (seg) => {
      setPdv(seg);
      setModalEditar(!modalEditar);
    },
    [modalEditar]
  );

  const toggleModalNovo = useCallback(() => {
    setModalNovo(!modalNovo);
  }, [modalNovo]);

  const toggleModalApagar = useCallback(
    (seg) => {
      setPdv(seg);
      setModalApagar(!modalApagar);
    },
    [modalApagar]
  );

  const toggleModalLinkPagamento = useCallback(
    (pdv) => {
      setPdvSelecionadoParaLink(pdv.id);
      setModalLinkPagamento(!modalLinkPagamento);
    },
    [modalLinkPagamento]
  );

  const fetchPdvs = useCallback(
    async (itensPorPagina, paginaAtual, termoBusca) => {
      const data = {
        itens_pagina: itensPorPagina,
        pagina: paginaAtual,
        termo: termoBusca,
        order_by: "created_at",
        order: "desc",
      };

      const response = await apiClient.post("/pdvs/busca", data);
      if (response.sucesso) {
        const dados = response.dados;
        setPdvs(dados.data);
        setTotalDePdvs(dados.total);
      } else {
        console.error(response.mensagem);
        if (!response.mensagem.includes(" 401"))
          if (response.mensagem !== "") toast.error(response.mensagem);
      }
      if (
        !response.sucesso &&
        (!response.responseCode || response.responseCode === 401)
      ) {
        await checkLastLogin();
      }
    },
    []
  );

  const refreshUser = useCallback(async () => {
    window.dispatchEvent(new Event("localDataUpdated"));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoadTable(true);
      await fetchPdvs(itensPorPagina, paginaAtual, termoBusca);
      await fetchPdvs();
      if (refresh) {
        await refreshUser();
        setRefresh(false);
      }
      setLoadTable(false);
    };

    fetchData();
    setLoading(false);
  }, [loading, itensPorPagina, paginaAtual, termoBusca, refresh]);

  return (
    <Fragment>
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Puntos de Venta" pageTitle="Ventas" />
          <Card>
            <Row className="justify-content-between align-items-center mb-2 me-2 ms-2">
              <Col xl={3}>
                <label className="form-label">Ítems por página</label>
                <select
                  className="form-select mt-1"
                  aria-label=""
                  onChange={(e) => {
                    setItensPorPagina(parseInt(e.target.value));
                    setPaginaAtual(1);
                    setLoadTable(true);
                    setLoading(true);
                  }}
                  value={itensPorPagina}
                >
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                </select>
              </Col>
              <Col xl={9} className="mb-0" style={{ marginTop: "32px" }}>
                <SearchOption
                  setTermoBusca={setTermoBusca}
                  setPaginaAtual={setPaginaAtual}
                  termoBusca={termoBusca}
                  setLoading={setLoading}
                  setLoadTable={setLoadTable}
                />
              </Col>
            </Row>
          </Card>

          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Pdvs</h4>
                  <div className="flex-shrink-0">
                    <Link
                      to="#"
                      className="btn btn-soft-secondary"
                      onClick={() => {
                        toggleModalNovo();
                      }}
                    >
                      Nuevo Pdv
                    </Link>
                  </div>
                </CardHeader>
                <CardBody>
                  {loading || loadTable ? (
                    <Row className="justify-content-center mt-4 pt-2 text-center">
                      <Spinner />
                    </Row>
                  ) : (
                    <div className="live-preview">
                      <div className="table-responsive table-card">
                        <table className="table align-middle table-nowrap table-striped-columns mb-1">
                          <thead className="table-light">
                            <tr>
                              <th scope="col">ID #</th>
                              <th scope="col">Nombre</th>
                              <th scope="col">Punto de Venta</th>
                              <th scope="col">Acciones</th>
                            </tr>
                          </thead>
                          <tbody>
                            {pdvs &&
                              pdvs.length !== 0 &&
                              pdvs.map((pdv, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{pdv.id}</td>
                                    <td
                                      style={{
                                        overflowWrap: "break-word",
                                        whiteSpace: "pre-wrap",
                                      }}
                                    >
                                      <p className="mb-0">
                                        <b>{pdv.nome}</b>
                                      </p>
                                    </td>
                                    <td>
                                      <Link
                                        to={`/ponto-de-venda/${pdv.id}`}
                                        className="btn btn-sm btn-secondary btn-label right ms-auto"
                                      >
                                        <i className="ri-arrow-right-line label-icon align-bottom fs-16 ms-2"></i>{" "}
                                        Página del PDV
                                      </Link>
                                    </td>
                                    <td>
                                      <div className="d-flex gap-2">
                                        <Link
                                          to="#"
                                          className="text-success"
                                          title="Editar"
                                          onClick={() => {
                                            toggleModalEditar(pdv);
                                          }}
                                        >
                                          <FeatherIcon
                                            icon="edit"
                                            width="14"
                                          />
                                        </Link>
                                        <Link
                                          to="#"
                                          className="text-primary"
                                          title="Generar Link de Pago"
                                          onClick={() => {
                                            toggleModalLinkPagamento(pdv);
                                          }}
                                        >
                                          <FeatherIcon
                                            icon="link"
                                            width="14"
                                          />
                                        </Link>
                                        <Link
                                          to="#"
                                          className="text-danger"
                                          title="Borrar"
                                          onClick={() => {
                                            toggleModalApagar(pdv);
                                          }}
                                        >
                                          <FeatherIcon
                                            icon="trash-2"
                                            width="14"
                                          />
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}

                  {!loading && !loadTable && pdvs.length === 0 ? (
                    <Row>
                      <small className="text-muted text-center mt-5 mb-5">
                        Ningún pdv encontrado
                      </small>
                    </Row>
                  ) : (
                    <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
                      <div className="col-sm">
                        <div className="text-muted">
                          Mostrando{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              (paginaAtual - 1) * itensPorPagina + 1,
                              totalDePdvs
                            )}
                          </span>{" "}
                          a{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              paginaAtual * itensPorPagina,
                              totalDePdvs
                            )}
                          </span>{" "}
                          de <span className="fw-semibold">{totalDePdvs}</span>{" "}
                          resultados
                        </div>
                      </div>
                      <div className="col-sm-auto mt-3 mt-sm-0">
                        <Paginacao
                          total={totalDePdvs}
                          currentPage={paginaAtual}
                          setCurrentPage={setPaginaAtual}
                          perPageData={itensPorPagina}
                        />
                      </div>
                    </div>
                  )}
                  <ModalNovo
                    modal={modalNovo}
                    setModal={setModalNovo}
                    setRefresh={setRefresh}
                  />
                  <ModalEditar
                    modal={modalEditar}
                    setModal={setModalEditar}
                    pdv={pdv}
                    setRefresh={setRefresh}
                  />
                  <ModalApagar
                    modal={modalApagar}
                    setModal={setModalApagar}
                    pdv={pdv}
                    setRefresh={setRefresh}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ModalNovoLink
            modal={modalLinkPagamento}
            setModal={setModalLinkPagamento}
            setRefresh={setRefresh}
            pdvId={pdvSelecionadoParaLink}
          />
        </Container>
      </div>
    </Fragment>
  );
};

export default PontosDeVenda;
