import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
  FormGroup,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
import { NumericFormat } from "react-number-format";
import FeatherIcon from "feather-icons-react";

const apiClient = new APIClient();

const ModalEditar = ({ modal, setModal, retirada, setRefresh, userDados }) => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [cotacao, setCotacao] = useState("");
  const [amountFinal, setAmountFinal] = useState("");
  const [lucroNautt, setLucroNautt] = useState("");

  const [dadosConta, setDadosConta] = useState("dados_deposito");

  const toggle = () => {
    setStatus("");
    setCotacao("");
    setAmountFinal("");
    setLucroNautt("");
    setModal(!modal);
  };

  const isReadOnly = ["finalizada", "cancelada"].includes(retirada?.status);

  const copyToClipboard = (text, fieldName) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success(`${fieldName} copiado al portapapeles`);
    });
  };

  const AccountField = ({ label, value, showCopy = true }) => {
    if (!value) return null;

    return (
      <Col md={6}>
        <div className="d-flex align-items-center mb-2">
          <div className="flex-grow-1">
            <small className="text-muted">{label}</small>
            <div className="fw-medium">{value}</div>
          </div>
          {showCopy && (
            <div className="ms-2">
              <FeatherIcon
                icon="copy"
                size={16}
                className="cursor-pointer text-muted"
                onClick={() => copyToClipboard(value, label)}
                style={{ cursor: "pointer" }}
              />
            </div>
          )}
        </div>
      </Col>
    );
  };

  const editar = async () => {
    if (isReadOnly) return;

    setLoading(true);

    const data = {
      status: status,
      retirada_id: retirada.id,
    };

    if (retirada?.converter) {
      data.cotacao = parseFloat(cotacao);
      data.amount_final = parseFloat(amountFinal);
      data.lucro_nautt = parseFloat(lucroNautt);
    }

    const response = await apiClient.put(`/retiradas`, data);

    if (response.sucesso) {
      toast.success(response.mensagem);
      setRefresh(true);
      toggle();
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  useEffect(() => {
    const fetchDados = async () => {
      if (retirada?.converter) {
        setCotacao(retirada.cotacao);
        setAmountFinal(retirada.amount_final);
        setLucroNautt(retirada.lucro_nautt);
        setStatus(retirada.status);
      }
    };

    fetchDados();
  }, [modal]);

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        {isReadOnly ? "Ver Retiro" : "Editar Retiro"}
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Token</Label>
              <Input
                type="text"
                value={
                  `${retirada?.token?.sigla} (${retirada?.token?.rede?.nome})` ||
                  ""
                }
                disabled
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Monto Original (USD)</Label>
              <NumericFormat
                value={
                  userDados?.role === "admin"
                    ? retirada?.amount
                    : (
                        parseFloat(retirada?.amount || "0") +
                        parseFloat(retirada?.lucro_nautt || "0")
                      ).toFixed(2)
                }
                className="form-control"
                disabled
                allowNegative={false}
                decimalScale={2}
                decimalSeparator="."
                thousandSeparator=","
              />
            </FormGroup>
          </Col>

          {retirada?.converter ? (
            <>
              <Col md={6}>
                <FormGroup>
                  <Label>Cotización</Label>
                  <NumericFormat
                    value={cotacao}
                    onValueChange={(values) => setCotacao(values.value)}
                    className="form-control"
                    disabled={isReadOnly}
                    allowNegative={false}
                    decimalScale={2}
                    decimalSeparator="."
                    thousandSeparator=","
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Por Cobrar</Label>
                  <NumericFormat
                    value={amountFinal}
                    onValueChange={(values) => setAmountFinal(values.value)}
                    className="form-control"
                    disabled
                    allowNegative={false}
                    decimalScale={2}
                    decimalSeparator="."
                    thousandSeparator=","
                  />
                </FormGroup>
              </Col>
              {userDados?.role === "admin" && (
                <Col md={6}>
                  <FormGroup>
                    <Label>Tasa (USD)</Label>
                    <NumericFormat
                      value={lucroNautt}
                      onValueChange={(values) => setLucroNautt(values.value)}
                      className="form-control"
                      disabled={isReadOnly}
                      allowNegative={false}
                      decimalScale={2}
                      decimalSeparator="."
                      thousandSeparator=","
                    />
                  </FormGroup>
                </Col>
              )}
              <Col md={6}>
                <FormGroup>
                  <Label>Moneda</Label>
                  <Input
                    type="text"
                    value={`${retirada?.moeda?.nome} (${retirada?.moeda?.sigla}) - ${retirada?.moeda?.pais}`}
                    disabled
                  />
                </FormGroup>
              </Col>
            </>
          ) : (
            <>
              <Col md={6}>
                <FormGroup>
                  <Label>Por Cobrar (USD)</Label>
                  <NumericFormat
                    value={retirada?.amount_final || ""}
                    className="form-control"
                    disabled
                    allowNegative={false}
                    decimalScale={2}
                    decimalSeparator="."
                    thousandSeparator=","
                  />
                </FormGroup>
              </Col>
              {userDados?.role === "admin" && (
                <Col md={6}>
                  <FormGroup>
                    <Label>Tasa (USD)</Label>
                    <NumericFormat
                      value={retirada?.lucro_nautt || ""}
                      className="form-control"
                      disabled
                      allowNegative={false}
                      decimalScale={2}
                      decimalSeparator="."
                      thousandSeparator=","
                    />
                  </FormGroup>
                </Col>
              )}
            </>
          )}
          <Col md={userDados?.role === "admin" ? 12 : 6}>
            <FormGroup>
              <Label>Estado</Label>
              <Input
                type="select"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                disabled={isReadOnly}
              >
                <option value="">Seleccionar Estado</option>
                <option value="nova">Nueva</option>
                <option value="processando">Procesando</option>
                <option value="finalizada">Finalizada</option>
                <option value="cancelada">Cancelada</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-3">
          {retirada?.converter && (
            <Col md={12} className="mb-3">
              <Nav pills className="nav-pills-custom">
                <NavItem>
                  <NavLink
                    className={dadosConta === "dados_deposito" ? "active" : ""}
                    onClick={() => setDadosConta("dados_deposito")}
                    style={{ cursor: "pointer" }}
                  >
                    Datos de Depósito
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={dadosConta === "dados_cliente" ? "active" : ""}
                    onClick={() => setDadosConta("dados_cliente")}
                    style={{ cursor: "pointer" }}
                  >
                    Datos adicionales del Cliente
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          )}
          {retirada?.converter ? (
            dadosConta === "dados_deposito" ? (
              <>
                <Col md={12}>
                  <FormGroup>
                    <Label>Datos de Depósito</Label>
                    <Card className="mb-0">
                      <CardBody className="p-4">
                        <Row>
                          <AccountField
                            label="Nombre de la Cuenta"
                            value={retirada?.conta?.nome}
                            showCopy={false}
                          />
                          <AccountField
                            label="País"
                            value={retirada?.conta?.pais}
                            showCopy={false}
                          />
                          <AccountField
                            label="Tipo"
                            value={
                              retirada?.conta?.tipo === "fisica"
                                ? "Física"
                                : "Jurídica"
                            }
                            showCopy={false}
                          />
                          <AccountField
                            label="Banco"
                            value={retirada?.conta?.banco}
                          />
                          <AccountField
                            label="Agencia"
                            value={retirada?.conta?.agencia}
                          />
                          <AccountField
                            label="Cuenta"
                            value={retirada?.conta?.conta}
                          />
                          <AccountField
                            label="PIX"
                            value={retirada?.conta?.pix_alias}
                          />
                          <AccountField
                            label="Nombre del Destinatario"
                            value={retirada?.conta?.nome_destinatario}
                          />
                          <AccountField
                            label="Documento"
                            value={retirada?.conta?.documento}
                          />
                        </Row>
                        {retirada?.carteira_retirada &&
                          userDados?.role === "admin" && (
                            <Row className="mt-3">
                              {retirada?.carteira_retirada !==
                                "0x0000000000000000000000000000000000000000" && (
                                <Col md={6}>
                                  <div className="d-flex align-items-center mb-2">
                                    <div className="flex-grow-1">
                                      <small className="text-muted">
                                        Carteira Retirada (Polygon)
                                      </small>
                                      <div className="fw-medium">
                                        {retirada?.carteira_retirada
                                          ? `${retirada?.carteira_retirada.slice(
                                              0,
                                              5
                                            )}...${retirada?.carteira_retirada.slice(
                                              -4
                                            )}`
                                          : ""}
                                      </div>
                                    </div>
                                    <div className="ms-2">
                                      <FeatherIcon
                                        icon="copy"
                                        size={16}
                                        className="cursor-pointer text-muted"
                                        onClick={() =>
                                          copyToClipboard(
                                            retirada?.carteira_retirada,
                                            "Carteira Retirada (Polygon)"
                                          )
                                        }
                                        style={{ cursor: "pointer" }}
                                      />
                                    </div>
                                  </div>
                                </Col>
                              )}
                              <AccountField
                                label="Valor USDT"
                                value={parseFloat(retirada?.amount).toFixed(6)}
                              />
                            </Row>
                          )}
                      </CardBody>
                    </Card>
                  </FormGroup>
                </Col>
              </>
            ) : (
              <Col md={12}>
                <FormGroup>
                  <Label>Datos adicionales del Cliente</Label>
                  <Card className="mb-0">
                    <CardBody className="p-4">
                      <Row>
                        <AccountField
                          label="Fecha de Nacimiento"
                          value={retirada?.conta?.data_nascimento}
                          showCopy={true}
                        />
                        <AccountField
                          label="Actividad"
                          value={retirada?.conta?.atividade}
                          showCopy={true}
                        />
                        <AccountField
                          label="Provincia"
                          value={retirada?.conta?.provincia}
                          showCopy={true}
                        />
                        <AccountField
                          label="Ciudad"
                          value={retirada?.conta?.cidade}
                          showCopy={true}
                        />
                        <AccountField
                          label="Código Postal"
                          value={retirada?.conta?.codigo_postal}
                          showCopy={true}
                        />
                        <AccountField
                          label="Dirección"
                          value={retirada?.conta?.endereco}
                          showCopy={true}
                        />
                        <AccountField
                          label="Barrio"
                          value={retirada?.conta?.bairro}
                          showCopy={true}
                        />
                        <AccountField
                          label="Teléfono"
                          value={retirada?.conta?.telefone}
                          showCopy={true}
                        />
                        <AccountField
                          label="Email"
                          value={retirada?.conta?.email}
                          showCopy={true}
                        />
                        <AccountField
                          label="IBAN"
                          value={retirada?.conta?.iban}
                          showCopy={true}
                        />
                        <AccountField
                          label="SWIFT/BIC"
                          value={retirada?.conta?.swift_bic}
                          showCopy={true}
                        />
                        <AccountField
                          label="Routing Number"
                          value={retirada?.conta?.routing_number}
                          showCopy={true}
                        />
                      </Row>
                    </CardBody>
                  </Card>
                </FormGroup>
              </Col>
            )
          ) : (
            <Col md={12}>
              <FormGroup>
                <Label>Wallet Cripto</Label>
                <Input
                  type="text"
                  value={retirada?.carteira?.carteira || ""}
                  disabled
                />
              </FormGroup>
            </Col>
          )}
        </Row>

        <div className="hstack gap-2 justify-content-end mt-5">
          <Link
            to="#"
            className="btn btn-link link-primary fw-medium"
            onClick={() => toggle()}
            disabled={loading}
          >
            <i className="ri-close-line me-1 align-middle"></i> Cerrar
          </Link>
          {!isReadOnly && (
            <button
              className="btn btn-primary"
              onClick={() => editar()}
              disabled={loading}
            >
              Guardar
            </button>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalEditar;
