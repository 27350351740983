import React, { useEffect, useState, Fragment, useCallback } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
  Badge,
} from "reactstrap";
import Paginacao from "../../Components/Common/Paginacao";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import UiContent from "../../Components/Common/UiContent";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";

import SearchOption from "../../Components/Common/SearchOption";
import ModalApagar from "./ModalApagar";
import ModalNovo from "./ModalNovo";
import ModalEditar from "./ModalEditar";
import ModalRecibo from "./ModalRecibo";
import ModalNotas from "./ModalNotas";

import { toast } from "react-toastify";
import {
  formatCurrency,
  converterData,
} from "../../Components/FunctionsRepository";
import { APIClient, getLoggedinUser } from "../../helpers/api_helper";

const apiClient = new APIClient();

const getStatusBadge = (status) => {
  const statusConfig = {
    nova: { color: "info", text: "Nueva" },
    processando: { color: "warning", text: "Procesando" },
    finalizada: { color: "success", text: "Finalizada" },
    cancelada: { color: "danger", text: "Cancelada" },
  };

  const config = statusConfig[status] || { color: "secondary", text: status };
  return <Badge color={config.color}>{config.text}</Badge>;
};

const getFileExtension = (url) => {
  if (!url) return "";
  const match = url.match(/\.([^.]+)$/);
  return match ? match[1].toLowerCase() : "";
};

const Retiros = () => {
  document.title = "Retiros | Nautt Finance";

  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadTable, setLoadTable] = useState(true);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [userData, setUserData] = useState(null);

  const [retiradas, setRetiros] = useState([]);
  const [retirada, setRetiro] = useState(null);
  const [itensPorPagina, setItensPorPagina] = useState(50);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [totalDeRetiros, setTotalDeRetiros] = useState(0);
  const [termoBusca, setTermoBusca] = useState("");

  const [modalEditar, setModalEditar] = useState(false);
  const [modalNovo, setModalNovo] = useState(false);
  const [modalApagar, setModalApagar] = useState(false);
  const [modalRecibo, setModalRecibo] = useState(false);
  const [modalNotas, setModalNotas] = useState(false);

  const toggleModalEditar = useCallback(
    (seg) => {
      setRetiro(seg);
      setModalEditar(!modalEditar);
    },
    [modalEditar]
  );

  const toggleModalNovo = useCallback(() => {
    setModalNovo(!modalNovo);
  }, [modalNovo]);

  const toggleModalApagar = useCallback(
    (seg) => {
      setRetiro(seg);
      setModalApagar(!modalApagar);
    },
    [modalApagar]
  );

  const toggleModalRecibo = useCallback(
    (seg) => {
      setRetiro(seg);
      setModalRecibo(!modalRecibo);
    },
    [modalRecibo]
  );

  const toggleModalNotas = useCallback(
    (seg) => {
      setRetiro(seg);
      setModalNotas(!modalNotas);
    },
    [modalNotas]
  );

  const handleReceiptView = async (retirada) => {
    setLoadingDownload(true);
    try {
      if (!retirada.converter && retirada.recibo_url) {
        // For non-converter retiradas, open the URL
        window.open(retirada.recibo_url, "_blank");
      } else if (retirada.converter && retirada.recibo_url) {
        // For converter retiradas, download the file using postDownload
        const response = await apiClient.postDownload(`/documento/download`, {
          recibo_url: retirada.recibo_url,
        });

        if (!response.sucesso || !response.dados) {
          toast.error(response.mensagem || "No se pudo descargar el recibo");
          return;
        }

        // Get content type from response headers or file extension
        const contentType =
          response.headers?.["content-type"] ||
          (() => {
            const extension = getFileExtension(retirada.recibo_url);
            const mimeTypes = {
              pdf: "application/pdf",
              jpg: "image/jpeg",
              jpeg: "image/jpeg",
              png: "image/png",
            };
            return mimeTypes[extension] || "application/octet-stream";
          })();

        // Create blob using the response data and content type
        const blob = new Blob([response.dados], { type: contentType });

        // Verify blob is not empty
        if (blob.size === 0) {
          toast.error("El archivo del recibo está vacío");
          return;
        }

        // Get file extension from URL or content type
        const extension =
          getFileExtension(retirada.recibo_url) ||
          contentType.split("/")[1] ||
          "pdf";

        // Create download link
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `recibo_${retirada.id}.${extension}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      } else {
        toast.error("No hay recibo disponible");
      }
    } catch (error) {
      console.error("Error viewing/downloading receipt:", error);
      toast.error(error.mensagem || "Error al ver o descargar el recibo");
    } finally {
      setLoadingDownload(false);
    }
  };

  const fetchRetiros = useCallback(
    async (itensPorPagina, paginaAtual, termoBusca) => {
      const data = {
        itens_pagina: itensPorPagina,
        pagina: paginaAtual,
        termo: termoBusca,
        order_by: "created_at",
        order: "desc",
      };

      const response = await apiClient.post("/retiradas/busca", data);
      if (response.sucesso) {
        const dados = response.dados;
        setRetiros(dados.data);
        setTotalDeRetiros(dados.total);
      } else {
        console.error(response.mensagem);
        if (!response.mensagem.includes(" 401"))
          if (response.mensagem !== "") toast.error(response.mensagem);
      }
    },
    []
  );

  const changePage = (page) => {
    if (page !== paginaAtual) {
      setPaginaAtual(page);
      setLoadTable(true);
      setRefresh(true);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const usuarioDados = await getLoggedinUser();
        setUserData(usuarioDados);
        setLoadTable(true);
        await fetchRetiros(itensPorPagina, paginaAtual, termoBusca);
        setRefresh(false);
      } catch (error) {
        console.error("Erro ao carregar dados:", error);
      } finally {
        setLoadTable(false);
        setLoading(false);
      }
    };

    if (refresh || (retiradas.length === 0 && loading)) {
      fetchData();
    }
  }, [itensPorPagina, paginaAtual, termoBusca, refresh, fetchRetiros]);

  return (
    <Fragment>
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Registro de Retiros" pageTitle="Retiros" />
          <Card>
            <Row className="justify-content-between align-items-center mb-2 me-2 ms-2">
              <Col xl={3}>
                <label className="form-label">Ítems por página</label>
                <select
                  className="form-select mt-1"
                  aria-label=""
                  onChange={(e) => {
                    setItensPorPagina(parseInt(e.target.value));
                    setPaginaAtual(1);
                    setLoadTable(true);
                    setRefresh(true);
                  }}
                  value={itensPorPagina}
                >
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                </select>
              </Col>
              <Col xl={9} className="mb-0" style={{ marginTop: "32px" }}>
                <SearchOption
                  setTermoBusca={setTermoBusca}
                  setPaginaAtual={setPaginaAtual}
                  termoBusca={termoBusca}
                  setLoading={setRefresh}
                  setLoadTable={setLoadTable}
                />
              </Col>
            </Row>
          </Card>

          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Retiros</h4>
                  <div className="flex-shrink-0">
                    <Link
                      to="#"
                      className="btn btn-soft-secondary"
                      onClick={() => {
                        toggleModalNovo();
                      }}
                    >
                      Nuevo Retiro
                    </Link>
                  </div>
                </CardHeader>
                <CardBody>
                  {loading || loadTable ? (
                    <Row className="justify-content-center mt-4 pt-2 text-center">
                      <Spinner />
                    </Row>
                  ) : (
                    <div className="live-preview">
                      <div className="table-responsive table-card">
                        <table className="table align-middle table-nowrap table-striped-columns mb-1">
                          <thead className="table-light">
                            <tr>
                              <th scope="col">ID</th>
                              <th scope="col">Usuario</th>
                              <th scope="col">Token</th>
                              <th scope="col">Valor</th>
                              <th scope="col">Destino</th>
                              <th scope="col">Estado</th>
                              {userData?.role === "admin" && (
                                <th scope="col">Notas Fiscales</th>
                              )}
                              <th scope="col">Acciones</th>
                            </tr>
                          </thead>
                          <tbody>
                            {retiradas &&
                              retiradas?.length !== 0 &&
                              retiradas?.map((retirada, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      {retirada.id}
                                      <br />
                                      <small className="text-muted">
                                        {converterData(retirada.created_at)}
                                      </small>
                                    </td>
                                    <td>{retirada.user?.nome}</td>
                                    <td>
                                      {retirada.token?.sigla}
                                      {retirada.converter && retirada.moeda && (
                                        <span> → {retirada.moeda.sigla}</span>
                                      )}
                                    </td>
                                    <td>
                                      Monto:{" "}
                                      <small className="text-muted">
                                        {formatCurrency(
                                          userData?.role === "admin"
                                            ? retirada.amount
                                            : (
                                                parseFloat(
                                                  retirada.amount || "0"
                                                ) +
                                                parseFloat(
                                                  retirada.lucro_nautt || "0"
                                                )
                                              ).toFixed(2),
                                          "USD"
                                        )}
                                      </small>
                                      <br />
                                      Por Cobrar:{" "}
                                      <small className="text-muted">
                                        {formatCurrency(
                                          retirada.amount_final,
                                          retirada.moeda
                                            ? retirada.moeda.sigla
                                            : "USD"
                                        )}
                                      </small>
                                    </td>
                                    <td>
                                      {retirada.sistema === "nautt" ? (
                                        <>
                                          <small className="text-muted">
                                            Conta Nautt
                                          </small>
                                          <br />
                                          <span className="font-monospace">
                                            {retirada.remetente?.nome}
                                          </span>
                                        </>
                                      ) : retirada.converter ? (
                                        <>
                                          <small className="text-muted">
                                            Cuenta
                                          </small>
                                          <br />
                                          <span className="font-monospace">
                                            {retirada.conta?.nome}
                                          </span>
                                        </>
                                      ) : (
                                        <>
                                          <small className="text-muted">
                                            Wallet
                                          </small>
                                          <br />
                                          <span className="font-monospace">
                                            {retirada.carteira?.nome}
                                          </span>
                                        </>
                                      )}
                                    </td>
                                    <td>{getStatusBadge(retirada.status)}</td>
                                    {userData?.role === "admin" && (
                                      <td>
                                        {retirada.notas_fiscais?.length ===
                                        0 ? (
                                          <small className="text-danger">
                                            No
                                          </small>
                                        ) : (
                                          <Link
                                            to="#"
                                            className="btn btn-soft-info btn-sm"
                                            onClick={() =>
                                              toggleModalNotas(retirada)
                                            }
                                          >
                                            Si ({retirada.notas_fiscais.length})
                                          </Link>
                                        )}
                                      </td>
                                    )}
                                    <td>
                                      {userData?.role === "admin" ||
                                      (userData?.role === "lojista" &&
                                        !["finalizada", "cancelada"].includes(
                                          retirada.status
                                        )) ? (
                                        <Link
                                          to="#"
                                          className="text-success"
                                          title="Editar"
                                          onClick={() => {
                                            toggleModalEditar(retirada);
                                          }}
                                        >
                                          <FeatherIcon
                                            icon="edit"
                                            width="14"
                                            className="me-3"
                                          />
                                        </Link>
                                      ) : null}
                                      {!["finalizada", "cancelada"].includes(
                                        retirada.status
                                      ) &&
                                      (userData?.role === "admin" ||
                                        (userData?.role === "lojista" &&
                                          retirada.status === "nova")) ? (
                                        <>
                                          <Link
                                            to="#"
                                            className="text-danger"
                                            title="Cancelar"
                                            onClick={() => {
                                              toggleModalApagar(retirada);
                                            }}
                                          >
                                            <FeatherIcon
                                              icon="trash-2"
                                              width="14"
                                              className="me-3"
                                            />
                                          </Link>
                                        </>
                                      ) : null}
                                      {retirada.status === "finalizada" && (
                                        <Link
                                          to={`/recibo/${retirada.uuid}`}
                                          className="text-info"
                                          title="Ver Recibo"
                                          target="_blank"
                                        >
                                          <FeatherIcon
                                            icon="file-text"
                                            width="14"
                                            className="me-3"
                                          />
                                        </Link>
                                      )}
                                      {userData?.role === "admin" &&
                                        retirada.status === "finalizada" &&
                                        (retirada.recibo_url === "" ||
                                          !retirada.recibo_url) &&
                                        (retirada.moeda?.sigla === "ARS" ||
                                          !retirada.converter) &&
                                        !retirada.nautt_user && (
                                          <Link
                                            to="#"
                                            className="text-warning"
                                            title={
                                              retirada.moeda?.sigla === "ARS"
                                                ? "Atualizar Coelsa ID"
                                                : "Atualizar TX Hash"
                                            }
                                            onClick={() => {
                                              toggleModalRecibo(retirada);
                                            }}
                                          >
                                            <FeatherIcon
                                              icon="upload"
                                              width="14"
                                            />
                                          </Link>
                                        )}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}

                  {!loading && !loadTable && retiradas?.length === 0 ? (
                    <Row>
                      <small className="text-muted text-center mt-5 mb-5">
                        Ningún retiro encontrado
                      </small>
                    </Row>
                  ) : (
                    <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
                      <div className="col-sm">
                        <div className="text-muted">
                          Mostrando{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              (paginaAtual - 1) * itensPorPagina + 1,
                              totalDeRetiros
                            )}
                          </span>{" "}
                          a{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              paginaAtual * itensPorPagina,
                              totalDeRetiros
                            )}
                          </span>{" "}
                          de{" "}
                          <span className="fw-semibold">{totalDeRetiros}</span>{" "}
                          resultados
                        </div>
                      </div>
                      <div className="col-sm-auto mt-3 mt-sm-0">
                        <Paginacao
                          total={totalDeRetiros}
                          currentPage={paginaAtual}
                          setCurrentPage={changePage}
                          perPageData={itensPorPagina}
                        />
                      </div>
                    </div>
                  )}
                  <ModalEditar
                    modal={modalEditar}
                    setModal={setModalEditar}
                    retirada={retirada}
                    setRefresh={setRefresh}
                    userDados={userData}
                  />
                  <ModalApagar
                    modal={modalApagar}
                    setModal={setModalApagar}
                    retirada={retirada}
                    setRefresh={setRefresh}
                  />
                  <ModalRecibo
                    modal={modalRecibo}
                    setModal={setModalRecibo}
                    retirada={retirada}
                    setRefresh={setRefresh}
                    userData={userData}
                  />
                  <ModalNotas
                    modal={modalNotas}
                    setModal={setModalNotas}
                    retirada={retirada}
                  />
                  <ModalNovo
                    modal={modalNovo}
                    setModal={setModalNovo}
                    setRefresh={setRefresh}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default Retiros;
