import React, { useState, useCallback, useEffect } from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  FormFeedback,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import { toast } from "react-toastify";
import { APIClient } from "../../../helpers/api_helper";
import InputMask from "react-input-mask";
import FeatherIcon from "feather-icons-react";

const apiClient = new APIClient();

const StepThree = ({ onComplete, data, countries }) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    acionistas: (!data.acionistas || data.acionistas.length === 0) ? [
      {
        nome_completo: "",
        pais_fiscal: "",
        documento_fiscal: "",
        email: "",
      },
    ] : data.acionistas,
    documentos: {
      docConstituicao: null,
      docEndereco: null,
    },
  });
  const [errors, setErrors] = useState({});

  const getDocumentMask = (countryCode) => {
    const selectedCountry = countries.find(
      (country) => country.country_code === countryCode
    );
    return selectedCountry?.tipo_documento?.mask_pf || "";
  };

  const getDocumentLabel = (countryCode) => {
    const selectedCountry = countries.find(
      (country) => country.country_code === countryCode
    );
    return selectedCountry?.tipo_documento?.doc_pf || "Documento Fiscal";
  };

  const handleShareholderChange = (index, field, value) => {
    setFormData((prev) => {
      const newAcionistas = [...prev.acionistas];
      newAcionistas[index] = {
        ...newAcionistas[index],
        [field]: value,
      };
      return {
        ...prev,
        acionistas: newAcionistas,
      };
    });
    // Clear error when user types
    if (errors[`acionistas.${index}.${field}`]) {
      setErrors((prev) => ({ ...prev, [`acionistas.${index}.${field}`]: "" }));
    }
  };

  const addNewShareholder = () => {
    setFormData((prev) => ({
      ...prev,
      acionistas: [
        ...prev.acionistas,
        {
          nome_completo: "",
          pais_fiscal: "",
          documento_fiscal: "",
          email: "",
        },
      ],
    }));
  };

  const removeShareholder = (index) => {
    if (formData.acionistas.length === 1) {
      toast.error("Es necesario tener al menos un accionista");
      return;
    }

    setFormData((prev) => ({
      ...prev,
      acionistas: prev.acionistas.filter((_, i) => i !== index),
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    formData.acionistas.forEach((acionista, index) => {
      if (!acionista.nome_completo) {
        newErrors[`acionistas.${index}.nome_completo`] = "El nombre completo es obligatorio";
      }
      if (!acionista.pais_fiscal) {
        newErrors[`acionistas.${index}.pais_fiscal`] = "El país es obligatorio";
      }
      if (!acionista.documento_fiscal) {
        newErrors[`acionistas.${index}.documento_fiscal`] = "El documento fiscal es obligatorio";
      }
      if (!acionista.email) {
        newErrors[`acionistas.${index}.email`] = "El email es obligatorio";
      } else if (!/\S+@\S+\.\S+/.test(acionista.email)) {
        newErrors[`acionistas.${index}.email`] = "El email no es válido";
      }
    });

    if (!formData.documentos.docConstituicao) {
      newErrors.docConstituicao = "El documento de constitución es obligatorio";
    }
    if (!formData.documentos.docEndereco) {
      newErrors.docEndereco = "El comprobante de domicilio es obligatorio";
    }

    return newErrors;
  };

  const handleDocumentChange = (field, file) => {
    if (file) {
      const extension = file.name.split(".").pop().toLowerCase();
      const allowedExtensions = ["pdf", "jpg", "jpeg", "png"];
      
      if (!allowedExtensions.includes(extension)) {
        toast.error("Formato de archivo no permitido. Use PDF, JPG, JPEG o PNG");
        return;
      }

      setFormData(prev => ({
        ...prev,
        documentos: {
          ...prev.documentos,
          [field]: file
        }
      }));

      // Clear error when user uploads a file
      if (errors[field]) {
        setErrors(prev => ({ ...prev, [field]: "" }));
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setLoading(true);

    try {
      const submitData = new FormData();
      submitData.append("uuid", data.uuid);
      
      // Append acionistas como array de objetos
      submitData.append("acionistas", JSON.stringify(formData.acionistas.map(acionista => ({
        ...acionista,
        documento_fiscal: acionista.documento_fiscal
      }))));
      
      // Append documents and their extensions
      if (formData.documentos.docConstituicao) {
        submitData.append("docConstituicao", formData.documentos.docConstituicao);
        submitData.append(
          "extensaoDocConstituicao",
          formData.documentos.docConstituicao.name.split(".").pop().toLowerCase()
        );
      }

      if (formData.documentos.docEndereco) {
        submitData.append("docEndereco", formData.documentos.docEndereco);
        submitData.append(
          "extensaoDocEndereco",
          formData.documentos.docEndereco.name.split(".").pop().toLowerCase()
        );
      }

      const response = await apiClient.put("/kyb/acionistas-documentos", submitData);
      if (response.sucesso) {
        onComplete(formData);
      } else {
        toast.error(response.mensagem || "Error al guardar los datos");
      }
    } catch (error) {
      console.error(error);
      toast.error("Error al guardar los datos");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="mb-4">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h5 className="mb-0">Accionistas</h5>
          <Button
            color="success"
            size="sm"
            type="button"
            onClick={addNewShareholder}
          >
            <FeatherIcon icon="plus" size={16} className="me-1" />
            Agregar Accionista
          </Button>
        </div>

        {formData.acionistas.map((acionista, index) => (
          <Card key={index} className="mb-3">
            <CardBody>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h6 className="mb-0">Accionista {index + 1}</h6>
                {formData.acionistas.length > 1 && (
                  <Button
                    color="danger"
                    size="sm"
                    type="button"
                    onClick={() => removeShareholder(index)}
                  >
                    <FeatherIcon icon="trash-2" size={16} />
                  </Button>
                )}
              </div>

              <Row className="mb-3">
                <Col md={6}>
                  <FormGroup>
                    <Label>Nombre Completo <span className="text-danger">*</span></Label>
                    <Input
                      type="text"
                      value={acionista.nome_completo}
                      onChange={(e) => handleShareholderChange(index, "nome_completo", e.target.value)}
                      invalid={!!errors[`acionistas.${index}.nome_completo`]}
                      maxLength={100}
                    />
                    <FormFeedback>{errors[`acionistas.${index}.nome_completo`]}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>Email <span className="text-danger">*</span></Label>
                    <Input
                      type="email"
                      value={acionista.email}
                      onChange={(e) => handleShareholderChange(index, "email", e.target.value)}
                      invalid={!!errors[`acionistas.${index}.email`]}
                    />
                    <FormFeedback>{errors[`acionistas.${index}.email`]}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label>País <span className="text-danger">*</span></Label>
                    <Input
                      type="select"
                      value={acionista.pais_fiscal}
                      onChange={(e) => handleShareholderChange(index, "pais_fiscal", e.target.value)}
                      invalid={!!errors[`acionistas.${index}.pais_fiscal`]}
                    >
                      <option value="">Seleccione un país</option>
                      {countries.map((country) => (
                        <option key={country.country_code} value={country.country_code}>
                          {country.pais}
                        </option>
                      ))}
                    </Input>
                    <FormFeedback>{errors[`acionistas.${index}.pais_fiscal`]}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>{getDocumentLabel(acionista.pais_fiscal)} <span className="text-danger">*</span></Label>
                    <InputMask
                      mask={getDocumentMask(acionista.pais_fiscal)}
                      value={acionista.documento_fiscal}
                      onChange={(e) => handleShareholderChange(index, "documento_fiscal", e.target.value)}
                    >
                      {(inputProps) => (
                        <Input
                          {...inputProps}
                          type="text"
                          invalid={!!errors[`acionistas.${index}.documento_fiscal`]}
                        />
                      )}
                    </InputMask>
                    <FormFeedback>{errors[`acionistas.${index}.documento_fiscal`]}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        ))}
      </div>

      <hr className="my-4" />

      <div className="mb-4">
        <h5 className="mb-3">Documentos</h5>
        <small className="text-muted d-block mb-3">
          El envío de documentos es obligatorio. Formatos aceptados: PDF, JPG, JPEG o PNG
        </small>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Documento de Constitución de la Empresa <span className="text-danger">*</span></Label>
              <Input
                type="file"
                accept=".pdf,.jpg,.jpeg,.png"
                onChange={(e) => handleDocumentChange("docConstituicao", e.target.files[0])}
                invalid={!!errors.docConstituicao}
              />
              <FormFeedback>{errors.docConstituicao}</FormFeedback>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Comprobante de Domicilio <span className="text-danger">*</span></Label>
              <Input
                type="file"
                accept=".pdf,.jpg,.jpeg,.png"
                onChange={(e) => handleDocumentChange("docEndereco", e.target.files[0])}
                invalid={!!errors.docEndereco}
              />
              <FormFeedback>{errors.docEndereco}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
      </div>

      <div className="mt-4">
        <Button
          color="success"
          className="w-100"
          type="submit"
          disabled={loading}
        >
          {loading ? <Spinner size="sm" /> : "Continuar"}
        </Button>
      </div>
    </Form>
  );
};

export default StepThree;
