import React from "react";
import { Row, Col, Label, FormGroup } from "reactstrap";
import { NumericFormat } from "react-number-format";

const RetiradasNautt = ({ 
  amount, 
  setAmount, 
  codAfiliado, 
  setCodAfiliado, 
  apiClient,
  userRole,
  codAfiliado1,
  setCodAfiliado1
}) => {
  return (
    <Row>
      {userRole === "admin" && (
        <Col md={6}>
          <FormGroup>
            <Label className="form-label">Código de Afiliado (Origen)</Label>
            <input
              type="text"
              className="form-control"
              value={codAfiliado1}
              onChange={(e) => setCodAfiliado1(e.target.value)}
              placeholder="Código de Afiliado"
            />
          </FormGroup>
        </Col>
      )}
      <Col md={6}>
        <FormGroup>
          <Label className="form-label">Código de Afiliado (Destino)</Label>
          <input
            type="text"
            className="form-control"
            value={codAfiliado}
            onChange={(e) => setCodAfiliado(e.target.value)}
            placeholder="Código de Afiliado"
          />
        </FormGroup>
      </Col>
      <Col md={userRole === "admin" ? 12 : 6}>
        <FormGroup>
          <Label className="form-label">Monto (USD)</Label>
          <div style={{ position: "relative" }}>
            <NumericFormat
              value={amount}
              onValueChange={(values) => setAmount(values.value)}
              className="form-control"
              placeholder="$ 0.00"
              allowNegative={false}
              decimalScale={2}
              decimalSeparator="."
              thousandSeparator=","
              prefix="$ "
            />
          </div>
        </FormGroup>
      </Col>
    </Row>
  );
};

export default RetiradasNautt;
