import React, { useEffect, useState, Fragment, useCallback } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
  Label,
  Input,
  Button,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import UiContent from "../../Components/Common/UiContent";
import { useParams } from "react-router-dom";
import InputMask from "react-input-mask";
import {
  checkLastLogin,
  formatEmail,
  nomeMask,
  trataUsename,
} from "../../Components/FunctionsRepository";
import { toast } from "react-toastify";
import UserConfigs from "./UserConfigs";

import { APIClient, getLoggedinUser } from "../../helpers/api_helper";

const apiClient = new APIClient();

const EditarUsuario = () => {
  document.title = "Editar Usuario | Nautt Finance";

  const { user_id } = useParams();

  const [loading, setLoading] = useState(false);
  const [nome, setNome] = useState("");
  const [chavePix, setChavePix] = useState("");
  const [login, setLogin] = useState("");
  const [email, setEmail] = useState("");
  const [statusUser, setStatusUser] = useState("");
  const [telefone, setTelefone] = useState("");
  const [paisTelefone, setPaisTelefone] = useState("BR");
  const [tipo, setTipo] = useState("lojista");
  const [funcoesSelecionadas, setFuncoesSelecionadas] = useState([]);
  const [optionsFuncoes, setOptionsFuncoes] = useState([]);
  const [codAfiliado, setCodAfiliado] = useState("");
  const [indicadoPor, setIndicadoPor] = useState("");

  const selecionarTodasFuncoes = () => {
    setFuncoesSelecionadas(optionsFuncoes);
  };

  const deselecionarTodasFuncoes = () => {
    setFuncoesSelecionadas([]);
  };

  const editar = async (user_id) => {
    setLoading(true);

    const funcoesIds = funcoesSelecionadas?.map((funcao) => funcao.value);

    const data = {
      user_id,
      nome: nomeMask(nome),
      username: login,
      email,
      telefone: telefone?.replace(/\D/g, ""),
      pais_telefone: paisTelefone,
      tipo,
      status: statusUser,
      chave_pix: chavePix,
      funcoes: funcoesIds,
      cod_afiliado: codAfiliado,
      indicado_por: indicadoPor,
    };

    const response = await apiClient.put("/usuarios", data);
    if (response.sucesso) {
      toast.success(`${response.mensagem}. Redirigiendo...`);
      setTimeout(() => {
        window.location.href = "/usuarios";
      }, 1000);
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  const fetchFuncoes = useCallback(async () => {
    const response = await apiClient.get("/funcoes");
    if (response.sucesso) {
      const dados = response.dados;
      setOptionsFuncoes(
        dados?.map((funcao) => ({
          label: `${funcao?.funcao}`,
          value: `${funcao?.id}`,
        }))
      );
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  }, []);

  const fetchUsuario = useCallback(async () => {
    const response = await apiClient.get("/usuarios", { user_id });

    if (response.sucesso) {
      const dados = response.dados;
      setNome(dados?.nome);
      setLogin(dados?.username);
      setEmail(dados?.email);
      setTelefone(dados?.telefone);
      setPaisTelefone(dados?.pais_telefone);
      setTipo(dados?.tipo);
      setStatusUser(dados?.status);
      setChavePix(dados?.chave_pix);
      setCodAfiliado(dados?.cod_afiliado);
      setIndicadoPor(dados?.indicado_por);
      setFuncoesSelecionadas(
        dados?.funcoes?.map((funcao) => ({
          label: funcao?.funcao,
          value: funcao?.id,
        }))
      );
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  }, [user_id]);

  const handleCheckboxChange = (funcao) => {
    if (funcoesSelecionadas.includes(funcao)) {
      setFuncoesSelecionadas(funcoesSelecionadas.filter((f) => f !== funcao));
    } else {
      setFuncoesSelecionadas([...funcoesSelecionadas, funcao]);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchFuncoes();
      await fetchUsuario();
    };

    fetchData();
    setLoading(false);
  }, [loading]);

  return (
    <Fragment>
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Editar Usuarios" pageTitle="Usuarios" />
          <Card>
            <CardBody>
              <div className="mt-4">
                <Row className="mt-3">
                  <Col md="6">
                    <div>
                      <Label htmlFor="nome" className="form-label">
                        Nombre <span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="nome"
                        className="form-control"
                        type="text"
                        placeholder="Nombre"
                        value={nome || ""}
                        onChange={(e) => setNome(e.target.value)}
                        maxLength={150}
                      />
                    </div>
                  </Col>

                  <Col md="6">
                    <div>
                      <Label htmlFor="email" className="form-label">
                        Correo electrónico
                      </Label>
                      <Input
                        id="email"
                        className="form-control"
                        type="email"
                        placeholder="Correo electrónico"
                        value={email || ""}
                        onChange={(e) => setEmail(formatEmail(e.target.value))}
                        maxLength={150}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="6">
                    <div>
                      <Label htmlFor="pais_telefone" className="form-label">
                        País del Teléfono
                      </Label>
                      <Input
                        type="select"
                        id="pais_telefone"
                        value={paisTelefone}
                        onChange={(e) => setPaisTelefone(e.target.value)}
                        className="form-control"
                      >
                        <option value="BR">Brasil (+55)</option>
                        <option value="AR">Argentina (+54)</option>
                      </Input>
                    </div>
                  </Col>

                  <Col md="6">
                    <div>
                      <Label htmlFor="telefone" className="form-label">
                        Teléfono
                      </Label>
                      <InputMask
                        mask={
                          paisTelefone === "BR"
                            ? "(99) 99999-9999"
                            : "999 999-9999"
                        }
                        placeholder={
                          paisTelefone === "BR"
                            ? "(99) 99999-9999"
                            : "999 999-9999"
                        }
                        value={telefone || ""}
                        onChange={(e) => setTelefone(e.target.value)}
                      >
                        {(inputProps) => (
                          <Input
                            type="text"
                            className="form-control"
                            {...inputProps}
                          />
                        )}
                      </InputMask>
                    </div>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col md="6">
                    <div>
                      <Label htmlFor="login" className="form-label">
                        Usuario
                      </Label>
                      <Input
                        id="login"
                        className="form-control"
                        type="text"
                        placeholder="Usuario"
                        value={login}
                        onChange={(e) => setLogin(trataUsename(e.target.value))}
                        maxLength={30}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <Label htmlFor="tipo" className="form-label">
                      Tipo
                    </Label>
                    <Input
                      type="select"
                      id="tipo"
                      value={tipo}
                      onChange={(e) => setTipo(e.target.value)}
                      className="form-control"
                    >
                      <option value="admin">Administrador</option>
                      <option value="lojista">Comerciante</option>
                    </Input>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col md="6">
                    <div>
                      <Label htmlFor="chave_pix" className="form-label">
                        Chave PIX
                      </Label>
                      <Input
                        id="chave_pix"
                        className="form-control"
                        type="text"
                        placeholder="Chave PIX"
                        value={chavePix || ""}
                        onChange={(e) => setChavePix(e.target.value)}
                      />
                    </div>
                  </Col>

                  <Col md="6">
                    <div>
                      <Label htmlFor="cod_afiliado" className="form-label">
                        Código de Afiliado
                      </Label>
                      <Input
                        id="cod_afiliado"
                        className="form-control"
                        type="text"
                        placeholder="Código de Afiliado"
                        value={codAfiliado || ""}
                        onChange={(e) => setCodAfiliado(e.target.value)}
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col md="6">
                    <div>
                      <Label htmlFor="indicado_por" className="form-label">
                        Indicado por
                      </Label>
                      <Input
                        id="indicado_por"
                        className="form-control"
                        type="text"
                        placeholder="Código de Afiliado del indicador"
                        value={indicadoPor || ""}
                        onChange={(e) => setIndicadoPor(e.target.value)}
                      />
                    </div>
                  </Col>

                  <Col md="6">
                    <Label htmlFor="statusUser" className="form-label">
                      Estado
                    </Label>
                    <Input
                      type="select"
                      id="statusUser"
                      value={statusUser}
                      onChange={(e) => setStatusUser(e.target.value)}
                      className="form-control"
                    >
                      <option value="ativo">Activo</option>
                      <option value="inativo">Inactivo</option>
                    </Input>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Label htmlFor="funcoes" className="form-label">
                    Funciones
                  </Label>

                  <Row className="mt-3">
                    <Col md="6">
                      <Button
                        color="primary"
                        className="btn btn-sm"
                        onClick={() => selecionarTodasFuncoes()}
                        disabled={loading}
                      >
                        Seleccionar todas
                      </Button>
                      <Button
                        color="secondary"
                        className="btn btn-sm ms-2"
                        onClick={() => deselecionarTodasFuncoes()}
                        disabled={loading}
                      >
                        Desmarcar todas
                      </Button>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    {optionsFuncoes?.map((funcao) => (
                      <Col md="3" key={funcao.value}>
                        <div className="form-check">
                          <Input
                            type="checkbox"
                            id={`funcao-${funcao.value}`}
                            checked={funcoesSelecionadas.includes(funcao)}
                            onChange={() => handleCheckboxChange(funcao)}
                            disabled={loading}
                          />
                          <Label
                            htmlFor={`funcao-${funcao.value}`}
                            className="form-check-label"
                          >
                            {funcao.label}
                          </Label>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Row>

                <div className="hstack gap-2 justify-content-end mt-5">
                  <button
                    className="btn btn-success"
                    onClick={() => editar(user_id)}
                    disabled={loading}
                  >
                    Editar
                  </button>
                </div>
              </div>
            </CardBody>
          </Card>

          <UserConfigs userId={user_id} />
        </Container>
      </div>
    </Fragment>
  );
};

export default EditarUsuario;
