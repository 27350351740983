import React, { useState, useCallback, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  Row,
  Col,
  FormFeedback,
} from "reactstrap";
import { toast } from "react-toastify";
import { APIClient } from "../../../helpers/api_helper";
import InputMask from "react-input-mask";
import UsdFields from "./UsdFields";
const apiClient = new APIClient();

const ModalNovaConta = ({ modal, setModal, userId, setRefresh }) => {
  const [loading, setLoading] = useState(false);
  const [moedas, setMoedas] = useState([]);
  const [bancos, setBancos] = useState([]);
  const [selectedMoeda, setSelectedMoeda] = useState("");
  const [formData, setFormData] = useState({
    nome: "",
    banco: "",
    agencia: "",
    conta: "",
    pix_alias: "",
    tipo_pix: "email",
    telefone: "",
    nome_destinatario: "",
    primeiro_sobrenome: "",
    data_nascimento: "",
    atividade: "",
    documento: "",
    pais: "",
    moeda: "",
    tipo: "fisica",
    email: "",
    endereco: "",
    provincia: "",
    cidade: "",
    codigo_postal: "",
    routing_number: "",
    iban: "",
    swift_bic: "",
  });
  const [errors, setErrors] = useState({});

  const fetchMoedas = useCallback(async () => {
    setLoading(true);
    const response = await apiClient.get("/moedas", { bancos: true });
    if (response.sucesso) {
      const dados = response.dados.filter(
        (moeda) => moeda.tipo_documento && moeda.bancos
      );
      setMoedas(dados);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
    setLoading(false);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "moeda") {
      setSelectedMoeda(value);
      const moedaSelecionada = moedas.find((m) => m.country_code === value);
      if (moedaSelecionada) {
        if (moedaSelecionada?.bancos) {
          setBancos(moedaSelecionada?.bancos);
        } else {
          setBancos([]);
        }
        setFormData((prev) => ({
          ...prev,
          pais: moedaSelecionada?.country_code,
          moeda: moedaSelecionada?.sigla,
        }));
      }
    } else if (name === "documento") {
      const mask = getDocumentoMask();
      if (mask === "#") {
        // Validar com regex para máscara "#"
        if (value === "" || /^[A-Za-z0-9]{1,15}$/.test(value)) {
          setFormData((prev) => ({
            ...prev,
            [name]: value,
          }));
        }
      } else {
        setFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    } else if (name === "telefone") {
      const mask = getTelefoneMask();
      if (mask === "#") {
        // Validar com regex para máscara "#"
        if (value === "" || /^[0-9]{1,15}$/.test(value)) {
          setFormData((prev) => ({
            ...prev,
            [name]: value,
          }));
        }
      } else {
        setFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    } else if (name === "codigo_postal") {
      const mask = getCepMask();
      if (mask === "#") {
        // Validar com regex para máscara "#"
        if (value === "" || /^[0-9]{1,15}$/.test(value)) {
          setFormData((prev) => ({
            ...prev,
            [name]: value.toUpperCase(),
          }));
        }
      } else {
        setFormData((prev) => ({
          ...prev,
          [name]: value.toUpperCase(),
        }));
      }
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    // Clear error when field is modified
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: undefined }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const requestData = {
      ...formData,
      user_id: userId,
    };

    // Only include tipo_pix if it's Brazil and has a value
    if (!isBrazil) {
      delete requestData.tipo_pix;
    }

    const response = await apiClient.post("/contas-cliente", requestData);

    if (response.sucesso) {
      toast.success("Cuenta bancaria registrada con éxito");
      setRefresh(true);
      setModal(false);
      setFormData({
        nome: "",
        banco: "",
        agencia: "",
        conta: "",
        pix_alias: "",
        tipo_pix: "",
        telefone: "",
        nome_destinatario: "",
        primeiro_sobrenome: "",
        data_nascimento: "",
        atividade: "",
        documento: "",
        pais: "",
        moeda: "",
        tipo: "fisica",
        email: "",
        endereco: "",
        provincia: "",
        cidade: "",
        codigo_postal: "",
        routing_number: "",
        iban: "",
        swift_bic: "",
      });
      setSelectedMoeda("");
      setBancos([]);
    } else {
      toast.error(response.mensagem || "Error al registrar la cuenta bancaria");
    }

    setLoading(false);
  };

  const isBrazil = selectedMoeda === "BRA";
  const isArgentina = selectedMoeda === "ARG";
  const isPixProvided = formData.pix_alias?.trim().length > 0;
  const isPessoaFisica = formData.tipo === "fisica";

  const getDocumentoLabel = () => {
    const moedaSelecionada = moedas.find(
      (m) => m.country_code === selectedMoeda
    );
    if (!moedaSelecionada || !moedaSelecionada?.tipo_documento)
      return "Documento";
    return isPessoaFisica
      ? moedaSelecionada?.tipo_documento.doc_pf
      : moedaSelecionada?.tipo_documento.doc_pj;
  };

  const getDocumentoMask = () => {
    const moedaSelecionada = moedas.find(
      (m) => m.country_code === selectedMoeda
    );
    if (!moedaSelecionada || !moedaSelecionada?.tipo_documento) return "";
    return isPessoaFisica
      ? moedaSelecionada?.tipo_documento.mask_pf
      : moedaSelecionada?.tipo_documento.mask_pj;
  };

  const getDocumentoPlaceholder = () => {
    const mask = getDocumentoMask();
    return mask.replace(/9/g, "0");
  };

  const getTelefoneMask = () => {
    const moedaSelecionada = moedas.find(
      (m) => m.country_code === selectedMoeda
    );
    if (!moedaSelecionada || !moedaSelecionada?.tipo_documento) return "";
    return moedaSelecionada?.tipo_documento.mask_tel;
  };

  const getTelefonePlaceholder = () => {
    const mask = getTelefoneMask();
    return mask ? mask.replace(/9/g, "0") : "";
  };

  const getCepMask = () => {
    const moedaSelecionada = moedas.find(
      (m) => m.country_code === selectedMoeda
    );
    if (!moedaSelecionada || !moedaSelecionada?.tipo_documento) return "";
    return moedaSelecionada?.tipo_documento.mask_cep;
  };

  const getCepPlaceholder = () => {
    const mask = getCepMask();
    return mask ? mask.replace(/9/g, "0") : "";
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchMoedas();
    };
    if (modal) {
      fetchData();
    }
  }, [modal]);

  return (
    <Modal isOpen={modal} toggle={() => setModal(false)} centered size="lg">
      <ModalHeader toggle={() => setModal(false)}>
        Nueva Cuenta Bancaria
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label for="moeda">Moneda</Label>
                <Input
                  type="select"
                  name="moeda"
                  id="moeda"
                  value={selectedMoeda}
                  onChange={handleInputChange}
                >
                  <option value="">Seleccionar moneda</option>
                  {moedas.map((moeda) => (
                    <option key={moeda.country_code} value={moeda.country_code}>
                      {moeda.nome} ({moeda.sigla})
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="tipo">Tipo de Persona</Label>
                <Input
                  type="select"
                  name="tipo"
                  id="tipo"
                  value={formData.tipo}
                  onChange={handleInputChange}
                >
                  <option value="fisica">Persona Física</option>
                  <option value="juridica">Persona Jurídica</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="documento">{getDocumentoLabel()}</Label>
                {getDocumentoMask() === "#" ? (
                  <Input
                    type="text"
                    name="documento"
                    id="documento"
                    value={formData.documento}
                    onChange={handleInputChange}
                    className={`form-control ${
                      errors.documento ? "is-invalid" : ""
                    }`}
                    maxLength={15}
                  />
                ) : (
                  <InputMask
                    mask={getDocumentoMask()}
                    formatChars={{
                      9: "[0-9]",
                      0: "[0-9]",
                      "?": "[0-9A-Za-z]",
                    }}
                    value={formData.documento}
                    onChange={handleInputChange}
                    className={`form-control ${
                      errors.documento ? "is-invalid" : ""
                    }`}
                    placeholder={getDocumentoPlaceholder()}
                  >
                    {(inputProps) => (
                      <Input
                        {...inputProps}
                        type="text"
                        name="documento"
                        id="documento"
                      />
                    )}
                  </InputMask>
                )}
                {errors.documento && (
                  <FormFeedback>{errors.documento}</FormFeedback>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <FormGroup>
                <Label for="nome">
                  Nombre de la Cuenta en Nautt (no es el nombre de la persona)
                </Label>
                <Input
                  type="text"
                  name="nome"
                  id="nome"
                  placeholder="Nombre de la cuenta"
                  value={formData.nome}
                  onChange={handleInputChange}
                  required
                />
              </FormGroup>
            </Col>
          </Row>

          {!isBrazil && !isArgentina && (
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email del destinatario"
                    value={formData.email}
                    onChange={handleInputChange}
                    required={!isBrazil}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="telefone">Teléfono</Label>
                  {getTelefoneMask() === "#" ? (
                    <Input
                      type="text"
                      name="telefone"
                      id="telefone"
                      value={formData.telefone}
                      onChange={handleInputChange}
                      className={`form-control ${
                        errors.telefone ? "is-invalid" : ""
                      }`}
                      maxLength={15}
                      required
                    />
                  ) : (
                    <InputMask
                      mask={getTelefoneMask()}
                      value={formData.telefone}
                      onChange={handleInputChange}
                      className={`form-control ${
                        errors.telefone ? "is-invalid" : ""
                      }`}
                      placeholder={getTelefonePlaceholder()}
                    >
                      {(inputProps) => (
                        <Input
                          {...inputProps}
                          type="text"
                          name="telefone"
                          id="telefone"
                          required
                        />
                      )}
                    </InputMask>
                  )}
                  {errors.telefone && (
                    <FormFeedback>{errors.telefone}</FormFeedback>
                  )}
                </FormGroup>
              </Col>
            </Row>
          )}

          <Row>
            <Col
              md={
                formData.tipo === "fisica" && !isBrazil && !isArgentina ? 6 : 12
              }
              className="mt-3"
            >
              <FormGroup>
                <Label for="nome_destinatario">Nombre del Destinatario</Label>
                <Input
                  type="text"
                  name="nome_destinatario"
                  id="nome_destinatario"
                  placeholder="Nombre del destinatario"
                  value={formData.nome_destinatario}
                  onChange={handleInputChange}
                  required
                />
              </FormGroup>
            </Col>
            {formData.tipo === "fisica" && !isBrazil && !isArgentina && (
              <Col md={6} className="mt-3">
                <FormGroup>
                  <Label for="primeiro_sobrenome">Primer Apellido</Label>
                  <Input
                    type="text"
                    name="primeiro_sobrenome"
                    id="primeiro_sobrenome"
                    placeholder="Primer apellido"
                    value={formData.primeiro_sobrenome}
                    onChange={handleInputChange}
                    required
                  />
                </FormGroup>
              </Col>
            )}
            {formData.tipo === "fisica" && !isBrazil && !isArgentina && (
              <Col md={6} className="mt-3">
                <FormGroup>
                  <Label for="data_nascimento">Fecha de Nacimiento</Label>
                  <Input
                    type="date"
                    name="data_nascimento"
                    id="data_nascimento"
                    value={formData.data_nascimento}
                    onChange={handleInputChange}
                    required={!isBrazil && !isArgentina}
                  />
                </FormGroup>
              </Col>
            )}
            {!isBrazil && !isArgentina && (
              <Col md={formData.tipo === "fisica" ? 6 : 12} className="mt-3">
                <FormGroup>
                  <Label for="atividade">Actividad</Label>
                  <Input
                    type="text"
                    name="atividade"
                    id="atividade"
                    placeholder="Actividad"
                    value={formData.atividade}
                    onChange={handleInputChange}
                    required
                  />
                </FormGroup>
              </Col>
            )}
          </Row>

          {!isBrazil && (
            <>
              <Row className="mt-3">
                <Col md={12}>
                  <h5>Dirección</h5>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="endereco">Dirección</Label>
                    <Input
                      type="text"
                      name="endereco"
                      id="endereco"
                      placeholder="Dirección completa"
                      value={formData.endereco}
                      onChange={handleInputChange}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="bairro">Localidad</Label>
                    <Input
                      type="text"
                      name="bairro"
                      id="bairro"
                      placeholder="Localidad"
                      value={formData.bairro}
                      onChange={handleInputChange}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <FormGroup>
                    <Label for="provincia">Provincia</Label>
                    <Input
                      type="text"
                      name="provincia"
                      id="provincia"
                      placeholder="Provincia"
                      value={formData.provincia}
                      onChange={handleInputChange}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="cidade">Ciudad</Label>
                    <Input
                      type="text"
                      name="cidade"
                      id="cidade"
                      placeholder="Ciudad"
                      value={formData.cidade}
                      onChange={handleInputChange}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="codigo_postal">Código Postal</Label>
                    {getCepMask() === "#" ? (
                      <Input
                        type="text"
                        name="codigo_postal"
                        id="codigo_postal"
                        value={formData.codigo_postal}
                        onChange={handleInputChange}
                        className={`form-control ${
                          errors.codigo_postal ? "is-invalid" : ""
                        }`}
                        maxLength={15}
                        required
                      />
                    ) : (
                      <InputMask
                        mask={getCepMask()}
                        value={formData.codigo_postal}
                        onChange={handleInputChange}
                        className={`form-control ${
                          errors.codigo_postal ? "is-invalid" : ""
                        }`}
                        placeholder={getCepPlaceholder()}
                      >
                        {(inputProps) => (
                          <Input
                            {...inputProps}
                            type="text"
                            name="codigo_postal"
                            id="codigo_postal"
                            required
                          />
                        )}
                      </InputMask>
                    )}
                    {errors.codigo_postal && (
                      <FormFeedback>{errors.codigo_postal}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </>
          )}

          <Row className="mt-3">
            <Col md={12}>
              <h5>Datos Bancarios</h5>
            </Col>
            {selectedMoeda === "BRA" || selectedMoeda === "ARG" ? (
              <>
                {isBrazil && (
                  <Col md={4}>
                    <FormGroup>
                      <Label for="tipo_pix">Tipo de PIX</Label>
                      <Input
                        type="select"
                        name="tipo_pix"
                        id="tipo_pix"
                        value={formData.tipo_pix}
                        onChange={handleInputChange}
                      >
                        <option value="cpf">CPF</option>
                        <option value="cnpj">CNPJ</option>
                        <option value="telefone">Teléfono</option>
                        <option value="email">Email</option>
                        <option value="aleatoria">Aleatoria</option>
                      </Input>
                    </FormGroup>
                  </Col>
                )}
                <Col md={isBrazil ? 4 : 6}>
                  <FormGroup>
                    <Label for="pix_alias">
                      {isBrazil ? "Chave PIX" : "CBU / CVU / ALIAS"}
                    </Label>
                    {isBrazil && formData.tipo_pix === "cpf" ? (
                      <InputMask
                        mask="999.999.999-99"
                        value={formData.pix_alias}
                        onChange={handleInputChange}
                        className="form-control"
                      >
                        {(inputProps) => (
                          <Input
                            {...inputProps}
                            type="text"
                            name="pix_alias"
                            id="pix_alias"
                            placeholder="Digite o CPF"
                          />
                        )}
                      </InputMask>
                    ) : isBrazil && formData.tipo_pix === "cnpj" ? (
                      <InputMask
                        mask="99.999.999/9999-99"
                        value={formData.pix_alias}
                        onChange={handleInputChange}
                        className="form-control"
                      >
                        {(inputProps) => (
                          <Input
                            {...inputProps}
                            type="text"
                            name="pix_alias"
                            id="pix_alias"
                            placeholder="Digite o CNPJ"
                          />
                        )}
                      </InputMask>
                    ) : isBrazil && formData.tipo_pix === "telefone" ? (
                      <InputMask
                        mask="(99) 99999-9999"
                        value={formData.pix_alias}
                        onChange={handleInputChange}
                        className="form-control"
                      >
                        {(inputProps) => (
                          <Input
                            {...inputProps}
                            type="text"
                            name="pix_alias"
                            id="pix_alias"
                            placeholder="Digite o telefone"
                          />
                        )}
                      </InputMask>
                    ) : (
                      <Input
                        type="text"
                        name="pix_alias"
                        id="pix_alias"
                        placeholder={
                          isBrazil
                            ? "Ingrese la clave PIX"
                            : "Ingrese CBU/CVU/ALIAS"
                        }
                        value={formData.pix_alias}
                        onChange={handleInputChange}
                        maxLength={100}
                      />
                    )}
                  </FormGroup>
                </Col>
                <Col md={isBrazil ? 4 : 6}>
                  <FormGroup>
                    <Label for="banco">Banco</Label>
                    {bancos.length === 0 ? (
                      <Input
                        type="text"
                        name="banco"
                        id="banco"
                        value={formData.banco}
                        onChange={handleInputChange}
                        maxLength={100}
                        placeholder="Nombre del banco"
                        required
                      />
                    ) : (
                      <Input
                        type="select"
                        name="banco"
                        id="banco"
                        value={formData.banco}
                        onChange={handleInputChange}
                        disabled={!selectedMoeda}
                      >
                        <option value="">Seleccionar banco</option>
                        {bancos.map((banco) => (
                          <option key={banco.id} value={banco.bankCode}>
                            {banco.bankCode === ""
                              ? banco.name
                              : `${banco.bankCode} - ${banco.name}`}
                          </option>
                        ))}
                      </Input>
                    )}
                  </FormGroup>
                </Col>
              </>
            ) : (
              <>
                <Col md={6}>
                  <FormGroup>
                    <Label for="conta">Cuenta</Label>
                    <Input
                      type="text"
                      name="conta"
                      id="conta"
                      value={formData.conta}
                      onChange={handleInputChange}
                      placeholder="Número de cuenta"
                      maxLength={30}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="banco">Banco</Label>
                    {bancos.length === 0 ? (
                      <Input
                        type="text"
                        name="banco"
                        id="banco"
                        value={formData.banco}
                        onChange={handleInputChange}
                        maxLength={100}
                        placeholder="Nombre del banco"
                        required
                      />
                    ) : (
                      <Input
                        type="select"
                        name="banco"
                        id="banco"
                        value={formData.banco}
                        onChange={handleInputChange}
                        disabled={!selectedMoeda}
                      >
                        <option value="">Seleccionar banco</option>
                        {bancos.map((banco) => (
                          <option key={banco.id} value={banco.bankCode}>
                            {banco.bankCode === ""
                              ? banco.name
                              : `${banco.bankCode} - ${banco.name}`}
                          </option>
                        ))}
                      </Input>
                    )}
                  </FormGroup>
                </Col>
              </>
            )}
          </Row>

          {!isArgentina && !isBrazil && (
            <>
              <Row className="mt-3">
                <Col md={6}>
                  <FormGroup>
                    <Label for="agencia">Agencia</Label>
                    <Input
                      type="text"
                      name="agencia"
                      id="agencia"
                      placeholder="Número de agencia"
                      value={formData.agencia}
                      onChange={handleInputChange}
                      required={!isPixProvided}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="conta">Cuenta</Label>
                    <Input
                      type="text"
                      name="conta"
                      id="conta"
                      placeholder="Número de cuenta"
                      value={formData.conta}
                      onChange={handleInputChange}
                      required={!isPixProvided}
                      maxLength={30}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </>
          )}

          {/* Campos específicos para USD */}
          {formData.moeda === "USD" && (
            <UsdFields
              formData={formData}
              handleInputChange={handleInputChange}
              errors={errors}
            />
          )}

          <div className="text-end mt-4">
            <Button
              type="button"
              color="light"
              onClick={() => setModal(false)}
              className="me-2"
            >
              Cancelar
            </Button>
            <Button type="submit" color="primary" disabled={loading}>
              {loading ? <Spinner size="sm" /> : "Registrar"}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ModalNovaConta;
