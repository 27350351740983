import React from "react";
import { Alert } from "reactstrap";

const FormLatam = ({ moeda }) => {
  const getCurrencyName = () => {
    switch (moeda) {
      case "CLP":
        return "Pesos Chilenos";
      case "MXN":
        return "Pesos Mexicanos";
      case "PEN":
        return "Soles Peruanos";
      default:
        return "moneda local";
    }
  };

  return (
    <Alert color="info" className="text-center">
      ¡Pronto podrás hacer depósitos en {getCurrencyName()}! Estamos trabajando en ello.
    </Alert>
  );
};

export default FormLatam;
