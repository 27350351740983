import React from "react";
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";
import { toast } from "react-toastify";
import { APIClient } from "../../helpers/api_helper";

const apiClient = new APIClient();

const ModalConfirmarNota = ({ modal, setModal, ordem, onSuccess }) => {
  const toggle = () => {
    setModal(!modal);
  };

  const handleConfirm = async () => {
    try {
      const response = await apiClient.put("/ordens-pix/marcar-nota-emitida", {
        ordem_id: ordem?.id
      });

      if (response.sucesso) {
        toast.success("Nota fiscal marcada como emitida con éxito");
        onSuccess();
        toggle();
      } else {
        toast.error(response.mensagem || "Error al marcar la nota fiscal");
      }
    } catch (error) {
      toast.error("Error al marcar la nota fiscal");
    }
  };

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Confirmar Acción
      </ModalHeader>
      <ModalBody className="text-center p-4">
        <p>¿Está seguro que desea marcar esta nota fiscal como emitida?</p>
        <div className="hstack gap-2 justify-content-center mt-4">
          <Button color="light" onClick={() => toggle()}>
            Cancelar
          </Button>
          <Button color="success" onClick={handleConfirm}>
            Confirmar
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalConfirmarNota;
