import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin, converterData } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalEditar = ({ modal, setModal, setRefresh, verificacao }) => {
  const [loading, setLoading] = useState(false);
  const [statusVerificacao, setStatusVerificacao] = useState("");

  const toggle = () => {
    setStatusVerificacao("");
    setModal(!modal);
  };

  const baixarDocumento = async (verificacao, documento) => { 
    setLoading(true);
    console.log(verificacao);

    const data = {
      uuid: verificacao.kyb_code,
      paisFiscal: verificacao.pais_fiscal,
      documentoFiscal: verificacao.documento_fiscal,
      documento,
    };

    try {
      const response = await apiClient.postDownload(`/kyb/baixar-arquivo`, data);
      if (response.sucesso) {
        const contentDisposition = response.headers['content-disposition'];
        let filename = `${documento}`;
        let extension = '';

        if (contentDisposition) {
          const filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
          if (filenameMatch && filenameMatch[1]) {
            filename = filenameMatch[1].replace(/['"]/g, '');
            const extensionMatch = filename.match(/\.[0-9a-z]+$/i);
            if (extensionMatch) {
              extension = extensionMatch[0];
            }
          }
        }

        if (!extension) {
          const contentType = response.headers['content-type'];
          if (contentType) {
            if (contentType.includes('pdf')) {
              extension = '.pdf';
            } else if (contentType.includes('jpeg') || contentType.includes('jpg')) {
              extension = '.jpg';
            } else if (contentType.includes('png')) {
              extension = '.png';
            }
          }
        }

        if (!extension) {
          extension = '.pdf';
        }

        const blob = new Blob([response.dados], { type: response.headers['content-type'] });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${filename}${extension}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
        toast.success("Documento baixado com sucesso");
      } else {
        toast.error(response.mensagem || "Erro ao baixar o documento");
      }
    } catch (error) {
      console.error("Erro ao baixar documento:", error);
      toast.error("Erro ao baixar o documento");
    } finally {
      setLoading(false);
    }
  };

  const editar = async (verificacao) => {
    if (verificacao?.status !== 'pendente'){
      toast.error("La verificación ya fue procesada");
      return
    }
    
    setLoading(true);

    const data = {
      status: statusVerificacao,
      verificacao_id: verificacao.id,
    };

    const response = await apiClient.put(`/kyb-status`, data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);

      toggle();
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  useEffect(() => {
    if (verificacao) {
      setStatusVerificacao(verificacao.status || "");
    }
  }, [verificacao]);

  const isPending = verificacao?.status === 'pendente';

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        {!isPending ? "Ver Verificación" : "Editar Verificación"} #{verificacao?.id}
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="6">
              <Label for="nome_legal">Nombre Legal</Label>
              <Input
                type="text"
                name="nome_legal"
                id="nome_legal"
                value={verificacao?.nome_legal || ""}
                readOnly
              />
            </Col>
            <Col md="6">
              <Label for="nome_fantasia">Nombre Comercial</Label>
              <Input
                type="text"
                name="nome_fantasia"
                id="nome_fantasia"
                value={verificacao?.nome_fantasia || ""}
                readOnly
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <Label for="data_constituicao">Fecha de Constitución</Label>
              <Input
                type="text"
                name="data_constituicao"
                id="data_constituicao"
                value={converterData(verificacao?.data_constituicao, false) || ""}
                readOnly
              />
            </Col>
            <Col md="6">
              <Label for="email">Email</Label>
              <Input
                type="email"
                name="email"
                id="email"
                value={verificacao?.email || ""}
                readOnly
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <Label for="pais_fiscal">País Fiscal</Label>
              <Input
                type="text"
                name="pais_fiscal"
                id="pais_fiscal"
                value={verificacao?.pais_fiscal || ""}
                readOnly
              />
            </Col>
            <Col md="6">
              <Label for="documento_fiscal">Documento Fiscal</Label>
              <Input
                type="text"
                name="documento_fiscal"
                id="documento_fiscal"
                value={verificacao?.documento_fiscal || ""}
                readOnly
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <Label for="telefone">Teléfono</Label>
              <Input
                type="text"
                name="telefone"
                id="telefone"
                value={verificacao?.telefone || ""}
                readOnly
              />
            </Col>
            <Col md="6">
              <Label for="atividade">Actividad</Label>
              <Input
                type="text"
                name="atividade"
                id="atividade"
                value={verificacao?.atividade || ""}
                readOnly
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="12">
              <h4>Accionistas</h4>
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>Nombre Completo</th>
                    <th>País Fiscal</th>
                    <th>Documento Fiscal</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  {verificacao?.acionistas?.map((acionista, index) => (
                    <tr key={index}>
                      <td>{acionista["nome_completo"]}</td>
                      <td>{acionista["pais_fiscal"]}</td>
                      <td>{acionista["documento_fiscal"]}</td>
                      <td>{acionista.email}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>
          </Row>
          <Row className="mt-3">
            <h4 className="mt-4">Descargar Documentos</h4>
            <Col md="4">
              <Button
                color="primary"
                onClick={() => baixarDocumento(verificacao, "docConstituicao")}
                disabled={loading}
              >
                Documento de Constitución
              </Button>
            </Col>
            <Col md="4"></Col>
            <Col md="4">
              <Button
                color="primary"
                onClick={() => baixarDocumento(verificacao, "docEndereco")}
                disabled={loading}
              >
                Documento de Dirección
              </Button>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="12">
              <Label for="status-verificacao">Estado</Label>
              <Input
                type="select"
                name="status-verificacao"
                id="status-verificacao"
                value={statusVerificacao}
                onChange={(e) => setStatusVerificacao(e.target.value)}
                disabled={!isPending}
              >
                <option value="pendente">Pendiente</option>
                <option value="verificado">Verificado</option>
                <option value="rejeitado">Rechazado</option>
              </Input>
            </Col>
          </Row>

          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-success fw-medium"
              onClick={() => toggle()}
              disabled={loading}
            >
              <i className="ri-close-line me-1 align-middle"></i> Cerrar
            </Link>
            {isPending && (
              <button
                className="btn btn-success"
                onClick={() => editar(verificacao)}
                disabled={loading}
              >
                Editar Estado
              </button>
            )}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalEditar;
