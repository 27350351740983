import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { APIClient, getLoggedinUser } from "../../helpers/api_helper";
import { toast } from "react-toastify";

const apiClient = new APIClient();

const ModalRelatorio = ({ isOpen, toggle }) => {
  const [loading, setLoading] = useState(false);
  const [userDados, setUserDados] = useState(null);
  const [activeTab, setActiveTab] = useState("ordens");
  const [downloadDates, setDownloadDates] = useState({
    data_inicio: new Date().toISOString().split("T")[0],
    data_fim: new Date().toISOString().split("T")[0],
  });

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setDownloadDates((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDownload = async () => {
    setLoading(true);
    try {
      let endpoint =
        activeTab === "ordens" ? "/relatorios/ordens" : "/relatorios/retiradas";
      if (userDados?.role === "admin") {
        endpoint =
          activeTab === "ordens"
            ? "/relatorios/admin/ordens-pix"
            : "/relatorios/admin/retiradas";
      }

      const response = await apiClient.postDownload(endpoint, downloadDates);
      if (response.sucesso) {
        const blob = new Blob([response.dados], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `ordenes_${downloadDates.data_inicio}_${downloadDates.data_fim}.csv`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        toggle();
        toast.success("Informe descargado con éxito");
      } else {
        toast.error(
          "No se pudo encontrar el ordenes para las fechas seleccionadas"
        );
      }
    } catch (error) {
      toast.error("Error al descargar el informe");
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchUser = async () => {
      const user = await getLoggedinUser();
      setUserDados(user);
    };
    fetchUser();
  }, []);

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Generar Informe de Órdenes</ModalHeader>
      <ModalBody>
        <Nav tabs className="mb-3">
          <NavItem>
            <NavLink
              className={activeTab === "ordens" ? "active" : ""}
              onClick={() => setActiveTab("ordens")}
              style={{ cursor: "pointer" }}
            >
              Ordens
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "retiradas" ? "active" : ""}
              onClick={() => setActiveTab("retiradas")}
              style={{ cursor: "pointer" }}
            >
              Retiradas
            </NavLink>
          </NavItem>
        </Nav>
        <Form>
          <FormGroup>
            <Label for="data_inicio">Fecha inicial</Label>
            <Input
              type="date"
              name="data_inicio"
              id="data_inicio"
              value={downloadDates.data_inicio}
              onChange={handleDateChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="data_fim">Fecha final</Label>
            <Input
              type="date"
              name="data_fim"
              id="data_fim"
              value={downloadDates.data_fim}
              onChange={handleDateChange}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleDownload} disabled={loading}>
          {loading ? "Descargando..." : "Descargar"}
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalRelatorio;
