import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  CardHeader,
  Button,
  CardText,
  Spinner,
  UncontrolledAlert,
  Alert,
  Input,
} from "reactstrap";
import FeatherIcon from "feather-icons-react";
import { toast } from "react-toastify";
import { APIClient } from "../../../helpers/api_helper";

const apiClient = new APIClient();

const UsdPagamento = ({
  loading,
  setLoading,
  ordem,
  resumoCalculo,
  formatCurrency,
  linkData,
  setOrdem,
}) => {
  const [comprovante, setComprovante] = useState(null);
  const [loadingComponent, setLoadingComponent] = useState(false);
  const [bankData, setBankData] = useState(null);
  const handleGerarOrdem = async () => {
    setLoading(true);

    const data = {
      link_pagamento: linkData.link,
      user_id: linkData.user_id,
      moeda_final: "USDT",
      pdv_id: linkData.pdv_id === 0 ? null : linkData.pdv_id,
      moeda: "USD",
      expiracao: 30 * 60,
      total: resumoCalculo.valor_final,
    };

    const response = await apiClient.post("/ordem-nautt", data);
    if (response.sucesso) {
      toast.success("Orden generada con éxito");
      setOrdem(response.dados);

      // Formatear datos bancarios
      const bankDataString = [
        `Banco: ${response.dados.banco.banco}`,
        `Nombre: ${response.dados.banco.nome}`,
        `Account Number: ${response.dados.banco.conta_cvu}`,
        `Routing Number: ${response.dados.banco.aba_alias}`,
      ].join("\n");
      setBankData(bankDataString);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    setLoading(false);
  };

  const handleCopyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success("¡Copiado con éxito!");
    } catch (error) {
      toast.error("Error al copiar el texto");
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileType = selectedFile.type;
      const validTypes = [
        "application/pdf",
        "image/png",
        "image/jpeg",
        "image/jpg",
      ];
      if (validTypes.includes(fileType)) {
        setComprovante(selectedFile);
      } else {
        toast.error("Por favor, seleccione un archivo PDF, PNG o JPG/JPEG");
        e.target.value = null;
      }
    }
  };

  const enviarComprovante = async (ordem) => {
    setLoadingComponent(true);

    try {
      if (!comprovante) {
        toast.error("Por favor, seleccione un archivo de comprobante.");
        setLoadingComponent(false);
        return;
      }

      const formData = new FormData();
      formData.append("comprovante", comprovante);
      formData.append("ordem_id", ordem.id);

      const extensaoComprovante = comprovante.name.split(".").pop();
      formData.append("extensaoComprovante", extensaoComprovante);
      formData.append("uuid", ordem.uuid);

      const response = await apiClient.post(
        "/ordens-nautt/comprovante",
        formData
      );

      if (response.sucesso) {
        toast.success(
          "¡Comprobante enviado con éxito! Procesaremos su depósito en breve."
        );
        let oldOrdem = ordem;
        oldOrdem.status = "processando";
        setOrdem(oldOrdem);
      } else {
        toast.error(response.mensagem || "Error al subir el comprobante");
      }
    } catch (error) {
      console.error("Error al enviar comprobante:", error);
      toast.error("Ocurrió un error al enviar el comprobante");
    } finally {
      setLoadingComponent(false);
    }
  };

  const renderPaymentSection = () => {
    if (ordem && ordem.status !== "novo") {
      return (
        <CardBody>
          <div
            className={`rounded-circle p-3 mb-2 ${
              ordem.status === "pago" || ordem.status === "finalizada"
                ? "bg-success"
                : ordem.status === "processando" ||
                  ordem.status === "pago" ||
                  ordem.status === "enviado"
                ? "bg-warning"
                : "bg-danger"
            }`}
            style={{ width: "100px", height: "100px", margin: "0 auto" }}
          >
            <FeatherIcon
              icon={
                ordem.status === "pago" ||
                ordem.status === "finalizada" ||
                ordem.status === "processando" ||
                ordem.status === "pago" ||
                ordem.status === "enviado"
                  ? "check-circle"
                  : "x-circle"
              }
              className="text-white"
              size={70}
            />
          </div>
          <CardText
            tag="h4"
            className={`font-weight-bold mt-4 text-${
              ordem.status === "pago" || ordem.status === "finalizada"
                ? "success"
                : ordem.status === "processando" ||
                  ordem.status === "pago" ||
                  ordem.status === "enviado"
                ? "warning"
                : "danger"
            }`}
          >
            {ordem.status === "pago"
              ? "Pago Recibido"
              : ordem.status === "finalizada"
              ? "Orden Finalizada"
              : ordem.status === "processando" ||
                ordem.status === "pago" ||
                ordem.status === "enviado"
              ? "Orden en Proceso"
              : "Orden Inválida"}
          </CardText>
          <CardText>
            {ordem.status === "pago"
              ? "El pago se ha procesado con éxito."
              : ordem.status === "finalizada"
              ? "La orden ha sido finalizada exitosamente."
              : ordem.status === "processando" ||
                ordem.status === "pago" ||
                ordem.status === "enviado"
              ? "La orden se encuentra en proceso. Aguarde hasta que el pago se procese."
              : "La orden se consideró inválida. Por favor, inténtelo de nuevo."}
          </CardText>
        </CardBody>
      );
    }

    if (ordem) {
      const bankDataString = bankData
        ? bankData
        : [
            `Banco: ${ordem?.moeda?.nautt_contas[0].banco}`,
            `Nombre: ${ordem?.moeda?.nautt_contas[0].nome}`,
            `Account Number: ${ordem?.moeda?.nautt_contas[0].conta_cvu}`,
            `Routing Number: ${ordem?.moeda?.nautt_contas[0].aba_alias}`,
          ].join("\n");
      return ordem.status === "processando" ? (
        <CardBody>
          <div className="text-center">
            <Alert color="success" className="mt-3">
              <div className="text-center">
                El comprobante se ha subido con éxito. Por favor, espere a que
                el pago se procese.
              </div>
            </Alert>
          </div>
        </CardBody>
      ) : (
        <CardBody>
          <div className="text-center">
            <p>
              Por favor, asegúrese de transferir el monto exacto a la cuenta
              indicada. Una vez realizada la transferencia, el procesamiento del
              pago se reflejará en su panel. Este proceso puede tomar entre 1 y
              24 horas hábiles.
            </p>
            <Alert color="success" className="m-1">
              <div className="text-left">
                {bankDataString.split("\n").map((line, index) => (
                  <div
                    key={index}
                    className="d-flex align-items-center justify-content-start mt-2"
                  >
                    <div className="me-2">{line}</div>
                    <Button
                      color="link"
                      className="p-0"
                      onClick={() => handleCopyToClipboard(line)}
                    >
                      <FeatherIcon icon="copy" size={16} />
                    </Button>
                  </div>
                ))}
                <div className="d-flex align-items-center justify-content-start mt-3">
                  <div className="me-2">
                    Valor: {formatCurrency(resumoCalculo.valor_final, "USD")}
                  </div>
                  <Button
                    color="link"
                    className="p-0"
                    onClick={() =>
                      handleCopyToClipboard(`${resumoCalculo.valor_final}`)
                    }
                  >
                    <FeatherIcon icon="copy" size={16} />
                  </Button>
                </div>
              </div>
            </Alert>
            <Alert color="warning" className="mt-3">
              <div className="text-center">
                <p className="mb-2">
                  <strong>¡Importante!</strong> Después de realizar el pago,
                  suba el comprobante:
                </p>
                <p className="small text-muted mb-3">
                  Archivos permitidos: PDF, PNG, JPG o JPEG
                </p>
                <div className="d-flex justify-content-center align-items-center gap-2">
                  <Input
                    type="file"
                    onChange={handleFileChange}
                    accept=".pdf,.png,.jpg,.jpeg"
                    className="form-control"
                    style={{ maxWidth: "300px" }}
                  />
                  <Button
                    color="success"
                    onClick={() => enviarComprovante(ordem)}
                    disabled={!comprovante || loadingComponent}
                  >
                    {loadingComponent ? (
                      <Spinner size="sm" />
                    ) : (
                      <FeatherIcon icon="upload" size={16} className="me-1" />
                    )}
                  </Button>
                </div>
              </div>
            </Alert>
          </div>
        </CardBody>
      );
    }

    return (
      <CardBody>
        <div className="text-center mt-3">
          <p>Esperando orden de pago...</p>
        </div>
      </CardBody>
    );
  };

  return (
    <Col xl={4}>
      <div className="sticky-side-div">
        <Card>
          <CardHeader className="border-bottom-dashed">
            <h5 className="card-title mb-0">Finalizar Compra</h5>
          </CardHeader>
          {!ordem && (
            <CardHeader className="bg-light-subtle border-bottom-dashed">
              <div className="text-center">
                <Button
                  color="primary"
                  className="w-xs"
                  onClick={handleGerarOrdem}
                  disabled={loading}
                >
                  {loading ? <Spinner size="sm" /> : "Generar Orden de Pago"}
                </Button>
              </div>
            </CardHeader>
          )}
          {renderPaymentSection()}
          <CardBody className="pt-2">
            <div className="table-responsive">
              <table className="table table-borderless mb-0">
                <tbody>
                  {resumoCalculo && (
                    <>
                      {resumoCalculo.produtos.length > 0 ? (
                        <>
                          <tr>
                            <td>Subtotal :</td>
                            <td className="text-end">
                              {resumoCalculo.produtos.length > 0
                                ? formatCurrency(
                                    resumoCalculo.produtos.reduce(
                                      (total, produto, index) =>
                                        total +
                                        produto.preco_usd *
                                          linkData.produtos[index].pivot
                                            .quantidade *
                                          resumoCalculo.cotacao,
                                      0
                                    ),
                                    resumoCalculo.moeda_sigla
                                  )
                                : formatCurrency(
                                    resumoCalculo.valor_final - linkData.taxa,
                                    resumoCalculo.moeda_sigla
                                  )}
                            </td>
                          </tr>
                          <tr>
                            <td>Tasas :</td>
                            <td className="text-end">
                              {formatCurrency(
                                resumoCalculo.valor_final -
                                  resumoCalculo.produtos.reduce(
                                    (total, produto, index) =>
                                      total +
                                      produto.preco_usd *
                                        linkData.produtos[index].pivot
                                          .quantidade *
                                        resumoCalculo.cotacao,
                                    0
                                  ),
                                resumoCalculo.moeda_sigla
                              )}
                            </td>
                          </tr>
                        </>
                      ) : null}
                      <tr className="border-top border-top-dashed">
                        <td colSpan="2"></td>
                      </tr>
                      <tr className="fw-semibold">
                        <td>Total :</td>
                        <td className="text-end">
                          {formatCurrency(
                            resumoCalculo.valor_final,
                            resumoCalculo.moeda_sigla
                          )}
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </div>
    </Col>
  );
};

export default UsdPagamento;
