import React from "react";
import { Modal, ModalBody, ModalHeader, Row, Badge } from "reactstrap";

const ModalAcionistas = ({ modal, setModal, verificacao }) => {
  const toggle = () => {
    setModal(!modal);
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      backdrop={"static"}
      className="modal-md"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={toggle}
      >
        Acionistas de {verificacao?.nome_legal}
      </ModalHeader>

      <ModalBody className="py-3">
        <div className="table-responsive">
          <table className="table align-middle table-nowrap table-striped-columns mb-0">
            <thead className="table-light">
              <tr>
                <th scope="col">Nombre</th>
                <th scope="col">Email</th>
                <th scope="col">Estado</th>
              </tr>
            </thead>
            <tbody>
              {verificacao?.acionistas?.map((acionista, index) => (
                <tr key={index}>
                  <td>{acionista.nome_completo}</td>
                  <td>{acionista.email}</td>
                  <td>
                    {acionista.aprovado === 1 ? (
                      <Badge color="success">Aprobado</Badge>
                    ) : (
                      <Badge color="info">Pendiente</Badge>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalAcionistas;
