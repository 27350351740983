import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import FeatherIcon from "feather-icons-react";

const StepFour = ({ status = "pendente", data = {} }) => {
  const getStatusContent = () => {
    switch (status) {
      case "verificado":
        return {
          icon: "check-circle",
          iconColor: "text-success",
          title: "¡Verificación exitosa!",
          message:
            "Su empresa ha sido verificada exitosamente. Ya puede acceder a todas las funcionalidades de Nautt Finance.",
        };
      case "rejeitado":
        return {
          icon: "x-circle",
          iconColor: "text-danger",
          title: "Verificación denegada",
          message:
            "Lo sentimos, la verificación KYB de su empresa ha sido denegada. Por favor, contacte con nuestro soporte para más información.",
        };
      case "pendente":
      default:
        return {
          icon: "clock",
          iconColor: "text-warning",
          title: "¡Verificación completada!",
          message:
            data.username && data.username !== ""
              ? "¡Los datos de su empresa han sido enviados con éxito! En un plazo de 72 horas, recibirá en su correo electrónico registrado las claves de acceso al sistema de Nautt Finance."
              : "¡Los datos de su empresa han sido enviados con éxito! Por favor, espere unos momentos, regrese a la página de pago, ingrese el documento fiscal nuevamente y realice el pago según las instrucciones.",
        };
    }
  };

  const content = getStatusContent();
  const buttonLink = data.link_pagamento
    ? `/check-nautt/${data.link_pagamento}`
    : "/login";

  return (
    <div className="text-center pt-4">
      <div className="mb-4">
        <FeatherIcon
          icon={content.icon}
          className={content.iconColor}
          style={{ width: "64px", height: "64px" }}
        />
      </div>
      <h4 className={content.iconColor + " mb-3"}>{content.title}</h4>
      <p className="text-muted mb-4">{content.message}</p>
      <Button
        color={status === "rejeitado" ? "danger" : "success"}
        tag={Link}
        to={buttonLink}
        className="btn-hover"
      >
        {data.link_pagamento ? "Ir al pago" : "Ir al inicio de sesión"}
      </Button>
    </div>
  );
};

export default StepFour;
