import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Alert,
  Button,
} from "reactstrap";
import QRCode from "qrcode.react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { formatCurrency } from "../../Components/FunctionsRepository";
import FeatherIcon from "feather-icons-react";

const ModalApagar = ({ modal, setModal, setRefresh, ordem }) => {
  const toggle = () => {
    setRefresh(true);
    setModal(!modal);
  };

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("¡Copiado al portapapeles!");
  };

  const copyItem = (text) => {
    return (
      <div className="d-flex align-items-center justify-content-start mt-2">
        <div className="me-2">{text}</div>
        <Button
          color="link"
          className="p-0"
          onClick={() => handleCopyToClipboard(text)}
        >
          <FeatherIcon icon="copy" size={16} />
        </Button>
      </div>
    );
  };

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Pagar Orden # {ordem?.id}
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          {ordem?.qr_code ? (
            <Row className="-flex justify-content-center mt-3">
              <Col md="12" className="d-flex justify-content-center">
                {
                  <QRCode
                    value={ordem?.qr_code}
                    size={220}
                    style={{ backgroundColor: "white" }}
                    className="p-2"
                  />
                }
              </Col>
              <div className="text-center mt-4">
                Realiza el pago con el QRCode y cierre la ventana para aguardar
                la confirmación del pago.
              </div>
            </Row>
          ) : (ordem?.moeda?.nautt_contas || []).length > 0 ? (
            <Row className="d-flex justify-content-center mt-3">
              <Col md="12" className="d-flex justify-content-center">
                <p>
                  Asegúrese de transferir el monto exacto a la cuenta informada
                  y cierre la ventana para aguardar la confirmación del pago.
                </p>
              </Col>
              <Col md="12" className="mt-3">
                <Alert color="success" className="m-1">
                  <div className="text-left">
                    <div>
                      <b>Destinatario</b>
                    </div>
                    {copyItem(ordem.moeda.nautt_contas[0].nome)}
                    {ordem.moeda.nautt_contas[0].endereco &&
                      copyItem(ordem.moeda.nautt_contas[0].endereco)}
                    <div className="mt-4">
                      <b>{ordem.moeda.sigla === "USD" ? "Cuenta" : "CVU"}</b>
                    </div>
                    {ordem.moeda.nautt_contas[0].conta_cvu &&
                      copyItem(ordem.moeda.nautt_contas[0].conta_cvu)}
                    <div className="mt-4">
                      <b>{ordem.moeda.sigla === "USD" ? "ABA" : "Alias"}</b>
                    </div>
                    {ordem.moeda.nautt_contas[0].aba_alias &&
                      copyItem(ordem.moeda.nautt_contas[0].aba_alias)}
                    <div className="mt-4">
                      <b>Banco</b>
                    </div>
                    {ordem.moeda.nautt_contas[0].banco &&
                      copyItem(ordem.moeda.nautt_contas[0].banco)}
                    {ordem.moeda.nautt_contas[0].endereco_banco &&
                      copyItem(ordem.moeda.nautt_contas[0].endereco_banco)}

                    <div className="d-flex align-items-center justify-content-start mt-3">
                      <div className="me-2">
                        <b>Valor: </b>&nbsp;&nbsp;
                        {formatCurrency(
                          ordem?.total_moeda,
                          ordem?.moeda?.sigla
                        )}
                      </div>
                      <Button
                        color="link"
                        className="p-0"
                        onClick={() =>
                          handleCopyToClipboard(`${ordem?.total_moeda}`)
                        }
                      >
                        <FeatherIcon icon="copy" size={16} />
                      </Button>
                    </div>
                  </div>
                </Alert>
              </Col>
            </Row>
          ) : null}
          <div className="hstack gap-2 justify-content-center mt-5">
            <Link
              to="#"
              className="btn btn-link link-primary fw-medium"
              onClick={() => toggle()}
            >
              <i className="ri-close-line me-1 align-middle"></i> Cerrar
            </Link>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalApagar;
