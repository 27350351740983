import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Spinner,
} from "reactstrap";
import { Link, useParams } from "react-router-dom";
import withRouter from "../../Components/Common/withRouter";
import { toast } from "react-toastify";
import { APIClient } from "../../helpers/api_helper";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import logoLight from "../../assets/images/logo-light.png";
import FeatherIcon from "feather-icons-react";

const apiClient = new APIClient();

const ForgetPasswordPage = () => {
  const { uuid } = useParams();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [resetError, setResetError] = useState("");

  document.title = uuid 
    ? "Cambiar Contraseña | Nautt Finance"
    : "Recuperar Contraseña | Nautt Finance";

  const handleSubmitEmail = async (e) => {
    e.preventDefault();
    setLoading(true);

    const response = await apiClient.post("/reset-password", {
      email,
    });

    if (response.sucesso) {
      setEmailSent(true);
    } else {
      if (response.mensagem !== "") toast.error(response.mensagem);
    }

    setLoading(false);
  };

  const handleSubmitPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    setResetError("");

    const response = await apiClient.post("/confirm-reset", {
      uuid,
      password,
      password_confirmation: passwordConfirmation,
    });

    if (response.sucesso) {
      setResetSuccess(true);
    } else {
      setResetError(response.mensagem || "Error al cambiar la contraseña");
    }

    setLoading(false);
  };

  const renderResetForm = () => {
    if (resetSuccess) {
      return (
        <div className="text-center">
          <div className="mb-4 text-success">
            <FeatherIcon icon="check-circle" size={50} />
          </div>
          <h4 className="text-success">¡Contraseña cambiada!</h4>
          <p className="text-muted mt-3">
            Tu contraseña ha sido cambiada exitosamente.
          </p>
          <div className="mt-4">
            <Link
              to="/login"
              className="btn btn-success w-100"
            >
              Ir a iniciar sesión
            </Link>
          </div>
        </div>
      );
    }

    if (resetError) {
      return (
        <div className="text-center">
          <div className="mb-4 text-danger">
            <FeatherIcon icon="x-circle" size={50} />
          </div>
          <h4 className="text-danger">Error</h4>
          <p className="text-muted mt-3">{resetError}</p>
          <div className="mt-4">
            <Button
              color="success"
              className="w-100"
              onClick={() => setResetError("")}
            >
              Intentar nuevamente
            </Button>
          </div>
        </div>
      );
    }

    return (
      <>
        <div className="alert alert-borderless alert-warning text-center mb-4 mx-2">
          Ingresa tu nueva contraseña
        </div>
        <form onSubmit={handleSubmitPassword}>
          <div className="mb-3">
            <Label className="form-label">Nueva contraseña</Label>
            <div className="position-relative auth-pass-inputgroup mb-3">
              <Input
                name="password"
                value={password}
                type={showPassword ? "text" : "password"}
                className="form-control pe-5"
                placeholder="Ingresa tu nueva contraseña"
                required
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted shadow-none"
                onClick={() => setShowPassword(!showPassword)}
                type="button"
              >
                <i className="ri-eye-fill align-middle"></i>
              </button>
            </div>
          </div>

          <div className="mb-3">
            <Label className="form-label">Confirmar contraseña</Label>
            <div className="position-relative auth-pass-inputgroup mb-3">
              <Input
                name="password_confirmation"
                value={passwordConfirmation}
                type={showPasswordConfirmation ? "text" : "password"}
                className="form-control pe-5"
                placeholder="Confirma tu nueva contraseña"
                required
                onChange={(e) => setPasswordConfirmation(e.target.value)}
              />
              <button
                className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted shadow-none"
                onClick={() => setShowPasswordConfirmation(!showPasswordConfirmation)}
                type="button"
              >
                <i className="ri-eye-fill align-middle"></i>
              </button>
            </div>
          </div>

          <div className="text-center mt-4">
            <Button
              color="success"
              className="w-100"
              type="submit"
              disabled={loading}
            >
              {loading ? <Spinner size="sm" className="me-2" /> : null}
              Cambiar contraseña
            </Button>
          </div>
        </form>
      </>
    );
  };

  const renderEmailForm = () => {
    if (emailSent) {
      return (
        <div className="text-center">
          <div className="mb-4 text-success">
            <FeatherIcon icon="mail" size={50} />
          </div>
          <h4 className="text-success">¡Revisa tu correo!</h4>
          <p className="text-muted mt-3">
            Hemos enviado un enlace de recuperación a{" "}
            <span className="fw-semibold">{email}</span>. 
            Por favor, revisa tu bandeja de entrada y sigue las instrucciones.
          </p>
          <p className="text-muted mt-2">
            ¿No recibiste el correo? Revisa tu carpeta de spam o{" "}
            <Link
              to="#"
              className="text-success"
              onClick={(e) => {
                e.preventDefault();
                setEmailSent(false);
              }}
            >
              intenta nuevamente
            </Link>
          </p>
        </div>
      );
    }

    return (
      <>
        <div className="alert alert-borderless alert-warning text-center mb-4 mx-2">
          Ingresa tu correo electrónico y te enviaremos las instrucciones
        </div>
        <form onSubmit={handleSubmitEmail}>
          <div className="mb-4">
            <Label className="form-label">Correo electrónico</Label>
            <Input
              name="email"
              className="form-control"
              placeholder="Ingresa tu correo electrónico"
              type="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="text-center mt-4">
            <Button
              color="success"
              className="w-100"
              type="submit"
              disabled={loading}
            >
              {loading ? <Spinner size="sm" className="me-2" /> : null}
              Enviar instrucciones
            </Button>
          </div>
        </form>
      </>
    );
  };

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <Link to="/" className="d-inline-block auth-logo">
                    <img src={logoLight} alt="" />
                  </Link>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-success">
                        {uuid ? "Cambiar contraseña" : "¿Olvidaste tu contraseña?"}
                      </h5>
                    </div>
                    <div className="p-2 mt-3">
                      {uuid ? renderResetForm() : renderEmailForm()}
                    </div>
                  </CardBody>
                </Card>

                {!uuid && (
                  <div className="mt-4 text-center">
                    <p className="mb-0">
                      Espera, ya recordé mi contraseña...{" "}
                      <Link
                        to="/login"
                        className="fw-semibold text-primary text-decoration-underline"
                      >
                        Haz clic aquí
                      </Link>
                    </p>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(ForgetPasswordPage);
