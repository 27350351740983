import React, { useState, useCallback, useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  FormFeedback,
  Row,
  Col,
} from "reactstrap";
import { toast } from "react-toastify";
import { APIClient } from "../../../helpers/api_helper";
import InputMask from "react-input-mask";
import { Link } from "react-router-dom";

const apiClient = new APIClient();

const StepOne = ({ onComplete, data, countries }) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const isOnboarding = location.pathname.includes("kyb-wizard-onboarding");
  const indicadoPor = searchParams.get("indicado");
  const codPromocionalUrl = searchParams.get("cod_promocional");
  const linkPagamento = !isOnboarding
    ? searchParams.get("link_pagamento")
    : null;
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: data.email || "",
    nome_legal: data.nome_legal || "",
    nome_fantasia: data.nome_fantasia || "",
    data_constituicao: data.data_constituicao || "",
    atividade: data.atividade || "",
    pais_fiscal: data.pais_fiscal || "",
    telefone: data.telefone || "",
    website: data.website || "",
    checkbox_privacidade: data.checkbox_privacidade || false,
    username: data.username || "",
    senha: data.senha || "",
  });
  const [codPromocional, setCodPromocional] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (codPromocionalUrl) {
      setCodPromocional(codPromocionalUrl);
    }
  }, [codPromocionalUrl]);

  const getPhoneMask = () => {
    const selectedCountry = countries.find(
      (country) => country.country_code === formData.pais_fiscal
    );
    return selectedCountry?.tipo_documento?.mask_tel || "";
  };

  const getDDI = () => {
    const selectedCountry = countries.find(
      (country) => country.country_code === formData.pais_fiscal
    );
    return selectedCountry?.tipo_documento?.ddi || "";
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    if (name === "username") {
      // Only allow lowercase letters, numbers and underscore
      const sanitizedValue = value.toLowerCase().replace(/[^a-z0-9_]/g, '').slice(0, 15);
      setFormData((prev) => ({
        ...prev,
        username: sanitizedValue
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const handleCodigo = (value) => {
    const sanitizedValue = value.replace(/[^a-zA-Z0-9_]/g, "");
    return sanitizedValue.toUpperCase().slice(0, 15);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.email) newErrors.email = "El email es obligatorio";
    if (!formData.nome_legal)
      newErrors.nome_legal = "El nombre legal es obligatorio";
    if (!formData.nome_fantasia)
      newErrors.nome_fantasia = "El nombre comercial es obligatorio";
    if (!formData.data_constituicao)
      newErrors.data_constituicao = "La fecha de constitución es obligatoria";
    if (!formData.atividade)
      newErrors.atividade = "La actividad es obligatoria";
    if (!formData.pais_fiscal) newErrors.pais_fiscal = "El país es obligatorio";
    if (!formData.telefone) newErrors.telefone = "El teléfono es obligatorio";
    if (!formData.checkbox_privacidade)
      newErrors.checkbox_privacidade = "Debe aceptar la política de privacidad";
    if (isOnboarding) {
      if (!formData.username)
        newErrors.username = "El nombre de usuario es obligatorio";
      if (!formData.senha)
        newErrors.senha = "La contraseña es obligatoria";
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setLoading(true);

    try {
      const payload = {
        ...formData,
        indicado_por: indicadoPor,
        link_pagamento: linkPagamento,
        ...(codPromocional && {
          cod_promocional: codPromocional,
        }),
      };

      const response = await apiClient.post("/kyb", payload);
      if (response.sucesso) {
        onComplete({ ...formData, uuid: response.dados.kyb_code });
      } else {
        toast.error(response.mensagem || "Error al guardar los datos");
      }
    } catch (error) {
      console.error(error);
      toast.error("Error al guardar los datos");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      {/* Código Promocional */}
      <Row className="mb-3">
        <Col md={12}>
          <FormGroup>
            <Label for="codPromocional">Código Promocional</Label>
            <Input
              type="text"
              name="codPromocional"
              id="codPromocional"
              value={codPromocional}
              onChange={(e) => setCodPromocional(handleCodigo(e.target.value))}
              maxLength={15}
            />
            <small className="text-muted">
              Ingrese aquí un código que haya recibido en un evento con Nautt
            </small>
          </FormGroup>
        </Col>
      </Row>

      {/* Row 1: Username and Password (only for onboarding) */}
      {isOnboarding && (
        <Row className="mb-3">
          <Col md={6}>
            <FormGroup>
              <Label for="username">
                Nombre de Usuario <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                name="username"
                id="username"
                value={formData.username}
                onChange={handleInputChange}
                invalid={!!errors.username}
                maxLength={50}
              />
              <FormFeedback>{errors.username}</FormFeedback>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="senha">
                Contraseña <span className="text-danger">*</span>
              </Label>
              <Input
                type="password"
                name="senha"
                id="senha"
                value={formData.senha}
                onChange={handleInputChange}
                invalid={!!errors.senha}
              />
              <FormFeedback>{errors.senha}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
      )}

      {/* Row 2: Email and Nome Legal */}
      <Row className="mb-3">
        <Col md={6}>
          <FormGroup>
            <Label for="nome_legal">
              Nombre Legal <span className="text-danger">*</span>
            </Label>
            <Input
              type="text"
              name="nome_legal"
              id="nome_legal"
              value={formData.nome_legal}
              onChange={handleInputChange}
              invalid={!!errors.nome_legal}
              maxLength={100}
            />
            <FormFeedback>{errors.nome_legal}</FormFeedback>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="nome_fantasia">
              Nombre Comercial <span className="text-danger">*</span>
            </Label>
            <Input
              type="text"
              name="nome_fantasia"
              id="nome_fantasia"
              value={formData.nome_fantasia}
              onChange={handleInputChange}
              invalid={!!errors.nome_fantasia}
              maxLength={100}
            />
            <FormFeedback>{errors.nome_fantasia}</FormFeedback>
          </FormGroup>
        </Col>
      </Row>

      {/* Row 3: Nome Fantasia and Data Constituição */}
      <Row className="mb-3">
        <Col md={6}>
          <FormGroup>
            <Label for="email">
              Email <span className="text-danger">*</span>
            </Label>
            <Input
              type="email"
              name="email"
              id="email"
              value={formData.email}
              onChange={handleInputChange}
              invalid={!!errors.email}
            />
            <FormFeedback>{errors.email}</FormFeedback>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="data_constituicao">
              Fecha de Constitución <span className="text-danger">*</span>
            </Label>
            <Input
              type="date"
              name="data_constituicao"
              id="data_constituicao"
              value={formData.data_constituicao}
              onChange={handleInputChange}
              invalid={!!errors.data_constituicao}
            />
            <FormFeedback>{errors.data_constituicao}</FormFeedback>
          </FormGroup>
        </Col>
      </Row>

      {/* Row 4: País and Telefone */}
      <Row className="mb-3">
        <Col md={6}>
          <FormGroup>
            <Label for="pais_fiscal">
              País <span className="text-danger">*</span>
            </Label>
            <Input
              type="select"
              name="pais_fiscal"
              id="pais_fiscal"
              value={formData.pais_fiscal}
              onChange={handleInputChange}
              invalid={!!errors.pais_fiscal}
            >
              <option value="">Seleccione un país</option>
              {countries.map((country) => (
                <option key={country.country_code} value={country.country_code}>
                  {country.pais}
                </option>
              ))}
            </Input>
            <FormFeedback>{errors.pais_fiscal}</FormFeedback>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="telefone">
              Teléfono <span className="text-danger">*</span>
            </Label>
            <InputMask
              mask={getPhoneMask()}
              value={formData.telefone}
              onChange={handleInputChange}
            >
              {(inputProps) => (
                <Input
                  {...inputProps}
                  type="text"
                  name="telefone"
                  id="telefone"
                  invalid={!!errors.telefone}
                />
              )}
            </InputMask>
            <FormFeedback>{errors.telefone}</FormFeedback>
          </FormGroup>
        </Col>
      </Row>

      {/* Row 5: Atividade and Website */}
      <Row className="mb-3">
        <Col md={6}>
          <FormGroup>
            <Label for="atividade">
              Actividad <span className="text-danger">*</span>
            </Label>
            <Input
              type="text"
              name="atividade"
              id="atividade"
              value={formData.atividade}
              onChange={handleInputChange}
              invalid={!!errors.atividade}
              maxLength={100}
            />
            <FormFeedback>{errors.atividade}</FormFeedback>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="website">Website</Label>
            <Input
              type="url"
              name="website"
              id="website"
              value={formData.website}
              onChange={handleInputChange}
              invalid={!!errors.website}
              placeholder="https://"
            />
            <FormFeedback>{errors.website}</FormFeedback>
          </FormGroup>
        </Col>
      </Row>

      <FormGroup check className="mt-4">
        <Input
          type="checkbox"
          name="checkbox_privacidade"
          id="checkbox_privacidade"
          checked={formData.checkbox_privacidade}
          onChange={handleInputChange}
          invalid={!!errors.checkbox_privacidade}
        />
        <Label check for="checkbox_privacidade">
          Acepto la{" "}
          <Link
            to="https://nautt.finance/wp-content/uploads/2024/12/3-DocOps-Politica-de-Privacidade.pdf"
            target="_blank"
          >
            política de privacidad
          </Link>
        </Label>
        {!!errors.checkbox_privacidade && (
          <div className="invalid-feedback d-block">
            {errors.checkbox_privacidade}
          </div>
        )}
      </FormGroup>

      <div className="mt-4">
        <Button
          color="success"
          className="w-100"
          type="submit"
          disabled={loading}
        >
          {loading ? <Spinner size="sm" /> : "Continuar"}
        </Button>
      </div>
    </Form>
  );
};

export default StepOne;
