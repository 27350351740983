import React, { useState, useCallback, useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  FormFeedback,
  Row,
  Col,
} from "reactstrap";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import { Link } from "react-router-dom";
import { APIClient } from "../../../helpers/api_helper";

const apiClient = new APIClient();

const StepOne = ({ onComplete, data }) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [isAble, setIsAble] = useState(false);
  const isOnboarding = location.pathname.includes("kyc-wizard-onboarding");
  const indicadoPor = searchParams.get("indicado");
  const codPromocionalUrl = searchParams.get("cod_promocional");
  const linkPagamento = !isOnboarding
    ? searchParams.get("link_pagamento")
    : null;
  const kybId = searchParams.get("kyb_id");
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [codPromocional, setCodPromocional] = useState("");
  const [formData, setFormData] = useState({
    email: data.email || "",
    documento: data.documento || "",
    nome: data.nome || "",
    pais: data.pais || "",
    numero_telefone: data.numero_telefone || "",
    primeiro_sobrenome: data.primeiro_sobrenome || "",
    atividade: data.atividade || "",
    checkbox_privacidade: data.checkbox_privacidade || false,
    username: data.username || "",
    senha: data.senha || "",
  });
  const [errors, setErrors] = useState({});

  const fetchCountries = useCallback(async () => {
    setLoading(true);
    const response = await apiClient.get("/moedas");
    if (response.sucesso) {
      const dados = response.dados;
      setCountries(dados);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await fetchCountries();
      if (codPromocionalUrl) {
        setCodPromocional(codPromocionalUrl);
      }
    };
    fetchData();
  }, [fetchCountries, codPromocionalUrl]);

  const getPhoneMask = () => {
    const selectedCountry = countries.find(
      (country) => country.country_code === formData.pais
    );
    return selectedCountry?.tipo_documento?.mask_tel || "";
  };

  const getDocumentoMask = () => {
    const selectedCountry = countries.find(
      (country) => country.country_code === formData.pais
    );
    if (!selectedCountry || !selectedCountry?.tipo_documento) return "";
    return selectedCountry?.tipo_documento.mask_pf;
  };

  const getDDI = () => {
    const selectedCountry = countries.find(
      (country) => country.country_code === formData.pais
    );
    return selectedCountry?.tipo_documento?.ddi || "";
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === "username") {
      // Only allow lowercase letters, numbers and underscore
      const sanitizedValue = value
        .toLowerCase()
        .replace(/[^a-z0-9_]/g, "")
        .slice(0, 15);
      setFormData((prev) => ({
        ...prev,
        username: sanitizedValue,
      }));
    } else if (name === "nome") {
      // Only allow letters and spaces
      const sanitizedValue = value.replace(/[^a-zA-Z\s]/g, "");
      setFormData((prev) => ({
        ...prev,
        [name]: sanitizedValue,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      }));
    }

    // Clear error when user types
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleCodigo = (value) => {
    const sanitizedValue = value.replace(/[^a-zA-Z0-9_]/g, "");
    return sanitizedValue.toUpperCase().slice(0, 15);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.email) newErrors.email = "El email es obligatorio";
    if (!formData.pais) newErrors.pais = "El país es obligatorio";
    if (!formData.documento)
      newErrors.documento = "El documento es obligatorio";
    if (!formData.nome) newErrors.nome = "El nombre es obligatorio";
    if (!formData.numero_telefone)
      newErrors.numero_telefone = "El teléfono es obligatorio";
    if (!formData.primeiro_sobrenome)
      newErrors.primeiro_sobrenome = "El primer apellido es obligatorio";
    if (!formData.atividade)
      newErrors.atividade = "La actividad es obligatoria";
    if (!formData.checkbox_privacidade)
      newErrors.checkbox_privacidade = "Debe aceptar la política de privacidad";
    if (isOnboarding) {
      if (!formData.username)
        newErrors.username = "El nombre de usuario es obligatorio";
      if (!formData.senha) newErrors.senha = "La contraseña es obligatoria";
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    setLoading(true);

    try {
      const payload = {
        email: formData.email,
        pais: formData.pais,
        documento: formData.documento,
        nome: formData.nome,
        numero_telefone: `${getDDI()}${formData.numero_telefone.replace(
          /\D/g,
          ""
        )}`,
        primeiro_sobrenome: formData.primeiro_sobrenome,
        atividade: formData.atividade,
        checkbox_privacidade: formData.checkbox_privacidade,
        ...(isOnboarding && {
          username: formData.username,
          senha: formData.senha,
        }),
        ...(indicadoPor && {
          indicado_por: indicadoPor,
        }),
        ...(kybId && {
          kyb_id: kybId,
        }),
        ...(!isOnboarding &&
          linkPagamento && {
            link_pagamento: linkPagamento,
          }),
        ...(codPromocional && {
          cod_promocional: codPromocional,
        }),
      };

      const response = await apiClient.post("/kyc", payload);
      if (response.sucesso) {
        onComplete({ ...formData, uuid: response.dados.kyc_code });
      } else {
        toast.error(response.mensagem || "Error al guardar los datos");
      }
    } catch (error) {
      console.error(error);
      toast.error("Error al guardar los datos");
    } finally {
      setLoading(false);
    }
  };

  const pesquisarDocumento = async () => {
    // Verificar apenas erros de país e documento
    const newErrors = {};
    if (!formData.pais) newErrors.pais = "El país es obligatorio";
    if (!formData.documento)
      newErrors.documento = "El documento es obligatorio";
    if (!formData.nome) newErrors.nome = "El nombre es obligatorio";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setLoading(true);

    try {
      const payload = {
        documento: formData.documento,
      };

      const response = await apiClient.post("/kyc/documento", payload);
      if (response.sucesso) {
        const redirectUrl = (response.dados?.username || "") !== ""
          ? `/kyc-wizard-onboarding/${response.dados.kyc_code}`
          : `/kyc-wizard/${response.dados.kyc_code}`;
        window.location.href = redirectUrl;
      } else if (response.responseCode === 404) {
        setIsAble(true);
      } else {
        toast.error(response.mensagem || "Error al consultar los datos");
      }
    } catch (error) {
      console.error(error);
      toast.error("Error al consultar los datos");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="pais">País Fiscal</Label>
            <Input
              type="select"
              name="pais"
              id="pais"
              value={formData.pais}
              onChange={handleInputChange}
              invalid={!!errors.pais}
            >
              <option value="">Seleccione un país</option>
              {countries.map((country) => (
                <option key={country.country_code} value={country.country_code}>
                  {country.pais}
                </option>
              ))}
            </Input>
            <FormFeedback>{errors.pais}</FormFeedback>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="documento">Documento</Label>
            {formData.pais && formData.pais !== "" ? (
              <InputMask
                mask={getDocumentoMask()}
                value={formData.documento}
                onChange={handleInputChange}
              >
                {(inputProps) => (
                  <Input
                    {...inputProps}
                    type="text"
                    name="documento"
                    id="documento"
                    invalid={!!errors.documento}
                  />
                )}
              </InputMask>
            ) : (
              <Input disabled type="text"></Input>
            )}
            <FormFeedback>{errors.documento}</FormFeedback>
          </FormGroup>
        </Col>
        <Col md={12}>
          <FormGroup>
            <Label for="nome">Nombre</Label>
            <Input
              type="text"
              name="nome"
              id="nome"
              value={formData.nome}
              onChange={handleInputChange}
              invalid={!!errors.nome}
              maxLength={200}
            />
            <FormFeedback>{errors.nome}</FormFeedback>
          </FormGroup>
        </Col>
        {/* <Col md={12}>
          <FormGroup>
            <Label for="codPromocional">Código Promocional</Label>
            <Input
              type="text"
              name="codPromocional"
              id="codPromocional"
              value={codPromocional}
              onChange={(e) => setCodPromocional(handleCodigo(e.target.value))}
              maxLength={15}
            />
            <small className="text-muted">
              Ingrese aquí un código que haya recibido en un evento con Nautt
            </small>
          </FormGroup>
        </Col> */}
      </Row>
      {!isAble && !linkPagamento && (
        <div className="mt-4 mb-4">
          <Button
            color="primary"
            className="w-100"
            type="button"
            onClick={pesquisarDocumento}
            disabled={loading}
          >
            {loading ? <Spinner size="sm" /> : "Consultar Documento"}
          </Button>
        </div>
      )}
      {(linkPagamento || isAble) && (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              {isOnboarding && (
                <FormGroup>
                  <Label for="username">
                    Nombre de Usuario <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="username"
                    id="username"
                    value={formData.username}
                    onChange={handleInputChange}
                    invalid={!!errors.username}
                    maxLength={15}
                  />
                  <FormFeedback>{errors.username}</FormFeedback>
                </FormGroup>
              )}

              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  invalid={!!errors.email}
                />
                <FormFeedback>{errors.email}</FormFeedback>
              </FormGroup>

              <FormGroup>
                <Label for="primeiro_sobrenome">Primer Apellido</Label>
                <Input
                  type="text"
                  name="primeiro_sobrenome"
                  id="primeiro_sobrenome"
                  value={formData.primeiro_sobrenome}
                  onChange={handleInputChange}
                  invalid={!!errors.primeiro_sobrenome}
                  maxLength={100}
                />
                <FormFeedback>{errors.primeiro_sobrenome}</FormFeedback>
              </FormGroup>
            </Col>

            <Col md={6}>
              {isOnboarding && (
                <FormGroup>
                  <Label for="senha">
                    Contraseña <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="password"
                    name="senha"
                    id="senha"
                    value={formData.senha}
                    onChange={handleInputChange}
                    invalid={!!errors.senha}
                    maxLength={50}
                  />
                  <FormFeedback>{errors.senha}</FormFeedback>
                </FormGroup>
              )}

              <FormGroup>
                <Label for="numero_telefone">Teléfono</Label>
                <InputMask
                  mask={getPhoneMask()}
                  value={formData.numero_telefone}
                  onChange={handleInputChange}
                >
                  {(inputProps) => (
                    <Input
                      {...inputProps}
                      type="text"
                      name="numero_telefone"
                      id="numero_telefone"
                      invalid={!!errors.numero_telefone}
                    />
                  )}
                </InputMask>
                <FormFeedback>{errors.numero_telefone}</FormFeedback>
              </FormGroup>

              <FormGroup>
                <Label for="atividade">Actividad</Label>
                <Input
                  type="text"
                  name="atividade"
                  id="atividade"
                  value={formData.atividade}
                  onChange={handleInputChange}
                  invalid={!!errors.atividade}
                  maxLength={100}
                />
                <FormFeedback>{errors.atividade}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>

          <FormGroup check className="mb-4">
            <Input
              type="checkbox"
              name="checkbox_privacidade"
              id="checkbox_privacidade"
              checked={formData.checkbox_privacidade}
              onChange={handleInputChange}
              invalid={!!errors.checkbox_privacidade}
            />
            <Label check for="checkbox_privacidade">
              Acepto la{" "}
              <Link
                to="https://nautt.finance/wp-content/uploads/2024/12/3-DocOps-Politica-de-Privacidade.pdf"
                target="_blank"
              >
                política de privacidad
              </Link>
            </Label>
            <FormFeedback>{errors.checkbox_privacidade}</FormFeedback>
          </FormGroup>

          <div className="mt-4">
            <Button
              color="success"
              className="w-100"
              type="submit"
              disabled={loading}
            >
              {loading ? <Spinner size="sm" /> : "Continuar"}
            </Button>
          </div>
        </Form>
      )}
    </>
  );
};

export default StepOne;
