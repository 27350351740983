import React, { useState, useCallback, useEffect } from "react";
import InputMask from "react-input-mask";
import {
  Card,
  CardBody,
  Alert,
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  CardHeader,
  CardText,
  Spinner,
} from "reactstrap";
import FeatherIcon from "feather-icons-react";
import { toast } from "react-toastify";
import { APIClient } from "../../../helpers/api_helper";
import { Link } from "react-router-dom";

const apiClient = new APIClient();

const KoywePagamento = ({
  loading,
  ordem,
  resumoCalculo,
  formatCurrency,
  linkData,
  setOrdem,
  setLinkKoywe,
  linkKoywe,
}) => {
  const [loadingComponent, setLoadingComponent] = useState(false);
  const [documentoDados, setDocumentoDados] = useState(null);
  const [metodosPagamento, setMetodosPagamento] = useState([]);
  const [tipoDocumento, setTipoDocumento] = useState("fisica");
  const [pais, setPais] = useState(null);
  const [moedaId, setMoedaId] = useState(null);
  const [documento, setDocumento] = useState("");
  const [email, setEmail] = useState(null);
  const [errors, setErrors] = useState({});
  const [metodoPagamentoSelecionado, setMetodoPagamentoSelecionado] =
    useState("");
  const [bankData, setBankData] = useState();
  const [file, setFile] = useState(null);

  const bankDataChange = async (e) => {
    setMetodoPagamentoSelecionado(e.target.value);
    setBankData(
      metodosPagamento.find((metodo) => metodo.koywe_id === e.target.value)
        ?.bank_data
    );
  };

  const getDocumentoPlaceholder = (mask) => {
    return mask?.replace(/9/g, "0");
  };

  const enviarComprovante = async (ordem) => {
    setLoadingComponent(true);

    try {
      if (!file) {
        toast.error("Por favor, seleccione un archivo de comprobante.");
        setLoadingComponent(false);
        return;
      }

      const formData = new FormData();
      formData.append("comprovante", file);
      formData.append("ordem_id", ordem.id);

      const extensaoComprovante = file.name.split(".").pop();
      formData.append("extensaoComprovante", extensaoComprovante);
      formData.append("uuid", linkData.link);

      const response = await apiClient.post(
        "/ordens-koywe/comprovante",
        formData
      );

      if (response.sucesso) {
        toast.success(response.mensagem);
        const ordemAntiga = ordem;
        ordemAntiga.status = "processando";
        setOrdem(ordemAntiga);
      } else {
        toast.error(response.mensagem || "Error al subir el comprobante");
      }
    } catch (error) {
      console.error("Error al enviar comprobante:", error);
      toast.error("Ocurrió un error al enviar el comprobante");
    } finally {
      setLoadingComponent(false);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileType = selectedFile.type;
      const validTypes = [
        "application/pdf",
        "image/png",
        "image/jpeg",
        "image/jpg",
      ];
      if (validTypes.includes(fileType)) {
        setFile(selectedFile);
      } else {
        toast.error("Por favor, seleccione un archivo PDF, PNG o JPG/JPEG");
        e.target.value = null;
      }
    }
  };

  const fetchMoedas = useCallback(async (resumoCalculo) => {
    setLoadingComponent(true);
    const response = await apiClient.get("/moedas");
    if (response.sucesso) {
      const dados = response.dados;

      dados.forEach((moeda) => {
        if (moeda.sigla === resumoCalculo.moeda_sigla) {
          setDocumentoDados(moeda.tipo_documento);
          setPais(moeda.pais);
          setMoedaId(moeda.id);
        }
      });
    } else {
      console.error(response.mensagem);
      toast.error("Error al cargar las informaciones.");
    }
    setLoadingComponent(false);
  }, []);

  const fetchMetodosPagamento = useCallback(async (moedaId) => {
    setLoadingComponent(true);
    const response = await apiClient.get("/ordens-koywe/metodos-pagamento");
    if (response.sucesso) {
      const dados = response.dados;
      setMetodosPagamento(
        dados.filter(
          (metodo) =>
            metodo.moeda_id === parseInt(moedaId) &&
            metodo?.bank_data &&
            metodo?.bank_data !== ""
        )
      );
    } else {
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    setLoadingComponent(false);
  }, []);

  const handleVerificarClick = async () => {
    if (!documento) {
      setErrors({ documento: "Documento es obligatorio" });
      return;
    }

    setLoadingComponent(true);
    const response = await apiClient.post("/kyc/email/busca", { documento });
    if (response.sucesso) {
      setEmail(response.dados);
    } else {
      setEmail(response.dados || "erro_desconhecido");
    }
    setLoadingComponent(false);
  };

  const handleGerarLinkPagamento = async () => {
    if (!metodoPagamentoSelecionado) {
      toast.error("Seleccione un método de pago");
      return;
    }

    setLoadingComponent(true);
    const payload = {
      total: resumoCalculo.valor_final,
      moeda: resumoCalculo.moeda_sigla,
      email: email,
      documento: documento,
      metodo_pagamento: metodoPagamentoSelecionado,
      moeda_final: "USDT",
      pdv_id: linkData.pdv_id,
      link_id: linkData.id,
    };

    const response = await apiClient.post("/ordem-koywe", payload);
    if (response.sucesso && response.dados?.link_pagamento) {
      setOrdem(response.dados);
      setLinkKoywe(response.dados.link_pagamento);
    } else {
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    setLoadingComponent(false);
  };

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("¡Copiado al portapapeles!");
  };

  const handleCancelarOrdem = async () => {
    setLoadingComponent(true);
    const response = await apiClient.post("/ordem-koywe/cancelar", {
      link_id: linkData.id,
    });

    if (response.sucesso) {
      setOrdem(null);
      setMetodoPagamentoSelecionado("");
      setLinkKoywe(null);
    } else {
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    setLoadingComponent(false);
  };

  const handleInputChange = (e) => {
    let value = e.target.value;

    if (documentoDados?.mask_pf === '#') {
      value = value.replace(/[^a-zA-Z0-9]/g, '');
      value = value.toUpperCase();
      value = value.slice(0, 20);
    }
    
    setDocumento(value);
    setErrors({});
  };

  useEffect(() => {
    const fetchAll = async () => {
      if (resumoCalculo) {
        setLoadingComponent(true);
        await fetchMoedas(resumoCalculo);
        await fetchMetodosPagamento(moedaId);
        setLoadingComponent(false);
      }
    };
    fetchAll();
  }, [resumoCalculo, fetchMoedas, moedaId]);

  const renderPaymentSection = () => {
    if (ordem) {
      if (ordem.status === "novo") {
        return (
          <div className="mt-4 p-4">
            <div className="text-center">
              <b>¡Orden de pago generada con éxito!</b>
              <p>
                Asegúrese de transferir el monto exacto a la cuenta informada y
                permanezca en esta pantalla para recibir la confirmación:
              </p>
              <Alert color="success" className="m-1">
                <div className="text-left">
                  {(ordem.metodo_pagamento?.bank_data || bankData || "")
                    ?.split("\n")
                    .map((line, index) => (
                      <div
                        key={index}
                        className="d-flex align-items-center justify-content-start mt-2"
                      >
                        <div className="me-2">{line}</div>
                        <Button
                          color="link"
                          className="p-0"
                          onClick={() => handleCopyToClipboard(line)}
                        >
                          <FeatherIcon icon="copy" size={16} />
                        </Button>
                      </div>
                    ))}
                  <div className="d-flex align-items-center justify-content-start mt-3">
                    <div className="me-2">
                      Valor:{" "}
                      {formatCurrency(
                        resumoCalculo.valor_final,
                        resumoCalculo.moeda_sigla
                      )}
                    </div>
                    <Button
                      color="link"
                      className="p-0"
                      onClick={() =>
                        handleCopyToClipboard(`${resumoCalculo.valor_final}`)
                      }
                    >
                      <FeatherIcon icon="copy" size={16} />
                    </Button>
                  </div>
                </div>
              </Alert>
              {!ordem?.recibo_url && (
                <Alert color="warning" className="mt-3">
                  <div className="text-center">
                    <p className="mb-2">
                      <strong>¡Importante!</strong> Después de realizar el pago,
                      suba el comprobante:
                    </p>
                    <p className="small text-muted mb-3">
                      Archivos permitidos: PDF, PNG, JPG o JPEG
                    </p>
                    <div className="d-flex justify-content-center align-items-center gap-2">
                      <Input
                        type="file"
                        onChange={handleFileChange}
                        accept=".pdf,.png,.jpg,.jpeg"
                        className="form-control"
                        style={{ maxWidth: "300px" }}
                      />
                      <Button
                        color="success"
                        onClick={() => enviarComprovante(ordem)}
                        disabled={!file || loadingComponent}
                      >
                        {loadingComponent ? (
                          <Spinner size="sm" />
                        ) : (
                          <FeatherIcon
                            icon="upload"
                            size={16}
                            className="me-1"
                          />
                        )}
                      </Button>
                    </div>
                  </div>
                </Alert>
              )}
              {/* <div className="mt-3">
                <Button
                  color="danger"
                  onClick={handleCancelarOrdem}
                  disabled={loadingComponent}
                >
                  {loadingComponent ? <Spinner size="sm" /> : "Cambiar Metodo"}
                </Button>
              </div> */}
            </div>
          </div>
        );
      } else if (ordem.status === "processando") {
        return (
          <div className="m-3">
            <Alert color="warning">
              <div className="text-center">
                <p>El pago está siendo procesado.</p>
                <p>Por favor, aguarde la confirmación.</p>
              </div>
            </Alert>
          </div>
        );
      } else {
        return (
          <div className="m-3">
            <Alert color="warning">
              <div className="text-center">
                <p>Esta orden ya no está disponible para pago.</p>
              </div>
            </Alert>
          </div>
        );
      }
    }

    return (
      <CardBody>
        {!linkKoywe &&
          !(
            email &&
            typeof email === "string" &&
            email !== "nao_encontrado" &&
            email !== "em_andamento" &&
            email !== "negado" &&
            email !== "erro_desconhecido"
          ) && (
            <>
              <FormGroup>
                <Label for="tipoDocumento">Tipo de Documento</Label>
                <Input
                  type="select"
                  name="tipoDocumento"
                  id="tipoDocumento"
                  value={tipoDocumento}
                  disabled
                  className="form-select"
                >
                  <option value="fisica">Persona Física</option>
                  <option value="juridica">Persona Jurídica</option>
                </Input>
              </FormGroup>

              {documentoDados && (
                <FormGroup className="mt-3">
                  <Label for="documento">
                    {documentoDados.doc_pf} ({pais})
                  </Label>
                  {documentoDados.mask_pf!=='#' ?
                    
                    <InputMask
                    mask={documentoDados.mask_pf}
                    value={documento}
                    formatChars={{
                      "9": "[0-9]",
                      "?": "[0-9A-Za-z]",
                    }}
                    onChange={handleInputChange}
                    className={`form-control ${
                      errors.documento ? "is-invalid" : ""
                    }`}
                    placeholder={getDocumentoPlaceholder(
                      documentoDados.mask_pf
                    )}
                  >
                    {(inputProps) => (
                      <Input
                        {...inputProps}
                        type="text"
                        name="documento"
                        id="documento"
                      />
                    )}
                  </InputMask> :
                  <Input
                    type="text"
                    name="documento"
                    id="documento"
                    value={documento}
                    onChange={handleInputChange}
                    className={`form-control ${
                      errors.documento ? "is-invalid" : ""
                    }`}
                  />
                  }
                  {errors.documento && (
                    <FormFeedback>{errors.documento}</FormFeedback>
                  )}
                </FormGroup>
              )}

              <div className="text-center mt-4">
                <Button
                  color="primary"
                  className="w-md"
                  onClick={() => handleVerificarClick()}
                  disabled={loadingComponent}
                >
                  {loadingComponent ? <Spinner size="sm" /> : "Verificar"}
                </Button>
              </div>
            </>
          )}

        {email &&
          typeof email === "string" &&
          email !== "nao_encontrado" &&
          email !== "em_andamento" &&
          email !== "negado" &&
          email !== "erro_desconhecido" &&
          !linkKoywe && (
            <div className="mt-4">
              <Alert color="success">
                <div className="text-center">
                  <p>Cliente encontrado!</p>
                  <p>Email: {email}</p>
                  <FormGroup className="mt-3">
                    <Label for="metodoPagamento">Método de Pago</Label>
                    <Input
                      type="select"
                      name="metodoPagamento"
                      id="metodoPagamento"
                      value={metodoPagamentoSelecionado}
                      onChange={(e) => {
                        bankDataChange(e);
                      }}
                      className="form-select"
                      disabled={loadingComponent}
                    >
                      <option value="">
                        {loadingComponent
                          ? "Cargando..."
                          : "Seleccione un método"}
                      </option>
                      {metodosPagamento.map((metodo) => (
                        <option key={metodo.koywe_id} value={metodo.koywe_id}>
                          {metodo.nome}
                        </option>
                      ))}
                      {resumoCalculo.moeda_sigla === "ARS" && (
                        <option value="bank_transfer">Bank Transfer</option>
                      )}
                    </Input>
                  </FormGroup>

                  {metodoPagamentoSelecionado === "bank_transfer" ? (
                    <div className="mt-3">
                      <p>
                        Asegúrese de transferir el monto exacto a la cuenta
                        informada y permanezca en esta pantalla para recibir la
                        confirmación:
                      </p>
                      <Alert color="info">
                        <div className="text-center">
                          <div className="d-flex align-items-center justify-content-center mt-3">
                            <div className="me-2">Alias: nautt.arg</div>
                            <Button
                              color="link"
                              className="p-0"
                              onClick={() => handleCopyToClipboard("nautt.arg")}
                            >
                              <FeatherIcon icon="copy" size={16} />
                            </Button>
                          </div>
                          <div className="d-flex align-items-center justify-content-center mt-2">
                            <div className="me-2">
                              Valor: ARS {resumoCalculo.valor_final}
                            </div>
                            <Button
                              color="link"
                              className="p-0"
                              onClick={() =>
                                handleCopyToClipboard(
                                  `${resumoCalculo.valor_final}`
                                )
                              }
                            >
                              <FeatherIcon icon="copy" size={16} />
                            </Button>
                          </div>
                        </div>
                      </Alert>
                    </div>
                  ) : (
                    <Button
                      color="primary"
                      className="mt-2"
                      onClick={handleGerarLinkPagamento}
                      disabled={loadingComponent || !metodoPagamentoSelecionado}
                    >
                      {loadingComponent ? (
                        <Spinner size="sm" />
                      ) : (
                        "Generar Link de Pago"
                      )}
                    </Button>
                  )}
                </div>
              </Alert>
            </div>
          )}

        {linkKoywe && (
          <div className="mt-4">
            <div className="text-center">
              <b>¡Orden de pago generada con éxito!</b>
              <p>
                Asegúrese de transferir el monto exacto a la cuenta informada y
                permanezca en esta pantalla para recibir la confirmación:
              </p>
              <Alert color="success" className="m-1">
                <div className="text-left">
                  {bankData?.split("\n").map((line, index) => (
                    <div
                      key={index}
                      className="d-flex align-items-center justify-content-start mt-2"
                    >
                      <div className="me-2">{line}</div>
                      <Button
                        color="link"
                        className="p-0"
                        onClick={() => handleCopyToClipboard(line)}
                      >
                        <FeatherIcon icon="copy" size={16} />
                      </Button>
                    </div>
                  ))}
                  <div className="d-flex align-items-center justify-content-start mt-3">
                    <div className="me-2">
                      Valor:{" "}
                      {formatCurrency(
                        resumoCalculo.valor_final,
                        resumoCalculo.moeda_sigla
                      )}
                    </div>
                    <Button
                      color="link"
                      className="p-0"
                      onClick={() =>
                        handleCopyToClipboard(`${resumoCalculo.valor_final}`)
                      }
                    >
                      <FeatherIcon icon="copy" size={16} />
                    </Button>
                  </div>
                </div>
              </Alert>
              {!ordem?.recibo_url && (
                <Alert color="warning" className="mt-3">
                  <div className="text-center">
                    <p className="mb-2">
                      <strong>¡Importante!</strong> Después de realizar el pago,
                      suba el comprobante:
                    </p>
                    <p className="small text-muted mb-3">
                      Archivos permitidos: PDF, PNG, JPG o JPEG
                    </p>
                    <div className="d-flex justify-content-center align-items-center gap-2">
                      <Input
                        type="file"
                        onChange={handleFileChange}
                        accept=".pdf,.png,.jpg,.jpeg"
                        className="form-control"
                        style={{ maxWidth: "300px" }}
                      />
                      <Button
                        color="success"
                        onClick={() => enviarComprovante(ordem)}
                        disabled={!file || loadingComponent}
                      >
                        {loadingComponent ? (
                          <Spinner size="sm" />
                        ) : (
                          <FeatherIcon
                            icon="upload"
                            size={16}
                            className="me-1"
                          />
                        )}
                      </Button>
                    </div>
                  </div>
                </Alert>
              )}
              {/* <div className="mt-3">
                <Button
                  color="danger"
                  onClick={handleCancelarOrdem}
                  disabled={loadingComponent}
                >
                  {loadingComponent ? <Spinner size="sm" /> : "Cambiar Metodo"}
                </Button>
              </div> */}
            </div>
          </div>
        )}

        {email === "nao_encontrado" && (
          <div className="mt-4">
            <Alert color="warning">
              <div className="text-center">
                <p>Documento no encontrado en la base de datos.</p>
                <p>
                  Es necesario realizar el proceso KYC. Vuelva aquí después de
                  completar la verificación.
                </p>
                <Link to={`/kyc-wizard?link_pagamento=${linkData.link_id}`}>
                  <Button color="primary" className="mt-2">
                    Realizar KYC
                  </Button>
                </Link>
              </div>
            </Alert>
          </div>
        )}

        {email === "em_andamento" && (
          <div className="mt-4">
            <Alert color="warning">
              <div className="text-center">
                <p>Su verificación KYC está en proceso.</p>
                <p>
                  Por favor, espere hasta que se complete la verificación y
                  vuelva a intentarlo.
                </p>
              </div>
            </Alert>
          </div>
        )}

        {email === "negado" && (
          <div className="mt-4">
            <Alert color="danger">
              <div className="text-center">
                <p>Su verificación KYC fue rechazada.</p>
                <p>Por favor, realice el proceso nuevamente.</p>
                <Link to="/kyc-wizard">
                  <Button color="primary" className="mt-2">
                    Realizar KYC
                  </Button>
                </Link>
              </div>
            </Alert>
          </div>
        )}

        {email === "erro_desconhecido" && (
          <div className="mt-4">
            <Alert color="warning">
              <div className="text-center">
                <p>Ha ocurrido un error desconocido.</p>
                <p>Por favor, inténtelo nuevamente más tarde.</p>
              </div>
            </Alert>
          </div>
        )}
      </CardBody>
    );
  };

  return (
    <Col xl={4}>
      <div className="sticky-side-div">
        <Card>
          <CardHeader className="border-bottom-dashed">
            <h5 className="card-title mb-0">Finalizar Compra</h5>
          </CardHeader>
          <CardHeader className="bg-light-subtle border-bottom-dashed">
            <div className="text-center">
              <Alert color="info" className="mt-2">
                Por el momento, solo es posible realizar pagos como persona
                física
              </Alert>
            </div>
          </CardHeader>
          {renderPaymentSection()}
          <CardBody className="pt-2">
            <div className="table-responsive">
              <table className="table table-borderless mb-0">
                <tbody>
                  {resumoCalculo && (
                    <>
                      {resumoCalculo.produtos.length > 0 ? (
                        <>
                          <tr>
                            <td>Subtotal :</td>
                            <td className="text-end">
                              {resumoCalculo.produtos.length > 0
                                ? formatCurrency(
                                    resumoCalculo.produtos.reduce(
                                      (total, produto, index) =>
                                        total +
                                        produto.preco_usd *
                                          linkData.produtos[index].pivot
                                            .quantidade *
                                          resumoCalculo.cotacao,
                                      0
                                    ),
                                    resumoCalculo.moeda_sigla
                                  )
                                : formatCurrency(
                                    resumoCalculo.valor_final - linkData.taxa,
                                    resumoCalculo.moeda_sigla
                                  )}
                            </td>
                          </tr>
                          <tr>
                            <td>Tasas :</td>
                            <td className="text-end">
                              {formatCurrency(
                                resumoCalculo.valor_final -
                                  resumoCalculo.produtos.reduce(
                                    (total, produto, index) =>
                                      total +
                                      produto.preco_usd *
                                        linkData.produtos[index].pivot
                                          .quantidade *
                                        resumoCalculo.cotacao,
                                    0
                                  ),
                                resumoCalculo.moeda_sigla
                              )}
                            </td>
                          </tr>
                        </>
                      ) : null}
                      <tr className="border-top border-top-dashed">
                        <td colSpan="2"></td>
                      </tr>
                      <tr className="fw-semibold">
                        <td>Total :</td>
                        <td className="text-end">
                          {formatCurrency(
                            resumoCalculo.valor_final,
                            resumoCalculo.moeda_sigla
                          )}
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </div>
    </Col>
  );
};

export default KoywePagamento;
