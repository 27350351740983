import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Alert,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { formatCurrency } from "../../Components/FunctionsRepository";
import FeatherIcon from "feather-icons-react";

const ModalApagar = ({ modal, setModal, setRefresh, ordem }) => {
  const toggle = () => {
    setRefresh(true);
    setModal(!modal);
  };

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("¡Copiado al portapapeles!");
  };

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Pagar Orden # {ordem?.id}
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="d-flex justify-content-center mt-3">
            <Col md="12" className="d-flex justify-content-center">
              <p>
                Asegúrese de transferir el monto exacto a la cuenta informada y
                permanezca en esta pantalla para recibir la confirmación:
              </p>
            </Col>
            <Col md="12" className="mt-3">
              <Alert color="success" className="m-1">
                <div className="text-left">
                  {(ordem?.metodo_pagamento?.bank_data || "")
                    ?.split("\n")
                    .map((line, index) => (
                      <div
                        key={index}
                        className="d-flex align-items-center justify-content-start mt-2"
                      >
                        <div className="me-2">{line}</div>
                        <Button
                          color="link"
                          className="p-0"
                          onClick={() => handleCopyToClipboard(line)}
                        >
                          <FeatherIcon icon="copy" size={16} />
                        </Button>
                      </div>
                    ))}
                  <div className="d-flex align-items-center justify-content-start mt-3">
                    <div className="me-2">
                      Valor:{" "}
                      {formatCurrency(ordem?.total_moeda, ordem?.moeda?.sigla)}
                    </div>
                    <Button
                      color="link"
                      className="p-0"
                      onClick={() =>
                        handleCopyToClipboard(`${ordem?.total_moeda}`)
                      }
                    >
                      <FeatherIcon icon="copy" size={16} />
                    </Button>
                  </div>
                </div>
              </Alert>
            </Col>
          </Row>
          <div className="hstack gap-2 justify-content-center mt-5">
            <Link
              to="#"
              className="btn btn-link link-primary fw-medium"
              onClick={() => toggle()}
            >
              <i className="ri-close-line me-1 align-middle"></i> Cerrar
            </Link>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalApagar;
