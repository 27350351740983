import React, { useEffect, useState, Fragment, useCallback } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
  Badge,
} from "reactstrap";
import Paginacao from "../../Components/Common/Paginacao";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import UiContent from "../../Components/Common/UiContent";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";

import SearchOption from "../../Components/Common/SearchOption";
import ModalApagar from "./ModalApagar";
import ModalNovo from "./ModalNovo";
import ModalEditar from "./ModalEditar";
import ModalUpload from "./ModalUpload";

import { toast } from "react-toastify";
import {
  checkLastLogin,
  formatCurrency,
  converterData,
} from "../../Components/FunctionsRepository";
import { APIClient, getLoggedinUser } from "../../helpers/api_helper";

const apiClient = new APIClient();

const Depositos = () => {
  document.title = "Depósitos | Nautt Finance";

  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadTable, setLoadTable] = useState(true);
  const [userDados, setUserDados] = useState(null);

  const [depositos, setDepositos] = useState([]);
  const [deposito, setDeposito] = useState(null);
  const [moedas, setMoedas] = useState([]);
  const [tokens, setTokens] = useState([]);
  const [itensPorPagina, setItensPorPagina] = useState(50);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [totalDeDepositos, setTotalDeDepositos] = useState(0);
  const [termoBusca, setTermoBusca] = useState("");

  const [modalEditar, setModalEditar] = useState(false);
  const [modalNovo, setModalNovo] = useState(false);
  const [modalApagar, setModalApagar] = useState(false);
  const [modalUpload, setModalUpload] = useState(false);
  const [selectedOrdem, setSelectedOrdem] = useState(null);

  const toggleModalEditar = useCallback(
    (seg) => {
      setDeposito(seg);
      setModalEditar(!modalEditar);
    },
    [modalEditar]
  );

  const toggleModalNovo = useCallback(() => {
    setModalNovo(!modalNovo);
  }, [modalNovo]);

  const toggleModalApagar = useCallback(
    (seg) => {
      setDeposito(seg);
      setModalApagar(!modalApagar);
    },
    [modalApagar]
  );

  const fetchTokens = useCallback(async () => {
    const response = await apiClient.get("/tokens");
    if (response.sucesso) {
      setTokens(response.dados);
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  }, []);

  const fetchMoedas = useCallback(async () => {
    const response = await apiClient.get("/moedas");
    if (response.sucesso) {
      setMoedas(response.dados);
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  }, []);

  const fetchDepositos = useCallback(
    async (itensPorPagina, paginaAtual, termoBusca) => {
      const data = {
        itens_pagina: itensPorPagina,
        pagina: paginaAtual,
        termo: termoBusca,
        order_by: "created_at",
        order: "desc",
      };

      const response = await apiClient.post("/depositos/busca", data);
      if (response.sucesso) {
        const dados = response.dados;
        setDepositos(dados.data);
        setTotalDeDepositos(dados.total);
      } else {
        console.error(response.mensagem);
        if (!response.mensagem.includes(" 401"))
          if (response.mensagem !== "") toast.error(response.mensagem);
      }
      if (
        !response.sucesso &&
        (!response.responseCode || response.responseCode === 401)
      ) {
        await checkLastLogin();
      }
    },
    []
  );

  const refreshUser = useCallback(async () => {
    window.dispatchEvent(new Event("localDataUpdated"));
  }, []);

  const getBadgeColor = (tipo, order_nautt) => {
    const colors = {
      pix: "info",
      koywe: "warning",
      cripto: "primary",
      nautt: order_nautt === 0 ? "success" : "secondary",
    };
    return colors[tipo] || "secondary";
  };

  const getStatusBadgeColor = (status) => {
    const colors = {
      novo: "info",
      invalido: "danger",
      cancelado: "dark",
      processando: "warning",
      finalizado: "success",
    };
    return colors[status] || "secondary";
  };

  const formatTipo = (tipo, order_nautt, moeda) => {
    const tipos = {
      pix: "PIX",
      koywe: "Latam",
      cripto: "Crypto",
      nautt: order_nautt === 0 ? "Nautt" : moeda,
    };
    return tipos[tipo] || tipo;
  };

  const formatStatus = (status) => {
    const statusMap = {
      novo: "Nuevo",
      invalido: "Inválido",
      cancelado: "Cancelado",
      processando: "Procesando",
      finalizado: "Finalizado",
    };
    return statusMap[status] || status;
  };

  const formatOrdem = (deposito) => {
    if (deposito.tipo === "cripto" && deposito.txid) {
      return (
        <>
          <small className="text-muted">Transaction ID</small>
          <br />
          {deposito.token?.rede ? (
            <Link
              to={`${deposito.token.rede.block_explorer}/tx/${deposito.txid}`}
              target="_blank"
              className="font-monospace"
            >
              {deposito.txid.slice(0, 4)}...{deposito.txid.slice(-4)}
            </Link>
          ) : (
            <span className="font-monospace">
              {deposito.txid.slice(0, 4)}...{deposito.txid.slice(-4)}
            </span>
          )}
        </>
      );
    }
    if (deposito.tipo === "pix") {
      return (
        <>
          <small className="text-muted">Orden PIX</small>
          <br />
          {deposito.order_pix}
        </>
      );
    }
    if (deposito.tipo === "koywe") {
      return (
        <>
          <small className="text-muted">Orden Latam</small>
          <br />
          {deposito.order_koywe}
        </>
      );
    }
    if (deposito.tipo === "nautt" && deposito.ordem_nautt) {
      return (
        <>
          <small className="text-muted">Orden Nautt</small>
          <br />
          {deposito.order_nautt}
        </>
      );
    }
    if (deposito.tipo === "nautt") {
      return (
        <>
          <small className="text-muted">Remitente</small>
          <br />
          {deposito.remetente?.nome}
        </>
      );
    }
    return "-";
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoadTable(true);
      const userData = await getLoggedinUser();
      setUserDados(userData);
      await fetchTokens();
      await fetchMoedas();
      await fetchDepositos(itensPorPagina, paginaAtual, termoBusca);
      if (refresh) {
        await refreshUser();
        setRefresh(false);
      }
      setLoadTable(false);
    };

    fetchData();
    setLoading(false);
  }, [loading, itensPorPagina, paginaAtual, termoBusca, refresh]);

  return (
    <Fragment>
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Registro de Depositos" pageTitle="Depositos" />
          <Card>
            <Row className="justify-content-between align-items-center mb-2 me-2 ms-2">
              <Col xl={3}>
                <label className="form-label">Ítems por página</label>
                <select
                  className="form-select mt-1"
                  aria-label=""
                  onChange={(e) => {
                    setItensPorPagina(parseInt(e.target.value));
                    setPaginaAtual(1);
                    setLoadTable(true);
                    setLoading(true);
                  }}
                  value={itensPorPagina}
                >
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                </select>
              </Col>
              <Col xl={9} className="mb-0" style={{ marginTop: "32px" }}>
                <SearchOption
                  setTermoBusca={setTermoBusca}
                  setPaginaAtual={setPaginaAtual}
                  termoBusca={termoBusca}
                  setLoading={setLoading}
                  setLoadTable={setLoadTable}
                />
              </Col>
            </Row>
          </Card>

          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Depositos</h4>
                  <div className="flex-shrink-0">
                    <Link
                      to="#"
                      className="btn btn-soft-secondary"
                      onClick={() => {
                        toggleModalNovo();
                      }}
                    >
                      Nuevo Deposito
                    </Link>
                  </div>
                </CardHeader>
                <CardBody>
                  {loading || loadTable ? (
                    <Row className="justify-content-center mt-4 pt-2 text-center">
                      <Spinner />
                    </Row>
                  ) : (
                    <div className="live-preview">
                      <div className="table-responsive table-card">
                        <table className="table align-middle table-nowrap table-striped-columns mb-1">
                          <thead className="table-light">
                            <tr>
                              <th scope="col">ID #</th>
                              <th scope="col">Usuario</th>
                              <th scope="col">Valor</th>
                              <th scope="col">Tipo</th>
                              <th scope="col">Orden / TxID</th>
                              <th scope="col">Estado</th>
                              <th scope="col">Acciones</th>
                            </tr>
                          </thead>
                          <tbody>
                            {depositos &&
                              depositos.length !== 0 &&
                              depositos?.map((deposito, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      {deposito.id}
                                      <br />
                                      <small className="text-muted">
                                        {converterData(deposito.created_at)}
                                      </small>
                                    </td>
                                    <td>{deposito.user?.nome || "-"}</td>
                                    <td>
                                      {formatCurrency(
                                        deposito.valor_usd,
                                        "USD"
                                      )}
                                    </td>
                                    <td>
                                      <Badge
                                        color={getBadgeColor(
                                          deposito.tipo,
                                          deposito.order_nautt
                                        )}
                                      >
                                        {formatTipo(
                                          deposito.tipo,
                                          deposito.order_nautt,
                                          deposito.ordem_nautt?.moeda?.sigla
                                        )}
                                      </Badge>
                                    </td>
                                    <td>{formatOrdem(deposito)}</td>
                                    <td>
                                      <Badge
                                        color={getStatusBadgeColor(
                                          deposito.status
                                        )}
                                      >
                                        {formatStatus(deposito.status)}
                                      </Badge>
                                    </td>
                                    <td>
                                      {userDados.role === "admin" &&
                                        (deposito.status === "novo" ||
                                          deposito.status ===
                                            "processando") && (
                                          <Link
                                            to="#"
                                            className="text-success"
                                            title="Editar"
                                            onClick={() => {
                                              toggleModalEditar(deposito);
                                            }}
                                          >
                                            <FeatherIcon
                                              icon="edit"
                                              width="14"
                                              className="me-3"
                                            />
                                          </Link>
                                        )}
                                      {deposito.status === "novo" ? (
                                        <>
                                          {deposito.tipo === "nautt" &&
                                            deposito.ordem_nautt &&
                                            !deposito.ordem_nautt
                                              ?.recibo_url && (
                                              <Link
                                                to="#"
                                                className="text-warning"
                                                title="Subir Comprobante"
                                                onClick={() => {
                                                  setSelectedOrdem(
                                                    deposito.ordem_nautt
                                                  );
                                                  setModalUpload(true);
                                                }}
                                              >
                                                <FeatherIcon
                                                  icon="upload"
                                                  width="14"
                                                  className="me-3"
                                                />
                                              </Link>
                                            )}
                                          <Link
                                            to="#"
                                            className="text-danger"
                                            title="Borrar"
                                            onClick={() => {
                                              toggleModalApagar(deposito);
                                            }}
                                          >
                                            <FeatherIcon
                                              icon="trash-2"
                                              width="14"
                                            />
                                          </Link>
                                        </>
                                      ) : deposito.status === "finalizado" ? (
                                        <Link
                                          to={`/recibo/${deposito.uuid}`}
                                          className="text-info"
                                          title="Ver Recibo"
                                          target="_blank"
                                        >
                                          <FeatherIcon
                                            icon="file-text"
                                            width="14"
                                            className="me-3"
                                          />
                                        </Link>
                                      ) : (
                                        <small className="text-muted">-</small>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                  {!loading && !loadTable && depositos.length === 0 ? (
                    <Row>
                      <small className="text-muted text-center mt-5 mb-5">
                        Ningún deposito encontrado
                      </small>
                    </Row>
                  ) : (
                    <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
                      <div className="col-sm">
                        <div className="text-muted">
                          Mostrando{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              (paginaAtual - 1) * itensPorPagina + 1,
                              totalDeDepositos
                            )}
                          </span>{" "}
                          a{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              paginaAtual * itensPorPagina,
                              totalDeDepositos
                            )}
                          </span>{" "}
                          de{" "}
                          <span className="fw-semibold">
                            {totalDeDepositos}
                          </span>{" "}
                          resultados
                        </div>
                      </div>
                      <div className="col-sm-auto mt-3 mt-sm-0">
                        <Paginacao
                          total={totalDeDepositos}
                          currentPage={paginaAtual}
                          setCurrentPage={setPaginaAtual}
                          perPageData={itensPorPagina}
                        />
                      </div>
                    </div>
                  )}
                  <ModalEditar
                    modal={modalEditar}
                    setModal={setModalEditar}
                    deposito={deposito}
                    setRefresh={setRefresh}
                    moedas={moedas}
                    tokens={tokens}
                  />
                  <ModalApagar
                    modal={modalApagar}
                    setModal={setModalApagar}
                    deposito={deposito}
                    setRefresh={setRefresh}
                  />
                  <ModalNovo
                    modal={modalNovo}
                    setModal={setModalNovo}
                    setRefresh={setRefresh}
                    moedas={moedas}
                    tokens={tokens}
                  />
                  <ModalUpload
                    modal={modalUpload}
                    setModal={setModalUpload}
                    ordem={selectedOrdem}
                    setRefresh={setRefresh}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default Depositos;
