import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col, Card, CardBody, Spinner } from "reactstrap";
import { useParams, Link } from "react-router-dom";
import { toast } from "react-toastify";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import StepOne from "./Steps/StepOne";
import StepTwo from "./Steps/StepTwo";
import StepThree from "./Steps/StepThree";
import StepFour from "./Steps/StepFour";
import withRouter from "../../Components/Common/withRouter";
import logoLight from "../../assets/images/logo-light.png";
import { APIClient } from "../../helpers/api_helper";

const apiClient = new APIClient();

const steps = [
  { number: 1, title: "Datos de la Empresa" },
  { number: 2, title: "Dirección" },
  { number: 3, title: "Verificación" },
  { number: 4, title: "Completado" },
];

const KybWizard = () => {
  document.title = "KYB | Nautt Finance";
  const { uuid } = useParams();
  const [loading, setLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [kybData, setKybData] = useState({
    uuid: uuid || "",
    email: "",
    nome_legal: "",
    nome_fantasia: "",
    data_constituicao: "",
    atividade: "",
    pais_fiscal: "",
    telefone: "",
    checkbox_privacidade: false,
    status: "pendente",
  });

  const fetchCountries = useCallback(async () => {
    try {
      const response = await apiClient.get("/moedas");
      if (response.sucesso) {
        setCountries(response.dados);
      } else {
        console.error(response.mensagem);
        if (!response.mensagem.includes(" 401"))
          if (response.mensagem !== "") toast.error(response.mensagem);
      }
    } catch (error) {
      console.error(error);
      toast.error("Error al cargar los países");
    }
  }, []);

  const fetchKybData = useCallback(async () => {
    if (!uuid) {
      setLoading(false);
      return;
    }

    // Se uuid for "concluido", vá direto para o step 4
    if (uuid === "concluido") {
      setCurrentStep(4);
      setLoading(false);
      return;
    }

    try {
      const data = {
        uuid: uuid,
      };
      const response = await apiClient.get(`/kyb/get`, data);
      if (response.sucesso) {
        const responseData = response.dados;
        setKybData(prev => ({
          ...prev,
          uuid: responseData.kyb_code,
          email: responseData.email,
          nome_legal: responseData.nome_legal,
          nome_fantasia: responseData.nome_fantasia,
          data_constituicao: responseData.data_constituicao,
          atividade: responseData.atividade,
          pais_fiscal: responseData.pais_fiscal,
          telefone: responseData.telefone,
          website: responseData.website,
          checkbox_privacidade: responseData.checkbox_privacidade,
          status: responseData.status || "pendente",
          link_pagamento: responseData.link_pagamento,
          documento_fiscal: responseData.documento_fiscal,
          enderecos: responseData.enderecos,
          acionistas: responseData.acionistas,
        }));

        // Apenas setamos o selectedCountry se tivermos um país fiscal
        if (responseData.pais_fiscal) {
          const country = countries.find(
            (country) => country.country_code === responseData.pais_fiscal
          );
          setSelectedCountry(country);
        }

        // Determine which step to show based on filled fields
        if (responseData.status === "verificado" || responseData.status === "rejeitado") {
          setCurrentStep(4);
        } else if (responseData.enderecos?.length > 0 && responseData.acionistas?.length > 0) {
          setCurrentStep(4);
        } else if (responseData.enderecos?.length > 0) {
          setCurrentStep(3);
        } else if (responseData.email) {
          setCurrentStep(2);
        }
      } else {
        toast.error(response.mensagem || "Error al cargar los datos KYB");
      }
    } catch (error) {
      console.error(error);
      toast.error("Error al cargar los datos KYB");
    } finally {
      setLoading(false);
    }
  }, [uuid, countries]);

  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    fetchKybData();
  }, [fetchKybData]);

  const handleStepComplete = (stepData) => {
    setKybData((prev) => ({ ...prev, ...stepData }));
    if (stepData.pais_fiscal) {
      const country = countries.find(
        (country) => country.country_code === stepData.pais_fiscal
      );
      setSelectedCountry(country);
    }
    setCurrentStep((prev) => prev + 1);
  };

  const renderStep = () => {
    if (loading) {
      return (
        <div className="text-center">
          <Spinner color="success" />
          <p className="mt-2">Cargando datos KYB...</p>
        </div>
      );
    }

    switch (currentStep) {
      case 1:
        return <StepOne onComplete={handleStepComplete} data={kybData} countries={countries} selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} />;
      case 2:
        return <StepTwo onComplete={handleStepComplete} data={kybData} countries={countries} selectedCountry={selectedCountry} />;
      case 3:
        return <StepThree onComplete={handleStepComplete} data={kybData} countries={countries} selectedCountry={selectedCountry} />;
      case 4:
        return <StepFour 
          status={uuid === "concluido" ? "pendente" : kybData.status} 
          data={kybData}
        />;
      default:
        return null;
    }
  };

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoLight} alt="" height="50" />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={8}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-success">Onboarding de Persona Jurídica</h5>
                      {currentStep < 4 && (
                        <p className="text-muted">Complete los pasos para verificar la identidad de su empresa</p>
                      )}
                    </div>

                    {currentStep < 4 && (
                      <div className="d-flex justify-content-center mb-4">
                        {steps.slice(0, 3).map((step, index) => (
                          <div
                            key={step.number}
                            className="d-flex align-items-center"
                          >
                            <div
                              className={`d-flex align-items-center justify-content-center rounded-circle ${
                                step.number === currentStep
                                  ? "bg-success text-white"
                                  : step.number < currentStep
                                  ? "bg-success-subtle text-success"
                                  : "bg-light text-muted me-2"
                              }`}
                              style={{
                                width: "32px",
                                height: "32px",
                                fontSize: "14px",
                                fontWeight: "bold",
                              }}
                            >
                              {step.number}
                            </div>
                            {step.number === currentStep && (
                              <span
                                className="ms-2 me-2 text-success fw-medium"
                                style={{ fontSize: "14px" }}
                              >
                                {step.title}
                              </span>
                            )}
                            {index < 2 && (
                              <div
                                className={`flex-grow-1 ms-2 me-2 ${
                                  step.number < currentStep
                                    ? "border-success"
                                    : "border-light"
                                }`}
                                style={{
                                  height: "2px",
                                  width: "60px",
                                  backgroundColor: step.number < currentStep ? "#0ab39c" : "#e9ebec",
                                }}
                              ></div>
                            )}
                          </div>
                        ))}
                      </div>
                    )}

                    {renderStep()}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(KybWizard);
